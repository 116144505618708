import { API } from "apis";
import { message } from "antd";
import {
  del,
  getViaAuth,
  isNotFound,
  postViaAuth,
  responseValidator,
} from "utils/scripts/api";
import { Dispatch, SetStateAction } from "react";

export const getAllNotifications = (
  setLoader: any,
  setResponse: any,
  page: number,
  pageSize: number,
  query?: string
) => {
  setLoader(true);

  getViaAuth(
    API.notification.getAll +
      `/${page ? page : 1}/${pageSize ? pageSize : 20}` +
      (query || "")
  ).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setResponse(response.value);
    } else {
      isNotFound(response.status) && setResponse([]);
    }
  });
};

export const getAllNotificationDetails = (
  setLoader: any,
  setResponse: any,
  page: number,
  pageSize: number,
  query?: string
) => {
  setLoader(true);

  getViaAuth(
    API.notification.details +
      `/${page ? page : 1}/${pageSize ? pageSize : 20}` +
      (query || "")
  ).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setResponse(response.value);
    } else {
      isNotFound(response.status) && setResponse([]);
    }
  });
};

export const getNotificationById = (
  setLoader: any,
  setResponse: any,
  id: string
) => {
  setLoader(true);
  getViaAuth(API.notification.getNotificationById + `/${id}`).then(
    (response: any) => {
      setLoader(false);
      if (responseValidator(response.status)) {
        setResponse(response.value);
      }
    }
  );
};

export const getNotificationReasons = (setLoader: any, setResponse: any) => {
  setLoader(true);

  getViaAuth(API.notification.reasonsEnum).then((response: any) => {
    setLoader(false);
    if (responseValidator(response.status)) {
      setResponse([{ id: 0, title: "همه" }, ...response.value]);
    }
  });
};

export const updateNotification = (setloader: any, data: any) => {
  setloader(true);
  postViaAuth(API.notification.edit, data).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success(response.message);
      window.open("/notifications", "_self");
    }
  });
};

export const createNotification = (setloader: any, data: any) => {
  setloader(true);
  postViaAuth(API.notification.add, data).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success(response.message);
      window.open("/notifications", "_self");
    }
  });
};

export const deleteNotification = (
  setLoading: Dispatch<SetStateAction<boolean>>,
  id: string,
  onComplete: () => void
) => {
  setLoading(true);
  del(API.notification.delete, { id: id }).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      onComplete();
    }
  });
};
