import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import {
  CheckOutlined,
  CloseOutlined,
  DollarCircleOutlined,
  DownOutlined,
  EditOutlined,
  HourglassOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Calendar, Card, Input, Modal } from "components";
import { Badge } from "components/badge/badge";
import { Table } from "components/table/table";
import moment from "jalali-moment";
import "./index.scss";
import {
  Transaction,
  TransactionsCount,
  TransactionState,
  TransactionStateLabel,
  TransactionType,
} from "core/domains/transaction/transaction";
import {
  editTransactionState,
  getAllTransactions,
  getPlatforms,
  getTransactionDepositCurrencyTypes,
  getTransactionMarketsTypes,
  getTransactionStates,
  getTransactionTypes,
  payoutTranscation,
} from "core/repos/transaction";
import { digitSeparator } from "utils/scripts/decimal";
import { Enum, FilterValues, PaginationList } from "core/domains";
import { Dropdown, Menu, Popover, Tag, Tooltip } from "antd";
import { getIconPath } from "../../../../utils/scripts/getIconPath";

import { getPageNum } from "../../../../utils/scripts/paggingHelper";
import { getViaAuth, responseValidator } from "../../../../utils/scripts/api";
import { API } from "../../../../apis";
import { convertFaToEnCalendar } from "utils/scripts/calendarExtentions";
import { ConfirmBulkSelectModal } from "../modals/bulk-select-modal/bulk-select-modal";
import { Link } from "react-router-dom";
import { TransactionOrderDetailModal } from "../../../../components/transaction-order-modal/transaction-order-modal";

const { useState } = React;

const defaultStateItems: Enum[] = [
  {
    title: "وضعیت تراکنش",
    id: 0,
  },
];

const defaultTypeItems: Enum[] = [
  {
    title: "نوع تراکنش",
    id: 0,
  },
];

const defaultMarketTypeItems: Enum[] = [
  {
    title: "نوع ارز",
    id: 0,
  },
];

const defaultDepositCurrencyTypes: Enum[] = [
  {
    title: "نوع واریز",
    id: -1,
  },
];

const defaultPlatformItems: Enum[] = [
  {
    title: "پلتفرم",
    id: 0,
  },
];

const defaultActionDropdownItem: Enum[] = [
  {
    title: "انتخاب عملیات",
    id: 0,
  },
];

const actionItems: Enum[] = [
  {
    id: 0,
    title: "انتخاب عملیات",
  },
  {
    id: 1,
    title: "تایید و تسویه",
  },
  {
    id: 2,
    title: "تایید",
  },
  {
    id: 8,
    title: "لغو",
  },
];

type Props = {
  state?: any;
  type?: number;
};
export const AllTransactions: React.FC<Props> = (Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPaging, setLoadingPaging] = useState<boolean>(false);

  const [transactions, setTransactions] =
    useState<PaginationList<Transaction>>();
  const [transactionsCount, setTransactionsCount] =
    useState<TransactionsCount>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [getFiltersLoading, setGetFiltersLoading] = useState<boolean>(false);

  const [filterValues, setFilterValues] = useState<FilterValues>({
    type: defaultTypeItems[0],
    state: defaultStateItems[0],
    marketType: defaultMarketTypeItems[0],
    platform: defaultPlatformItems[0],
    depositCurrencyType: defaultDepositCurrencyTypes[0],
  });
  const [selectedItem, setSelectedItem] = useState<Transaction>();

  const [isOpenConfirmBulkActionModal, setIsOpenConfirmBulkActionModal] =
    React.useState<boolean>(false);
  const [actionApproveLoading, setActionApproveLoading] =
    React.useState<boolean>(false);
  const [selectedRows, setSelectedRows] = React.useState<Transaction[]>();
  const [selectedActionItem, setSelectedActionItem] = React.useState<Enum>(
    actionItems[0]
  );
  const [descriptionValue, setDescriptionValue] = useState<string>("");
  const [digitalReceiptValue, setDigitalReceiptValue] = useState<string>("");

  const [transactionMarketsType, setTransactionMarketsType] = useState<Enum[]>(
    []
  );
  const [transactionDepositCurrencyTypes, setTransactionDepositCurrencyTypes] =
    useState<Enum[]>([]);

  const [transactionStates, setTransactionStates] = useState<Enum[]>([]);
  const [transactionTypes, setTransactionTypes] = useState<Enum[]>([]);
  const [transactionPlatforms, setTransactionPlatforms] = useState<Enum[]>([]);
  const [payoutModalIsOpen, setPayoutModalIsOpen] = useState<boolean>(false);
  // states : approve , reject , cancel
  const [confirmModalState, setConfirmModalState] = useState<TransactionState>(
    TransactionState.WaitingForAdmin
  );
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);

  const [payoutLoading, setPayoutLoading] = useState<boolean>(false);

  const [query, setQuery] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);

  const [modalLoading, setModalLoading] = useState<boolean>(false);

  const closePayoutModal = () => setPayoutModalIsOpen(false);

  const openPayoutModal = (item: Transaction) => {
    setSelectedItem(item);
    setPayoutModalIsOpen(true);
  };

  const openConfirmModal = (item: Transaction, state: TransactionState) => {
    setSelectedItem(item);
    setConfirmModalState(state);
    setConfirmModal(true);
  };

  React.useEffect(() => {
    if (selectedRows && selectedRows.length < 2)
      setSelectedActionItem(actionItems[0]);
  }, [selectedRows]);
  async function onApproveAndPayOutHandler() {
    selectedItem &&
      (await payoutTranscation(
        setPayoutLoading,
        selectedItem,
        setPayoutModalIsOpen
      ));
    fetchAllData(query);
  }

  const closeAppproveModal = () => {
    setDescriptionValue("");
    setDigitalReceiptValue("");
    setConfirmModal(false);
  };

  const updatePageSize = (newPageSize: number) => {
    setTransactionsCount((prevState: TransactionsCount | undefined) => ({
      ...prevState!,
      pageSize: newPageSize,
    }));
  };

  const columns: any[] = [
    {
      name: "#",
      id: "index",
      selector: (row: Transaction, rowIndex: number) => rowIndex + 1,
      width: "4%",
    },

    {
      name: "شناسه",
      id: "trackingCode",
      width: "12%",
      selector: (row: Transaction) => row.trackingCode,
      cell: (row: Transaction) => (
        <span className="en">{row.trackingCode}</span>
      ),
    },
    {
      name: "نام ارز",
      id: "market",
      width: "8%",
      selector: (row: Transaction) => row.market.name,
      cell: (row: Transaction) => (
        <>
          <img
            style={{ height: "24px", marginLeft: "6px" }}
            src={getIconPath(row?.market?.symbol || "")}
          />
          <span className="en">{row?.market?.symbol}</span>
        </>
      ),
    },
    {
      name: "نوع تراکنش",
      id: "transactionType",
      width: "10%",
      selector: (row: Transaction) => row.type.title,
      cell: (row: Transaction) => (
        <>
          <Tag
            color={`${
              row.type?.id === TransactionType.Deposit ? "green" : "red"
            }`}
          >
            {row.type?.title}{" "}
            {row.type?.id == TransactionType.Deposit &&
              row?.depositIrtType &&
              row?.market?.symbol === "IRT" &&
              "/" + row.depositIrtType?.title}
          </Tag>
        </>
      ),
    },
    {
      name: "مقدار ",
      id: "amount",
      width: "8%",
      selector: (row: Transaction) => digitSeparator(row.amount),
      cell: (record: Transaction) => (
        <div className="column-amount">
          <span>
            <Popover title="مقدار">{digitSeparator(record?.amount)}</Popover>
          </span>
          {record?.type?.id === TransactionType.Withdrawal &&
            record?.market?.symbol === "IRT" && (
              <p>
                <Popover title="مبلغ جهت تسویه">
                  {digitSeparator(record?.payoutAmount)}
                </Popover>
              </p>
            )}
        </div>
      ),
    },
    {
      name: "نام کاربر",
      id: "createdBy",
      width: "12%",
      cell: (row: Transaction) => (
        <div className="column-createdBy">
          <span className="name">{row.createdBy}</span>
          <p className="phone">{row.phoneNumber}</p>
        </div>
      ),
    },
    {
      name: "پلتفرم",
      id: "platform",
      width: "8%",
      selector: (row: Transaction) => row.platform.title,
      cell: (row: Transaction) => (
        <>
          <Tag color={`${row.platform.id === 1 ? "blue" : "green"}`}>
            {row.platform.title}
          </Tag>
        </>
      ),
    },
    {
      name: "زمان",
      id: "createdAt",
      width: "10%",
      selector: (row: Transaction) => row.createdAt,

      cell: (row: Transaction) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdAt)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdAt).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "وضعیت",
      id: "state",
      width: "14%",
      selector: (row: Transaction) => row.state.id,
      cell: (row: Transaction) => (
        <Badge
          text={row.state.title}
          type={
            row.state.id == 2
              ? "success"
              : row.state.id == 1 || row.state.id == 6
              ? "waiting"
              : "error"
          }
          icon={`/assets/icons/table/${
            row.state.id == 2
              ? "success"
              : row.state.id == 1 || row.state.id == 6
              ? "waiting"
              : "danger"
          }.svg`}
        />
      ),
    },
    {
      name: "جزئیات",
      id: "detail",
      cell: (row: Transaction) => (
        <div className="actions">
          {(row.state.id === TransactionState.WaitingForAdmin ||
            row.state.id == TransactionState.WaitingForUpgradeLevel) && (
            <Tooltip placement="top" title={"تایید و تسویه تراکنش"}>
              <DollarCircleOutlined onClick={() => openPayoutModal(row)} />
            </Tooltip>
          )}
          {(row.state.id === TransactionState.WaitingForAdmin ||
            row.state.id == TransactionState.WaitingForTime ||
            row.state.id == TransactionState.WaitingForUpgradeLevel ||
            row.state.id ===
              TransactionState.WaitingForTimeAndUpgradeLevel) && (
            <Tooltip placement="top" title={"تایید تراکنش"}>
              <CheckOutlined
                onClick={() => openConfirmModal(row, TransactionState.Succeed)}
              />
            </Tooltip>
          )}
          {row.state.id === TransactionState.WaitingForAdmin && (
            <Tooltip placement="top" title={"لغو تراکنش"}>
              <CloseOutlined
                onClick={() => openConfirmModal(row, TransactionState.Cancel)}
              />
            </Tooltip>
          )}
          {row.state.id === TransactionState.WaitingForAdmin && (
            <Tooltip placement="top" title={"درحال اقدام دستی"}>
              <HourglassOutlined
                onClick={() =>
                  openConfirmModal(row, TransactionState.ManualDoing)
                }
              />
            </Tooltip>
          )}

          {row.state.id !== TransactionState.Cancel && (
            <Tooltip placement="top" title={"ویرایش تراکنش"}>
              <Link to={`/transactions/edit/${row.id}`}>
                <EditOutlined />
              </Link>
            </Tooltip>
          )}
          <Tooltip placement="top" title={"جزئیات تراکنش"}>
            <InfoCircleOutlined onClick={() => handleModal(row)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  function handleModal(item: Transaction) {
    setIsOpen(true);
    setSelectedItem(item);
  }

  const filterHandle = () => {
    var _query = "";
    if (filterValues.type?.id !== 0)
      _query += `${_query.includes("?") ? "&" : "?"}type=${
        filterValues.type?.id
      }`;

    if (filterValues.state?.id !== 0)
      _query += `${_query.includes("?") ? "&" : "?"}state=${
        filterValues.state?.id
      }`;

    if (filterValues.startDate)
      _query += `${
        _query.includes("?") ? "&" : "?"
      }startDate=${convertFaToEnCalendar(filterValues.startDate)}`;
    if (filterValues.endDate)
      _query += `${
        _query.includes("?") ? "&" : "?"
      }endDate=${convertFaToEnCalendar(filterValues.endDate)}`;
    if (filterValues.keywords) {
      _query += `${_query.includes("?") ? "&" : "?"}keywords=`;
      _query += filterValues.keywords;
    }
    if (filterValues.marketType?.id !== 0)
      _query += `${_query.includes("?") ? "&" : "?"}marketType=${
        filterValues.marketType?.id
      }`;
    if (filterValues.platform?.id !== 0)
      _query += `${_query.includes("?") ? "&" : "?"}platform=${
        filterValues.platform?.id
      }`;

    if (filterValues.depositCurrencyType?.id !== -1)
      _query += `${_query.includes("?") ? "&" : "?"}depositCurrencyType=${
        filterValues.depositCurrencyType?.id
      }`;

    setQuery(_query);
    getAllTransactions(
      setLoading,
      setTransactions,
      1,
      transactionsCount?.pageSize ?? 10,
      _query,
      setTransactionsCount
    );
  };

  const fetchAllData = async (query: string) => {
    getAllTransactions(
      setCardLoading,
      setTransactions,
      getPageNum(),
      pageSize,
      query,
      setTransactionsCount
    );
  };

  React.useEffect(() => {
    !transactionStates.length &&
      getTransactionStates(setGetFiltersLoading, setTransactionStates);
    !transactionMarketsType ||
      (transactionMarketsType.length < 1 &&
        getTransactionMarketsTypes(
          setGetFiltersLoading,
          setTransactionMarketsType
        ));
    !transactionDepositCurrencyTypes ||
      (transactionDepositCurrencyTypes.length < 1 &&
        getTransactionDepositCurrencyTypes(
          setGetFiltersLoading,
          setTransactionDepositCurrencyTypes
        ));

    !transactionTypes.length &&
      getTransactionTypes(setGetFiltersLoading, setTransactionTypes);
    !transactionPlatforms.length &&
      getPlatforms(setGetFiltersLoading, setTransactionPlatforms);
  }, []);

  React.useEffect(() => {
    fetchAllData(query);
  }, []);

  async function onConfirmHandler() {
    selectedItem &&
      (await editTransactionState(
        setApproveLoading,
        selectedItem,
        closeAppproveModal,
        confirmModalState,
        (confirmModalState === TransactionState.Succeed ||
          confirmModalState === TransactionState.Cancel) &&
          descriptionValue !== ""
          ? descriptionValue
          : undefined,
        selectedItem.type.id === TransactionType.Withdrawal &&
          selectedItem.market.symbol.toUpperCase() === "IRT" &&
          digitalReceiptValue !== ""
          ? digitalReceiptValue
          : undefined
      ));
    fetchAllData(query);
  }

  const getTxid = async () => {
    if (
      !selectedItem ||
      selectedItem.market.symbol == "PM" ||
      selectedItem.market.symbol == "IRT"
    )
      return;
    if (
      selectedItem.state.id === TransactionState.Succeed &&
      selectedItem.type.id == TransactionType.Withdrawal &&
      (selectedItem.txId == null || selectedItem.txId == "")
    ) {
      setModalLoading(true);

      await getViaAuth<any>(
        API.transaction.crypto.detail + `/${selectedItem?.trackingCode}`
      ).then((response: any) => {
        setModalLoading(false);

        if (responseValidator(response.status)) {
          setSelectedItem({
            ...selectedItem,
            txId: response.value.txid,
          });
        }
      });
    }
  };

  React.useEffect(() => {
    getTxid();
  }, [selectedItem?.trackingCode]);

  const openActionConfirmModal = () => {
    setActionApproveLoading(false);
    setIsOpenConfirmBulkActionModal(true);
  };

  return (
    <div className="currency-transactions">
      {selectedItem && (
        <TransactionOrderDetailModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          transactionItem={selectedItem}
          modalLoading={modalLoading}
        />
      )}

      {selectedRows && (
        <ConfirmBulkSelectModal
          isOpen={isOpenConfirmBulkActionModal}
          setIsOpen={setIsOpenConfirmBulkActionModal}
          buttonLoading={actionApproveLoading}
          selectedActionItem={selectedActionItem}
          selectedRows={selectedRows}
          setButtonLoading={setActionApproveLoading}
          setSelectedRows={setSelectedRows}
          fetchData={async () => {
            await getAllTransactions(
              setCardLoading,
              setTransactions,
              getPageNum(),
              pageSize,
              query,
              setTransactionsCount
            );
          }}
          actionItem={selectedActionItem}
        />
      )}
      {selectedItem && (
        <Modal
          setIsOpen={setConfirmModal}
          isOpen={confirmModal}
          title={`از تغییر وضعیت به  ${TransactionStateLabel.get(
            confirmModalState
          )} تراکش   ${selectedItem.trackingCode}   مطمئن هستید؟`}
          closeAble
          className="transaction-approve-modal"
          onClose={closeAppproveModal}
        >
          {(confirmModalState === TransactionState.Succeed ||
            confirmModalState === TransactionState.Cancel) && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "32px" }}
            >
              <Input
                name="description"
                label="توضیحات"
                placeholder="توضیحات را وارد کنید"
                onChange={(e) => setDescriptionValue(e.target.value)}
                value={descriptionValue}
                type="text"
              />
              {confirmModalState === TransactionState.Succeed &&
                selectedItem.type.id === TransactionType.Withdrawal &&
                selectedItem.market.symbol.toUpperCase() === "IRT" && (
                  <Input
                    name="digitalReceipt"
                    label="رسید دیجیتالی"
                    placeholder="رسید دیجیتالی را وارد کنید"
                    onChange={(e) => setDigitalReceiptValue(e.target.value)}
                    value={digitalReceiptValue}
                    type="text"
                  />
                )}
            </div>
          )}
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              loading={approveLoading}
              disabled={approveLoading}
              htmlType="button"
              onClick={onConfirmHandler}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => closeAppproveModal()}
            />
          </div>
        </Modal>
      )}
      {selectedItem && (
        <Modal
          setIsOpen={setPayoutModalIsOpen}
          isOpen={payoutModalIsOpen}
          closeAble
          title="آیا از تسویه تراکنش مطمین هستید؟"
          className="transaction-payout-modal"
        >
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              loading={payoutLoading}
              disabled={payoutLoading}
              htmlType="button"
              onClick={onApproveAndPayOutHandler}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => closePayoutModal()}
            />
          </div>
        </Modal>
      )}
      <Card title="تراکنش ها" blur={cardLoading} className="desktop-show">
        <div className="filter-row">
          <div className="fields">
            <Input
              type="text"
              value={filterValues.keywords}
              placeholder="جستجو کنید ..."
              name="search"
              className="search"
              onPressEnter={filterHandle}
              onChange={(e) =>
                setFilterValues({ ...filterValues, keywords: e.target.value })
              }
            />

            {
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {transactionTypes.length &&
                      transactionTypes.map((type, index) => (
                        <Menu.Item
                          key={index}
                          onClick={() =>
                            setFilterValues({ ...filterValues, type })
                          }
                        >
                          {type.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues.type?.title}
                />
              </Dropdown>
            }

            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {transactionStates.length &&
                    transactionStates.map((state, index) => (
                      <Menu.Item
                        key={index}
                        onClick={() =>
                          setFilterValues({ ...filterValues, state })
                        }
                      >
                        {state.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filterValues.state?.title}
              />
            </Dropdown>

            {transactionPlatforms && (
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {transactionMarketsType.length &&
                      transactionMarketsType.map((marketType, index) => (
                        <Menu.Item
                          key={index}
                          onClick={() =>
                            setFilterValues({ ...filterValues, marketType })
                          }
                        >
                          {marketType.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues.marketType?.title}
                />
              </Dropdown>
            )}

            {transactionMarketsType && (
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {transactionPlatforms.length &&
                      transactionPlatforms.map((platform, index) => (
                        <Menu.Item
                          key={index}
                          onClick={() =>
                            setFilterValues({ ...filterValues, platform })
                          }
                        >
                          {platform.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues.platform?.title}
                />
              </Dropdown>
            )}
            {transactionDepositCurrencyTypes && (
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {transactionDepositCurrencyTypes.length &&
                      transactionDepositCurrencyTypes.map(
                        (depositType, index) => (
                          <Menu.Item
                            key={index}
                            onClick={() =>
                              setFilterValues({
                                ...filterValues,
                                depositCurrencyType: depositType,
                              })
                            }
                          >
                            {depositType.title}
                          </Menu.Item>
                        )
                      )}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues.depositCurrencyType?.title}
                />
              </Dropdown>
            )}
          </div>
        </div>
        <div className="filter-row">
          <div className="fields">
            <Calendar
              value={filterValues.startDate}
              placeholder="از تاریخ"
              name="startDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  startDate: value,
                }))
              }
            />
            <Calendar
              value={filterValues.endDate}
              placeholder="تا تاریخ"
              name="endDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  endDate: value,
                }))
              }
            />
          </div>
          <div className="buttons">
            <Button
              loading={loading}
              disabled={loading}
              text="اعمال فیلتر"
              type="success"
              onClick={() => filterHandle()}
            />
            <span
              onClick={() => {
                setFilterValues({
                  keywords: "",
                  startDate: "",
                  endDate: "",
                  type: defaultTypeItems[0],
                  state: defaultStateItems[0],
                  marketType: defaultMarketTypeItems[0],
                  platform: defaultPlatformItems[0],
                  depositCurrencyType: defaultDepositCurrencyTypes[0],
                });
                getAllTransactions(
                  setCardLoading,
                  setTransactions,
                  1,
                  pageSize,
                  "",
                  setTransactionsCount
                );
              }}
            >
              حذف فیلتر
            </span>
          </div>
        </div>
        {selectedRows && selectedRows?.length > 1 && (
          <div className="action-row">
            <label htmlFor="">عملیات: </label>
            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {actionItems &&
                    actionItems.map((state) => (
                      <Menu.Item onClick={() => setSelectedActionItem(state)}>
                        {state.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={selectedActionItem.title}
              />
            </Dropdown>
            <Button
              disabled={selectedActionItem.id === 0}
              type="info"
              text="اصلاح تراکنش‌های انتخاب شده"
              onClick={() => openActionConfirmModal()}
            />
            <Button
              type="outlined"
              text={`${selectedRows?.length} آیتم انتخاب شده`}
            />
          </div>
        )}
        {transactions && (
          <Table
            filters={query}
            getData={getAllTransactions}
            setData={setTransactions}
            setLoader={setCardLoading}
            pagination={transactionsCount}
            setCount={setTransactionsCount}
            data={transactions.items}
            column={columns}
            pageSize={transactionsCount?.pageSize ?? 10}
            setPageSize={updatePageSize as Dispatch<SetStateAction<number>>}
            conditionalRowStyles={[
              {
                when: (row: any) =>
                  row.state.id === TransactionState.ManualDoing,
                style: {
                  backgroundColor: "#fac89861",
                },
              },
            ]}
            selectableRows
            onSelectRowsChange={(selected) => {
              setSelectedRows(selected.selectedRows);
            }}
          />
        )}
      </Card>
    </div>
  );
};
