import {
  CalendarOutlined,
  MessageOutlined,
  SecurityScanOutlined,
  ShoppingCartOutlined,
  SwapOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Col, Row, Spin } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import {
  Dashboard,
  DateFilterValues,
  FullReport,
  GatewayReport,
  OrderAndTransactionDataGraph,
  TrxUsdtReport,
  UserDataGraph,
} from "core/domains/dashboard/dashboard";
import * as React from "react";
import { Link } from "react-router-dom";
import { getViaAuth, responseValidator } from "utils/scripts/api";
import { digitSeparator, faNumToEnNum } from "utils/scripts/decimal";
import "./main.scss";
import { Table } from "components/table/table";
import { TableColumn } from "react-data-table-component";
import {
  getOrdersAndTransactionsDataGraph,
  getUsersDataGraph,
} from "../../../core/repos/dashboard";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import moment from "jalali-moment";
import { OrdersSum } from "./charts/orders-sum/orders-sum";
import { AllCount } from "./charts/all-orders-count/all-count";
import { OrdersCount } from "./charts/orders-count/orders-count";
import { UsersDataChart } from "./charts/users-data/users-data";

const initialFilters = {
  startDate: moment().subtract(7, "days").format("jYYYY/jMM/jDD"),
  endDate: "",
};

const emptyFilters = {
  startDate: "",
  endDate: "",
};
export const Main: React.FC = () => {
  const [indexes, setIndexes] = React.useState<Dashboard>();
  const [filterValues, setFilterValues] =
    React.useState<DateFilterValues>(initialFilters);
  const [submitFilterLoading, setSubmitFilterLoading] =
    React.useState<boolean>(false);
  const [clearFilterLoading, setClearFilterLoading] =
    React.useState<boolean>(false);
  const [query, setQuery] = React.useState("");
  const [gateways, setGateways] = React.useState<GatewayReport>();
  const [trxUsdtData, setTrxUsdtData] = React.useState<TrxUsdtReport>();
  const [gatewaysResult, setGatewaysResult] = React.useState<any[]>([]);
  const [trxUsdtResult, setTrxUsdtResult] = React.useState<any[]>([]);
  const [fullReports, setFullReports] = React.useState<FullReport>();
  const [userDataGraph, setUserDataGraph] = React.useState<UserDataGraph>();
  const [ordersAndTransactionsDataGraph, setOrdersAndTransactionsDataGraph] =
    React.useState<OrderAndTransactionDataGraph>();
  const [fullReportsResult, setFullReportsResult] = React.useState<any[]>([]);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);

  const startDateRef = React.useRef(null);
  const endDateRef = React.useRef(null);

  const getGatewaysReport = () => {
    setCardLoading(true);
    getViaAuth(API.dashboard.gateways).then((data: any) => {
      const keys = Object.keys(data.value);
      const values = Object.values(data.value);
      const arr: any[] = [];
      setCardLoading(false);
      if (responseValidator(data.status)) {
        setGateways(data.value);
        keys.forEach((key, index) => {
          values.forEach((value, idx) => {
            index === idx &&
              arr.push({
                gateway:
                  keys[idx] === "vandarTransactionsSum"
                    ? "درگاه وندار"
                    : keys[idx] === "zibalTransactionsSum"
                    ? "درگاه زیبال"
                    : keys[idx] === "jibimoTransactionsSum"
                    ? "درگاه جیبیمو"
                    : keys[idx] === "payStarTransactionsSum"
                    ? "درگاه پی استار"
                    : keys[idx] === "jibitTransactionsSum"
                    ? "درگاه جیبیت"
                    : keys[idx] === "nextPayTransactionsSum"
                    ? "درگاه نکست پی"
                    : keys[idx] === "jibimoCashInTransactionsSum"
                    ? "شناسه دار جیبیمو"
                    : keys[idx] === "vandarCashInTransactionsSum"
                    ? "شناسه دار وندار"
                    : "",
                balance: values[index],
              });
          });
        });
        setGatewaysResult(arr);
      }
    });
  };

  const getTrxUsdtReport = () => {
    setCardLoading(true);
    getViaAuth(API.dashboard.trxUsdtReport).then((data: any) => {
      const keys = Object.keys(data.value);
      const values = Object.values(data.value);
      const arr: any[] = [];
      setCardLoading(false);
      if (responseValidator(data.status)) {
        setTrxUsdtData(data.value);
        keys.forEach((key, index) => {
          values.forEach((value, idx) => {
            index === idx &&
              arr.push({
                title:
                  keys[idx] === "sumTronWithdrawals"
                    ? "برداشت حجمی ترون"
                    : keys[idx] === "countTronWithdrawals"
                    ? "تعداد برداشت ترون"
                    : keys[idx] === "sumTronWithdrawalsFee"
                    ? "کارمزد دریافتی ترون"
                    : keys[idx] === "sumUSDTWithdrawals"
                    ? "برداشت حجمی تتر"
                    : keys[idx] === "countUSDTWithdrawals"
                    ? "تعداد برداشت تتر"
                    : keys[idx] === "sumUSDTWithdrawalsFee"
                    ? "کارمزد دریافتی تتر"
                    : "",
                value: digitSeparator(values[index]),
              });
          });
        });
        setTrxUsdtResult(arr);
      }
    });
  };
  const getFullReport = () => {
    setCardLoading(true);
    getViaAuth(API.order.fullReport).then((data: any) => {
      const keys = Object.keys(data.value);
      const values = Object.values(data.value);
      const arr: any[] = [];
      setCardLoading(false);
      if (responseValidator(data.status)) {
        setFullReports(data.value);
        keys.forEach((key, index) => {
          values.forEach((value, idx) => {
            index === idx &&
              arr.push({
                title:
                  keys[idx] === "countSellUSDT"
                    ? "تعداد سفارش فروش تتر به ما"
                    : keys[idx] === "countBuyUSDT"
                    ? "تعداد سفارش خرید تتر از ما"
                    : keys[idx] === "sumSellUSDT"
                    ? "جمع تومانی فروش تتر به ما"
                    : keys[idx] === "sumBuyUSDT"
                    ? "جمع تومانی خرید تتر از ما"
                    : keys[idx] === "countSellCrypto"
                    ? "تعداد سفارش فروش کریپتو به ما"
                    : keys[idx] === "countBuyCrypto"
                    ? "تعداد سفارش خرید کریپتو از ما"
                    : keys[idx] === "sumSellCrypto"
                    ? "جمع تومانی فروش کریپتو به ما"
                    : keys[idx] === "sumBuyCrypto"
                    ? "جمع تومانی خرید کریپتو از ما"
                    : keys[idx] === "sumBuyCryptoInDollar"
                    ? "جمع دلاری خرید کریپتو از ما"
                    : keys[idx] === "sumSellCryptoInDollar"
                    ? "جمع دلاری فروش کریپتو به ما"
                    : "",
                value: digitSeparator((values[index] as number).toFixed(0)),
              });
          });
        });
        setFullReportsResult(arr);
      }
    });
  };
  const columns: TableColumn<any>[] = [
    {
      name: "نام درگاه",
      id: "gateway",
      selector: (row: any) => row?.gateway,
      width: "60%;",
    },
    {
      name: "موجودی",
      id: "balance",
      selector: (row: any) => digitSeparator(row?.balance),
      width: "40%",
    },
  ];

  const getIndexes = () => {
    setCardLoading(true);
    getViaAuth(API.dashboard.index).then((data: any) => {
      setCardLoading(false);
      if (responseValidator(data.status)) {
        setIndexes(data.value);
      }
    });
  };

  const trxUsdtTableColumns: TableColumn<any>[] = [
    {
      name: "عنوان",
      id: "title",
      selector: (row: any) => row?.title,
      width: "60%;",
    },
    {
      name: "مقدار",
      id: "value",
      selector: (row: any) => row?.value,
      width: "40%",
    },
  ];

  const fullReportTableColumns: TableColumn<any>[] = [
    {
      name: "عنوان",
      id: "title",
      selector: (row: any) => row?.title,
      width: "60%;",
    },
    {
      name: "مقدار",
      id: "value",
      selector: (row: any) => row?.value,
      width: "40%",
    },
  ];

  const graphsFilterHandle = () => {
    let _query = "";

    if (filterValues.startDate !== "")
      _query += `${_query.includes("?") ? "&" : "?"}startDate=${moment(
        faNumToEnNum(filterValues?.startDate),
        "jYYYY/jMM/jDD",
        "fa"
      )
        .locale("en")
        .format("YYYY/MM/DD")}`;
    if (filterValues.endDate !== "")
      _query += `${_query.includes("?") ? "&" : "?"}endDate=${moment(
        faNumToEnNum(filterValues?.endDate),
        "jYYYY/jMM/jDD",
        "fa"
      )
        .locale("en")
        .format("YYYY/MM/DD")}`;

    setQuery(_query);
    getUsersDataGraph(setSubmitFilterLoading, setUserDataGraph, _query);
    getOrdersAndTransactionsDataGraph(
      setSubmitFilterLoading,
      setOrdersAndTransactionsDataGraph,
      _query
    );
  };

  React.useEffect(() => {
    getIndexes();
    getGatewaysReport();
    getTrxUsdtReport();
    getFullReport();
    graphsFilterHandle();
  }, []);

  return (
    <div className="vt-dashboard-main">
      <Spin spinning={cardLoading}>
        <div className="filter-row">
          <div className="fields">
            <DatePicker
              className="date-picker"
              value={filterValues.startDate}
              calendar={persian}
              ref={startDateRef}
              locale={persian_fa}
              render={
                <div>
                  <Input
                    name={"fromDate"}
                    onChange={() => console.log(1)}
                    type={"text"}
                    value={filterValues.startDate}
                    icon={<CalendarOutlined />}
                    placeholder="تاریخ"
                    prefix={"از"}
                  />
                </div>
              }
              onChange={(e: any) => {
                setFilterValues({ ...filterValues, startDate: e.format() });
              }}
            />
            <DatePicker
              className="date-picker"
              value={filterValues.endDate}
              calendar={persian}
              ref={endDateRef}
              locale={persian_fa}
              render={
                <div>
                  <Input
                    name={"toDate"}
                    onChange={() => console.log(1)}
                    type={"text"}
                    value={filterValues.endDate}
                    icon={<CalendarOutlined />}
                    placeholder="تاریخ"
                    prefix={"تا"}
                  />
                </div>
              }
              onChange={(e: any) => {
                setFilterValues({ ...filterValues, endDate: e.format() });
              }}
            />
            <div className="buttons">
              <Button
                loading={submitFilterLoading}
                disabled={submitFilterLoading || cardLoading}
                text="اعمال فیلتر"
                type={"success"}
                onClick={graphsFilterHandle}
              />
              <Button
                loading={clearFilterLoading}
                disabled={clearFilterLoading || cardLoading}
                text="حذف فیلتر"
                type={"error"}
                onClick={() => {
                  setFilterValues(emptyFilters);
                  getUsersDataGraph(
                    setClearFilterLoading,
                    setUserDataGraph,
                    ""
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className={"vt-charts"}>
          <UsersDataChart
            registeredUsers={userDataGraph?.registeredUsers}
            authenticatedUsers={userDataGraph?.authenticatedUsers}
          />
          <OrdersCount
            buyCount={ordersAndTransactionsDataGraph?.buyCount}
            sellCount={ordersAndTransactionsDataGraph?.sellCount}
          />
          <AllCount allCount={ordersAndTransactionsDataGraph?.allCount} />
          <OrdersSum
            buySum={ordersAndTransactionsDataGraph?.buySum}
            sellSum={ordersAndTransactionsDataGraph?.sellSum}
          />
        </div>
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" span={12}>
            <Card
              className="active"
              tags={<SwapOutlined />}
              title="موجودی یو ووچر"
            >
              <p>{digitSeparator(indexes?.uusdBalance.toFixed(2))}</p>
              <p className="prices">
                <span>
                  قیمت خرید :{digitSeparator(indexes?.uusdBuyPrice)} تومان
                </span>
                <span className="divider"> | </span>
                <span>
                  قیمت فروش :{digitSeparator(indexes?.uusdSellPrice)} تومان
                </span>
              </p>
              <p className={"balances"}>
                <span>
                  موجودی یو ووچر:{" "}
                  {digitSeparator(indexes?.uusdBalance?.toFixed(2))}
                </span>
              </p>
            </Card>
          </Col>
          <Col className="gutter-row" span={12}>
            <Card
              className="active"
              tags={<ShoppingCartOutlined />}
              title="موجودی تتر و ترون"
            >
              <p className="balances">
                <span>
                  تتر هات :
                  {" " + digitSeparator(indexes?.usdtBalance.toFixed(2)) + " "}
                </span>
                <span className="divider">|</span>
                <span>
                  مازاد هات :
                  {" " +
                    digitSeparator(indexes?.exteraUsdtBalance?.toFixed(2)) ?? 0}
                </span>{" "}
                <span className="divider">|</span>{" "}
                <span>
                  تتر کریپتو(کوکوین) :
                  {" " +
                    digitSeparator(
                      indexes?.activeExchangeUsdtBalance?.toFixed(2) ?? 0
                    ) +
                    " "}
                </span>
              </p>
              <p className="balances">
                <span className="divider">|</span>
                <span>
                  تتر بینگ ایکس :
                  {" " +
                    digitSeparator(indexes?.bingXUSDTBalance?.toFixed(2) ?? 0) +
                    " "}
                </span>
                <span className="divider">|</span>{" "}
                <span>
                  ترون هات :
                  {" " +
                    digitSeparator(indexes?.trxBalance?.toFixed(2) ?? 0) +
                    " "}
                </span>{" "}
                <span className="divider">|</span>{" "}
                <span>
                  ترون بینگ ایکس :
                  {" " +
                    digitSeparator(
                      indexes?.trxExchangeBalance?.toFixed(2) ?? 0
                    ) +
                    " "}
                </span>
              </p>
              <p className="prices">
                <span>
                  قیمت خرید تتر:{digitSeparator(indexes?.usdtBuyPrice)} تومان
                </span>
                <span className="divider"> | </span>
                <span>
                  قیمت فروش تتر :{digitSeparator(indexes?.usdtSellPrice)} تومان
                </span>
              </p>
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Link to={"/wallets/users-balance-list"}>
              <Card tags={<UsergroupAddOutlined />} title="موجودی کل کاربران">
                {digitSeparator(indexes?.allUsersBalance.toFixed(0))} تومان
              </Card>
            </Link>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<UsergroupAddOutlined />} title="تعداد کل کاربران">
              {digitSeparator(indexes?.allUsersCount)}
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<SecurityScanOutlined />} title="کاربران">
              <p className="users-count">
                <span>
                  {" "}
                  جدید:
                  {indexes?.allDayUsersCount ? indexes.allDayUsersCount : ""}
                </span>
                <span className="divider"> | </span>
                <span>
                  احراز شده :
                  {indexes?.allAuthenticatedUsers
                    ? indexes.allAuthenticatedUsers
                    : ""}
                </span>
                <span className="divider"> | </span>
                <span>
                  با تراکنش :
                  {indexes?.allUsersWithTransaction
                    ? indexes.allUsersWithTransaction
                    : ""}
                </span>
              </p>
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<MessageOutlined />} title="تعداد کل خریدها">
              {indexes?.allBuyOrdersCount}
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<SwapOutlined />} title="تعداد کل فروش ها">
              {indexes?.allSellOrdersCount}
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<ShoppingCartOutlined />} title="تعداد کل سفارشات">
              {indexes?.allOrdersCount}
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<SwapOutlined />} title="مبلغ کل خرید ها">
              {digitSeparator(indexes?.allBuyOrdersAmountSum.toFixed(0))} تومان
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<SwapOutlined />} title="مبلغ کل فروش ها">
              {digitSeparator(indexes?.allSellOrdersAmountSum.toFixed(0))} تومان
            </Card>
          </Col>

          <Col className="gutter-row" span={6}>
            <Card tags={<SwapOutlined />} title="مبلغ کل سفارشات">
              {digitSeparator(indexes?.allOrdersAmountSum.toFixed(0))} تومان
            </Card>
          </Col>
          <Col className="gutter-row" span={6}>
            <Card tags={<ShoppingCartOutlined />} title="مجموع برداشت ریالی">
              {digitSeparator(indexes?.irtWithdrawalTransactionsSum.toFixed(0))}{" "}
              تومان
            </Card>
          </Col>

          <Col className="gutter-row" span={6}>
            <Card tags={<ShoppingCartOutlined />} title="مجموع کارمزد ها">
              {digitSeparator(indexes?.commissionAmount.toFixed(0))} تومان
            </Card>
          </Col>
        </Row>
        <Row>
          {gatewaysResult.length > 0 && (
            <>
              <Table
                className="gateways-table"
                column={columns}
                data={gatewaysResult.slice(0, gatewaysResult.length / 2)}
                blur={cardLoading}
              />
              <Table
                className="gateways-table"
                column={columns}
                data={gatewaysResult.slice(gatewaysResult.length / 2)}
                blur={cardLoading}
              />
            </>
          )}
        </Row>
        <Row>
          {trxUsdtResult.length > 0 && (
            <>
              <Table
                className="trxUsdt-table"
                column={trxUsdtTableColumns}
                data={trxUsdtResult.slice(0, trxUsdtResult.length / 2)}
                blur={cardLoading}
              />
              <Table
                className="trxUsdt-table"
                column={trxUsdtTableColumns}
                data={trxUsdtResult.slice(trxUsdtResult.length / 2)}
                blur={cardLoading}
              />
            </>
          )}
        </Row>
        <Row>
          {fullReportsResult.length > 0 && (
            <>
              <Table
                className="fullReport-table"
                column={fullReportTableColumns}
                data={fullReportsResult?.slice(0, 4)}
                blur={cardLoading}
              />
              <Table
                className="fullReport-table"
                column={fullReportTableColumns}
                data={fullReportsResult?.slice(4, fullReportsResult.length)}
                blur={cardLoading}
              />
            </>
          )}
        </Row>
      </Spin>
    </div>
  );
};
