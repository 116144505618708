import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import {
  CheckOutlined,
  CloseOutlined,
  DollarCircleOutlined,
  DownOutlined,
  EditOutlined,
  HourglassOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Calendar, Card, Input, Modal } from "components";
import { Badge } from "components/badge/badge";
import { Table } from "components/table/table";
import moment from "jalali-moment";
import "./index.scss";
import {
  Transaction,
  TransactionsCount,
  TransactionState,
  TransactionStateLabel,
  TransactionType,
} from "core/domains/transaction/transaction";
import {
  editTransactionState,
  getAllCurrencyTransactions,
  getTransactionStates,
  getTransactionTypes,
  payoutTranscation,
} from "core/repos/transaction";
import { digitSeparator } from "utils/scripts/decimal";
import { Enum, FilterValues, PaginationList } from "core/domains";
import { Dropdown, Menu, Tag, Tooltip } from "antd";
import { detect_bank } from "../../../../utils/scripts/bankDetecter";
import { getPageNum } from "../../../../utils/scripts/paggingHelper";
import { getViaAuth, responseValidator } from "../../../../utils/scripts/api";
import { API } from "../../../../apis";
import { convertFaToEnCalendar } from "utils/scripts/calendarExtentions";
import { ConfirmBulkSelectModal } from "../modals/bulk-select-modal/bulk-select-modal";
import { Link } from "react-router-dom";
import { TransactionOrderDetailModal } from "../../../../components/transaction-order-modal/transaction-order-modal";

const { useState } = React;

const defaultStateItems: Enum[] = [
  {
    title: "وضعیت تراکنش",
    id: 0,
  },
];

const defaultTypeItems: Enum[] = [
  {
    title: "نوع تراکنش",
    id: 0,
  },
];

const actionItems: Enum[] = [
  {
    id: 0,
    title: "انتخاب عملیات",
  },
  {
    id: 1,
    title: "تایید و تسویه",
  },
  {
    id: 2,
    title: "تایید",
  },
  {
    id: 8,
    title: "لغو",
  },
];

type Props = {
  state?: number[];
  type?: number;
};
export const CurrencyTransactions: React.FC<Props> = (Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] =
    useState<PaginationList<Transaction>>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    type: defaultTypeItems[0],
    state: defaultStateItems[0],
  });
  const [selectedItem, setSelectedItem] = useState<Transaction>();

  const [transactionStates, setTransactionStates] = useState<Enum[]>([]);
  const [transactionTypes, setTransactionTypes] = useState<Enum[]>([]);
  const [payoutModalIsOpen, setPayoutModalIsOpen] = useState<boolean>(false);
  const [approveModalIsOpen, setApproveModalIsOpen] = useState<boolean>(false);
  const [descriptionValue, setDescriptionValue] = useState<string>("");
  const [digitalReceiptValue, setDigitalReceiptValue] = useState<string>("");
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [payoutLoading, setPayoutLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedTotalAmount, setSelectedTotalAmount] = useState<number>(0);

  const [selectedRows, setSelectedRows] = useState<Transaction[]>([]);
  const [isOpenConfirmBulkActionModal, setIsOpenConfirmBulkActionModal] =
    useState<boolean>(false);
  const [selectedActionItem, setSelectedActionItem] = useState<Enum>(
    actionItems[0]
  );
  const [actionApproveLoading, setActionApproveLoading] =
    React.useState<boolean>(false);
  const [confirmModalState, setConfirmModalState] = useState<TransactionState>(
    TransactionState.WaitingForAdmin
  );

  const [transactionsCount, setTransactionsCount] =
    useState<TransactionsCount>();

  const [summaryTransactions, setSummaryTransactions] = useState<any>();

  const closePayoutModal = () => setPayoutModalIsOpen(false);

  const updatePageSize = (newPageSize: number) => {
    setTransactionsCount((prevState: TransactionsCount | undefined) => ({
      ...prevState!,
      pageSize: newPageSize,
    }));
  };

  const openActionConfirmModal = () => {
    setActionApproveLoading(false);
    setIsOpenConfirmBulkActionModal(true);
  };

  const openPayoutModal = (item: Transaction) => {
    setSelectedItem(item);
    setPayoutModalIsOpen(true);
  };

  const closeAppproveModal = () => {
    setDescriptionValue("");
    setDigitalReceiptValue("");
    setApproveModalIsOpen(false);
  };

  const handleSelectRowsChange = (selected: {
    selectedRows: Transaction[];
  }) => {
    const totalAmount = selected.selectedRows.reduce(
      (sum: number, row: Transaction) => sum + (row?.payoutAmount ?? 0),
      0
    );
    setSelectedTotalAmount(totalAmount);
    setSelectedRows(selected.selectedRows);
  };

  const columns: any[] = [
    {
      name: "#",
      id: "index",
      selector: (row: Transaction, rowIndex: number) => rowIndex + 1,
      width: "4%",
    },

    {
      name: "شناسه",
      id: "trackingCode",
      selector: (row: Transaction) => row.trackingCode,
      cell: (row: Transaction) => (
        <span className="en">{row.trackingCode}</span>
      ),
      width: "8.5%",
    },
    {
      name: "نوع تراکنش",
      id: "transactionType",
      width: "7.5%",
      selector: (row: Transaction) => row.type.title,
      cell: (row: Transaction) => (
        <>
          <Tag color={`${row.type.id === 1 ? "green" : "red"}`}>
            {row.type.title}
          </Tag>
        </>
      ),
    },
    {
      name: "مقدار (تومان)",
      id: "amount",

      selector: (row: Transaction) => digitSeparator(row.amount),
      cell: (record: Transaction) => (
        <div className="column-amount">
          <span>
            <Tooltip placement="top" title="مقدار">
              {digitSeparator(record?.amount)}
            </Tooltip>
          </span>
          {record.type.id === TransactionType.Withdrawal &&
            record.market.symbol === "IRT" && (
              <p>
                <Tooltip placement="top" title="مبلغ جهت تسویه">
                  {digitSeparator(record?.payoutAmount)}
                </Tooltip>
              </p>
            )}
        </div>
      ),
      width: "10%",
    },
    {
      name: "نام",
      id: "createdBy",

      cell: (row: Transaction) => (
        <div className="column-createdBy">
          <span className="day">{row.createdBy}</span>
          <p className="">{row.phoneNumber}</p>
        </div>
      ),
      width: "13%",
    },
    {
      name: "حساب بانکی",
      id: "bankAccount",
      width: "13%",
      cell: (row: Transaction) => (
        <div className="column-bankAccount">
          <span className="day">{row.bankAccount?.cardNumber}</span>
          <p className=" iban">
            {row.bankAccount?.iBan?.startsWith("IR")
              ? row.bankAccount?.iBan
              : "IR" + row.bankAccount?.iBan}
          </p>
        </div>
      ),
      selector: (row: Transaction) => row.bankAccount?.cardNumber,
    },
    {
      name: "بانک",
      id: "bankName",
      width: "7.5%",
      selector: (row: Transaction) =>
        row.bankAccount?.bankName
          ? row.bankAccount?.bankName.replace("بانک", "")
          : detect_bank(row.bankAccount?.cardNumber)?.bank_title.replace(
              "بانک",
              ""
            ),
    },

    {
      name: "زمان",
      id: "createdAt",
      width: "8%",
      selector: (row: Transaction) => row.createdAt,

      cell: (row: Transaction) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdAt)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdAt).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "وضعیت",
      id: "state",
      width: "13%",
      selector: (row: Transaction) => row.state.id,
      cell: (row: Transaction) => (
        <Badge
          text={row.state.id == 2 ? "موفق" : row.state.title}
          type={
            row.state.id == 2
              ? "success"
              : row.state.id == 1 || row.state.id == 6
              ? "waiting"
              : "error"
          }
          icon={`/assets/icons/table/${
            row.state.id == 2
              ? "success"
              : row.state.id == 1 || row.state.id == 6
              ? "waiting"
              : "danger"
          }.svg`}
        />
      ),
    },
    {
      name: "جزئیات",
      id: "detail",

      cell: (row: Transaction) => (
        <div className="actions">
          {row.state.id !== TransactionState.Succeed && (
            <Tooltip placement="top" title={"تایید و تسویه تراکنش"}>
              <DollarCircleOutlined onClick={() => openPayoutModal(row)} />
            </Tooltip>
          )}
          {row.state.id !== TransactionState.Succeed && (
            <Tooltip placement="top" title={"تایید تراکنش"}>
              <CheckOutlined
                onClick={() => openConfirmModal(row, TransactionState.Succeed)}
              />
            </Tooltip>
          )}
          {row.state.id !== TransactionState.Succeed && (
            <Tooltip placement="top" title={"لغو تراکنش"}>
              <CloseOutlined
                onClick={() => openConfirmModal(row, TransactionState.Cancel)}
              />
            </Tooltip>
          )}
          {row.state.id !== TransactionState.Succeed &&
            row.type.id === TransactionType.Withdrawal && (
              <Tooltip placement="top" title={"درحال اقدام دستی"}>
                <HourglassOutlined
                  onClick={() =>
                    openConfirmModal(row, TransactionState.ManualDoing)
                  }
                />
              </Tooltip>
            )}
          <Tooltip placement="top" title={"جزئیات تراکنش"}>
            <InfoCircleOutlined onClick={() => handleModal(row)} />
          </Tooltip>

          <Tooltip placement="top" title={"ویرایش تراکنش"}>
            <Link to={`/transactions/edit/${row.id}`}>
              <EditOutlined />
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  function handleModal(item: Transaction) {
    setIsOpen(true);
    setSelectedItem(item);
  }
  const filterHandle = () => {
    var _query = "";
    if (Props.state || filterValues?.state?.id !== 0) {
      _query += `&state=${Props.state || filterValues.state?.id}`;
    }
    if (Props.type || filterValues?.type?.id !== 0)
      _query += `&type=${Props.type || filterValues.type?.id}`;
    if (filterValues.startDate)
      _query += `&startDate=${convertFaToEnCalendar(filterValues.startDate)}`;
    if (filterValues.endDate)
      _query += `&endDate=${convertFaToEnCalendar(filterValues.endDate)}`;
    if (filterValues.keywords) {
      _query += "&keywords=";
      _query += filterValues.keywords;
    }
    setQuery(_query);
    getAllCurrencyTransactions(
      setLoading,
      setTransactions,
      1,
      pageSize,
      _query,
      setTransactionsCount
    );
  };

  const fetchAllData = async (query: string) => {
    await getAllCurrencyTransactions(
      setCardLoading,
      setTransactions,
      getPageNum(),
      transactionsCount?.pageSize ?? 10,
      query,
      setTransactionsCount
    );
  };

  React.useEffect(() => {
    !transactionStates.length &&
      getTransactionStates(setCardLoading, setTransactionStates);

    !transactionTypes.length &&
      getTransactionTypes(setCardLoading, setTransactionTypes);

    getViaAuth(
      API.transaction.getCountAndSum + "?symbol=IRT&state=6&state=9&type=2"
    ).then((response: any) => {
      if (responseValidator(response.status)) {
        setSummaryTransactions(response.value);
      }
    });
  }, []);

  React.useEffect(() => {
    var _query = "";
    if (Props.state) {
      _query += `state=${Props.state}`;
    }
    if (Props.type) {
      _query += `${_query.includes("state") ? "&" : ""}type=${Props.type}`;
    }
    setQuery(_query);
  }, []);

  React.useEffect(() => {
    var _query = "";
    if (Props.state) {
      Props.state.map((state, i) => {
        _query += `state=${state}&`;
      });
    }
    if (Props.type) {
      _query += `${_query.endsWith("&") ? "" : "&"}type=${Props.type}`;
    }
    setQuery(_query);
    fetchAllData(_query);
  }, [window.location.pathname]);

  React.useEffect(() => {
    if (selectedRows && selectedRows.length < 2)
      setSelectedActionItem(actionItems[0]);
  }, [selectedRows]);

  const openConfirmModal = (item: Transaction, state: TransactionState) => {
    setSelectedItem(item);
    setConfirmModalState(state);
    setApproveModalIsOpen(true);
  };

  async function onApproveHandler() {
    selectedItem &&
      (await editTransactionState(
        setApproveLoading,
        selectedItem,
        closeAppproveModal,
        confirmModalState,
        (confirmModalState === TransactionState.Succeed ||
          confirmModalState === TransactionState.Cancel) &&
          descriptionValue !== ""
          ? descriptionValue
          : undefined,
        selectedItem.type.id === TransactionType.Withdrawal &&
          selectedItem.market.symbol.toUpperCase() === "IRT" &&
          digitalReceiptValue !== ""
          ? digitalReceiptValue
          : undefined
      ));
    fetchAllData(query);
  }

  async function onApproveAndPayout() {
    setPayoutLoading(true);
    await payoutTranscation(
      setPayoutLoading,
      selectedItem,
      setPayoutModalIsOpen
    );

    fetchAllData(query);
  }

  return (
    <div className="currency-transactions">
      {selectedItem && (
        <TransactionOrderDetailModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          transactionItem={selectedItem}
        />
      )}

      {selectedRows && selectedRows.length > 0 && (
        <ConfirmBulkSelectModal
          isOpen={isOpenConfirmBulkActionModal}
          setIsOpen={setIsOpenConfirmBulkActionModal}
          buttonLoading={actionApproveLoading}
          selectedActionItem={selectedActionItem}
          selectedRows={selectedRows}
          setButtonLoading={setActionApproveLoading}
          setSelectedRows={setSelectedRows}
          selectedTotalAmount={selectedTotalAmount}
          fetchData={async () =>
            getAllCurrencyTransactions(
              setCardLoading,
              setTransactions,
              getPageNum(),
              pageSize,
              query,
              setTransactionsCount
            )
          }
          actionItem={selectedActionItem}
        />
      )}

      {selectedItem && (
        <Modal
          setIsOpen={setApproveModalIsOpen}
          isOpen={approveModalIsOpen}
          title={`از تغییر وضعیت به  ${TransactionStateLabel.get(
            confirmModalState
          )} تراکش   ${selectedItem.trackingCode}   مطمئن هستید؟`}
          closeAble
          className="transaction-approve-modal"
          onClose={closeAppproveModal}
        >
          {(confirmModalState === TransactionState.Succeed ||
            confirmModalState === TransactionState.Cancel) && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "32px" }}
            >
              <Input
                name="description"
                label="توضیحات"
                placeholder="توضیحات را وارد کنید"
                onChange={(e) => setDescriptionValue(e.target.value)}
                value={descriptionValue}
                type="text"
              />
              {confirmModalState === TransactionState.Succeed &&
                selectedItem.type.id === TransactionType.Withdrawal &&
                selectedItem.market.symbol.toUpperCase() === "IRT" && (
                  <Input
                    name="digitalReceipt"
                    label="رسید دیجیتالی"
                    placeholder="رسید دیجیتالی را وارد کنید"
                    onChange={(e) => setDigitalReceiptValue(e.target.value)}
                    value={digitalReceiptValue}
                    type="text"
                  />
                )}
            </div>
          )}
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              loading={approveLoading}
              disabled={approveLoading}
              htmlType="button"
              onClick={onApproveHandler}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => closeAppproveModal()}
            />
          </div>
        </Modal>
      )}
      {selectedItem && (
        <Modal
          setIsOpen={setPayoutModalIsOpen}
          isOpen={payoutModalIsOpen}
          closeAble
          title="آیا از تسویه تراکنش مطمین هستید؟"
          className="transaction-payout-modal"
        >
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              loading={payoutLoading}
              disabled={payoutLoading}
              htmlType="button"
              onClick={(e) => onApproveAndPayout()}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => closePayoutModal()}
            />
          </div>
        </Modal>
      )}
      <Card
        title={`${
          Props.type
            ? "برداشت های ریالی در انتظار تایید"
            : "واریز و برداشت ریالی"
        }`}
        blur={cardLoading}
        className="desktop-show"
      >
        <div className="filter-row">
          <div className="fields">
            <Input
              type="text"
              value={filterValues.keywords}
              placeholder="جستجو کنید ..."
              name="search"
              className="search"
              onPressEnter={filterHandle}
              onChange={(e) =>
                setFilterValues({ ...filterValues, keywords: e.target.value })
              }
            />

            {!Props.type && (
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {transactionTypes.length &&
                      transactionTypes.map((type) => (
                        <Menu.Item
                          onClick={() =>
                            setFilterValues({ ...filterValues, type })
                          }
                        >
                          {type.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues.type?.title}
                />
              </Dropdown>
            )}

            {!Props.state && (
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {transactionStates.length &&
                      transactionStates.map((state) => (
                        <Menu.Item
                          onClick={() =>
                            setFilterValues({ ...filterValues, state })
                          }
                        >
                          {state.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues.state?.title}
                />
              </Dropdown>
            )}

            <Calendar
              value={filterValues.startDate}
              placeholder="از تاریخ"
              name="startDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  startDate: value,
                }))
              }
            />
            <Calendar
              value={filterValues.endDate}
              placeholder="تا تاریخ"
              name="endDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  endDate: value,
                }))
              }
            />
          </div>
          <div className="buttons">
            <Button
              loading={loading}
              disabled={loading}
              text="اعمال فیلتر"
              type="success"
              onClick={() => filterHandle()}
            />
            <span
              onClick={(e) => {
                setFilterValues({
                  keywords: "",
                  type: transactionTypes[0],
                  state: transactionStates[0],
                  startDate: "",
                  endDate: "",
                });
                getAllCurrencyTransactions(
                  setCardLoading,
                  setTransactions,
                  1,
                  pageSize,
                  `${Props.state ? "&state=" + Props.state : ""}${
                    Props.type ? "&type=" + Props.type : ""
                  }`,
                  setTransactionsCount
                );
              }}
            >
              حذف فیلتر
            </span>
          </div>
        </div>
        {selectedRows && selectedRows?.length > 1 && (
          <div className="action-row">
            <label htmlFor="">عملیات: </label>
            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {actionItems &&
                    actionItems.map((state, key) => (
                      <Menu.Item onClick={() => setSelectedActionItem(state)}>
                        {state.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={selectedActionItem.title}
              />
            </Dropdown>
            <Button
              disabled={selectedActionItem.id === 0}
              type="info"
              text="اصلاح تراکنش‌های انتخاب شده"
              onClick={() => openActionConfirmModal()}
            />
            <Button
              type="outlined"
              text={`${selectedRows?.length} آیتم انتخاب شده`}
            />
          </div>
        )}
        {transactions && (
          <Table
            filters={query}
            getData={getAllCurrencyTransactions}
            setData={setTransactions}
            setLoader={setCardLoading}
            pagination={transactionsCount}
            setCount={setTransactionsCount}
            data={transactions.items}
            column={columns}
            pageSize={transactionsCount?.pageSize}
            setPageSize={updatePageSize as Dispatch<SetStateAction<number>>}
            selectableRows
            onSelectRowsChange={handleSelectRowsChange}
            conditionalRowStyles={[
              {
                when: (row: any) =>
                  row.state.id === TransactionState.ManualDoing,
                style: {
                  backgroundColor: "#fac89861",
                },
              },
            ]}
          />
        )}
        {transactionsCount && transactions && (
          <div className="summary">
            <span>
              تعداد کل: {digitSeparator(transactionsCount.totalCount)}
            </span>
            <span>
              جمع صفحه :
              {digitSeparator(
                transactions.items
                  .map((q) => q.amount)
                  .reduce((prev, curr) => prev + curr, 0) ?? 0
              )}
            </span>
            <span>جمع کل: {digitSeparator(summaryTransactions.sum)}</span>
          </div>
        )}
      </Card>
    </div>
  );
};
