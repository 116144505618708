import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import * as React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import "./index.scss";

interface JibimoSetting {
  username?: string;
  password?: string;
  secret_key?: string;
  batch?: string;
}

export const JibimoSettings: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [jibimoSettings, setJibimoSettings] = React.useState<JibimoSetting>();

  const getJibimoSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.jibimoSettings).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setJibimoSettings(response.value);
      }
    });
  };

  const onConfirmHandler = () => {
    setLoading(true);
    put(API.settings.changeJibimoSettings, {
      ...jibimoSettings,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success(response.data.message);
        getJibimoSettings();
      }
    });
  };

  React.useEffect(() => {
    getJibimoSettings();
  }, []);

  return (
    <>
      <Card blur={cardLoading} className="vt-dashboard-jibimo-settings">
        <Card title={`کانفیگ درگاه جیبیمو`} className="gateway-item">
          <Input
            label="نام کاربری"
            type="text"
            name="username"
            value={jibimoSettings?.username}
            onChange={(e) =>
              setJibimoSettings({ ...jibimoSettings, username: e.target.value })
            }
            dir="ltr"
          />
          <Input
            label="رمز عبور"
            type="text"
            name="password"
            value={jibimoSettings?.password}
            onChange={(e) =>
              setJibimoSettings({ ...jibimoSettings, password: e.target.value })
            }
            dir="ltr"
          />
          <Input
            label="کلید امنیتی"
            type="text"
            name="secretKey"
            value={jibimoSettings?.secret_key}
            onChange={(e) =>
              setJibimoSettings({
                ...jibimoSettings,
                secret_key: e.target.value,
              })
            }
            dir="ltr"
          />
          <Input
            label="بچ"
            type="text"
            name="batch"
            value={jibimoSettings?.batch}
            onChange={(e) =>
              setJibimoSettings({
                ...jibimoSettings,
                batch: e.target.value,
              })
            }
            dir="ltr"
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandler()}
            loading={loading}
            disabled={
              loading ||
              jibimoSettings?.secret_key === "" ||
              jibimoSettings?.username === "" ||
              jibimoSettings?.password === "" ||
              jibimoSettings?.batch === ""
            }
          />
        </Card>
      </Card>
    </>
  );
};
