import { getViaAuth, responseValidator } from "../../utils/scripts/api";
import { API } from "../../apis";

export const getUsersDataGraph = (
  setloading: any,
  setdata: any,
  query: string
) => {
  setloading(true);
  getViaAuth(API.dashboard.usersDataGraph + `${query}`).then((data: any) => {
    setloading(false);
    if (responseValidator(data.status)) {
      setdata(data.value);
    }
  });
};

export const getOrdersAndTransactionsDataGraph = (
  setloading: any,
  setdata: any,
  query: string
) => {
  setloading(true);
  getViaAuth(API.dashboard.ordersAndTransactionsDataGraph + `${query}`).then(
    (data: any) => {
      setloading(false);
      if (responseValidator(data.status)) {
        setdata(data.value);
      }
    }
  );
};
