import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import * as React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import "./index.scss";

interface VandarSetting {
  token?: string;
  refreshToken?: string;
  businessName?: string;
  port?: string;
}

export const VandarSettings: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [vandarSettings, setVandarSettings] = React.useState<VandarSetting>();

  const getVandarSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.vandarSettings).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setVandarSettings(response.value);
      }
    });
  };

  const onConfirmHandler = () => {
    setLoading(true);
    put(API.settings.changeVandarSettings, {
      ...vandarSettings,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success(response.data.message);
        getVandarSettings();
      }
    });
  };

  React.useEffect(() => {
    getVandarSettings();
  }, []);

  return (
    <>
      <Card blur={cardLoading} className="vt-dashboard-vandar-settings">
        <Card title={`کانفیگ درگاه وندار`} className="gateway-item">
          <Input
            label="نام تجاری"
            type="text"
            name="bussinessName"
            value={vandarSettings?.businessName}
            onChange={(e) =>
              setVandarSettings({
                ...vandarSettings,
                businessName: e.target.value,
              })
            }
            dir="rtl"
            maxLength={50}
          />
          <Input
            label="نام بانک درگاه (port)"
            type="text"
            name="port"
            value={vandarSettings?.port}
            onChange={(e) =>
              setVandarSettings({ ...vandarSettings, port: e.target.value })
            }
            dir="ltr"
          />
          <Input
            label="توکن"
            type="text"
            name="token"
            value={vandarSettings?.token}
            onChange={(e) =>
              setVandarSettings({ ...vandarSettings, token: e.target.value })
            }
            dir="ltr"
          />
          <Input
            label="رفرش توکن"
            type="text"
            name="refreshToken"
            value={vandarSettings?.refreshToken}
            onChange={(e) =>
              setVandarSettings({
                ...vandarSettings,
                refreshToken: e.target.value,
              })
            }
            dir="ltr"
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandler()}
            loading={loading}
            disabled={
              loading ||
              vandarSettings?.token === "" ||
              vandarSettings?.refreshToken === "" ||
              vandarSettings?.businessName === ""
            }
          />
        </Card>
      </Card>
    </>
  );
};
