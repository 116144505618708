import * as React from "react";
import { Dispatch, Fragment, SetStateAction } from "react";
import { DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Divider, Dropdown, Menu, Tag } from "antd";
import { Button, Calendar, Card, Input } from "components";
import { Badge } from "components/badge/badge";
import { Table } from "components/table/table";
import moment from "jalali-moment";
import "./index.scss";
import {
  Enum,
  Order,
  OrderFilterValues,
  OrdersCount,
  PaginationList,
} from "core/domains";
import {
  getAllOrders,
  getAllOrdersCount,
  getPlatforms,
  getTransactionMarketsTypes,
} from "core/repos";
import { digitSeparator } from "utils/scripts/decimal";
import { getIconPath } from "../../../utils/scripts/getIconPath";
import { getPageNum } from "../../../utils/scripts/paggingHelper";
import { convertFaToEnCalendar } from "utils/scripts/calendarExtentions";
import { TransactionOrderDetailModal } from "../../../components/transaction-order-modal/transaction-order-modal";
import { Helmet } from "react-helmet";

const { useState } = React;

const sides: Enum[] = [
  {
    id: 0,
    title: "همه",
  },
  {
    id: 1,
    title: "خرید",
  },
  {
    id: 2,
    title: "فروش",
  },
  {
    id: 3,
    title: "مبادله",
  },
];
const defaultMarketTypeItems: Enum[] = [
  {
    title: "نوع ارز",
    id: 0,
  },
];

const defaultPlatformItems: Enum[] = [
  {
    title: "همه پلتفرم ها",
    id: 0,
  },
];

const defaultOrderSourceTypeItems: Enum[] = [
  {
    title: "همه مبداء‌ها",
    id: -1,
  },
  {
    title: "کیف پول",
    id: 0,
  },
  {
    title: "درگاه",
    id: 1,
  },
];

export const Orders: React.FC = () => {
  const [orderList, setOrderList] = useState<PaginationList<Order>>();
  const [ordersCount, setOrdersCount] = useState<OrdersCount>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPaging, setLoadingPaging] = useState<boolean>(false);

  const [transactionMarketsType, setTransactionMarketsType] = useState<Enum[]>(
    []
  );
  const [pageSize, setPageSize] = useState<number>(10);
  const [orderSourceType, setOrderSourceType] = useState<Enum[]>(
    defaultOrderSourceTypeItems
  );
  const [platforms, setPlatforms] = useState<Enum[]>([]);
  const [query, setQuery] = useState<string>("");

  const [filterValues, setFilterValues] = useState<OrderFilterValues>({
    side: sides[0],
    marketType: defaultMarketTypeItems[0],
    source: defaultOrderSourceTypeItems[0],
    platform: defaultPlatformItems[0],
  });

  const [selectedItem, setSelectedItem] = useState<Order>();

  const columns: any[] = [
    {
      name: "#",
      id: "index",
      selector: (row: Order, rowIndex: number) => rowIndex + 1,
      width: "4%", // Adjusted
    },
    {
      name: "توسط",
      id: "createdBy",
      width: "10%",
      cell: (row: Order) => (
        <div className="column-createdBy">
          <span className="name">{row.createdBy}</span>
          <p className="phone">{row.phoneNumber}</p>
        </div>
      ),
    },
    {
      name: "شناسه ",
      id: "trackingCode",
      width: "8%",
      selector: (row: Order) => row.trackingCode,
    },
    {
      name: "جفت",
      id: "pair",
      selector: (row: Order) => row.pair.base + row.pair.quote,
      cell: (row: Order) => <img src={getIconPath(row.pair.base)} />,
      width: "5%",
    },
    {
      name: "خرید/فروش",
      id: "side",
      width: "8%",
      selector: (row: Order) => row.side.title,
      cell: (row: Order) => (
        <>
          <Tag color={`${row.side.id === 1 ? "green" : "red"}`}>
            {row.side.title}
          </Tag>
        </>
      ),
    },
    {
      name: "مقدار پرداخت شده",
      id: "sourceQuantity",
      width: "9%",
      selector: (row: Order) => row.sourceQuantity,
      cell: (row: Order) => (
        <Fragment>
          <span className="en">
            {row.side.id === 2 ? row.pair.base : row.pair.quote}
          </span>
          <span>
            {row.side.id === 1
              ? digitSeparator(row.sourceQuantity.toFixed(0))
              : digitSeparator(row.sourceQuantity)}
          </span>
        </Fragment>
      ),
    },
    {
      name: "مقدار دریافت شده",
      id: "destinationQuantity",
      width: "9%",
      selector: (row: Order) => row.destinationQuantity,
      cell: (row: Order) => (
        <Fragment>
          <span className="en">
            {row.side.id === 2 ? row.pair.quote : row.pair.base}
          </span>
          {row.side.id === 2
            ? digitSeparator(row.destinationQuantity.toFixed(0))
            : digitSeparator(row.destinationQuantity)}
        </Fragment>
      ),
    },
    {
      name: "قیمت واحد (تومان)",
      id: "onPrice",
      width: "8%",
      selector: (row: Order) => digitSeparator(row.onPrice),
    },
    {
      name: "مبدا سفارش",
      id: "source",
      width: "8%",
      selector: (row: Order) => row.side.title,
      cell: (row: Order) => (
        <>
          <Tag color={`${row.source.id === 1 ? "cyan" : "purple"}`}>
            {row.source.title}
          </Tag>
        </>
      ),
    },
    {
      name: "پلتفرم",
      id: "platform",
      width: "8%",
      selector: (row: Order) => row.platform.title,
      cell: (row: Order) => (
        <>
          <Tag color={`${row.platform.id === 1 ? "blue" : "green"}`}>
            {row.platform.title}
          </Tag>
        </>
      ),
    },
    {
      name: "تاریخ ایجاد",
      id: "createdAt",
      width: "8%",
      selector: (row: Order) => row.createdAt,
      cell: (row: Order) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdAt)
              .local()
              .toDate()
              .toLocaleDateString("fa-IR")}
          </span>
          <span className="time">
            {moment.utc(row.createdAt).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "وضعیت",
      id: "state",
      width: "9%",
      selector: (row: Order) => row.state.id,
      cell: (row: Order) => (
        <Badge
          text={row.state.id == 3 ? "موفق" : row.state.title}
          type={
            row.state.id == 3
              ? "success"
              : row.state.id == 4 || row.state.id == 5 || row.state.id == 2
              ? "error"
              : "waiting"
          }
          icon={`/assets/icons/table/${
            row.state.id == 3
              ? "success"
              : row.state.id == 4 || row.state.id == 5 || row.state.id == 2
              ? "danger"
              : "waiting"
          }.svg`}
        />
      ),
    },
    {
      name: "جزئیات",
      id: "detail",
      width: "5%",
      cell: (row: Order) => (
        <InfoCircleOutlined onClick={() => handleModal(row)} />
      ),
    },
  ];

  function handleModal(id: Order) {
    setIsOpen(true);
    setSelectedItem(id);
  }

  const updatePageSize = (newPageSize: number) => {
    setOrdersCount((prevState: OrdersCount | undefined) => ({
      ...prevState!,
      pageSize: newPageSize,
    }));
  };

  const filterHandle = () => {
    var q = "";

    if (filterValues.side.id !== 0)
      q += `${q.includes("?") ? "&" : "?"}side=${filterValues.side.id}`;
    if (filterValues.startDate)
      q += `${q.includes("?") ? "&" : "?"}startDate=${convertFaToEnCalendar(
        filterValues.startDate
      )}`;
    if (filterValues.endDate)
      q += `${q.includes("?") ? "&" : "?"}endDate=${convertFaToEnCalendar(
        filterValues.endDate
      )}`;
    if (filterValues.keywords)
      q += `${q.includes("?") ? "&" : "?"}keywords=${filterValues.keywords}`;
    if (filterValues.marketType?.id !== 0)
      q += `${q.includes("?") ? "&" : "?"}marketType=${
        filterValues.marketType?.id
      }`;
    if (filterValues.platform?.id !== 0)
      q += `${q.includes("?") ? "&" : "?"}platformType=${
        filterValues.platform?.id
      }`;
    if (filterValues.source?.id !== -1)
      q += `${q.includes("?") ? "&" : "?"}source=${filterValues.source?.id}`;

    setQuery(q);
    getAllOrders(setLoading, setOrderList, 1, pageSize, q, setOrdersCount);
  };

  const onFilterHandler = () => {
    getAllOrders(setLoading, setOrderList, 1, pageSize, query, setOrdersCount);
  };

  React.useEffect(() => {
    !orderList &&
      getAllOrders(
        setCardLoading,
        setOrderList,
        getPageNum(),
        pageSize,
        query,
        setOrdersCount
      );
    !transactionMarketsType ||
      (transactionMarketsType.length < 1 &&
        getTransactionMarketsTypes(setCardLoading, setTransactionMarketsType));
    !platforms.length && getPlatforms(setCardLoading, setPlatforms);
  }, []);

  return (
    <div className="all-orders">
      <Helmet title={"همه سفارشات | ادمین ایکس پی"} />
      <Card title="سفارشات " blur={cardLoading} className="desktop-show">
        {orderList && (
          <>
            {orderList.items.filter((item) => item === selectedItem)[0] &&
              selectedItem && (
                <TransactionOrderDetailModal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  orderItem={
                    orderList.items.filter((item) => item === selectedItem)[0]
                  }
                />
              )}
          </>
        )}

        <div className="filter-row">
          <div className="fields">
            <Input
              type="text"
              value={filterValues.keywords}
              placeholder="جستجو کنید ..."
              className="search"
              name="search"
              onPressEnter={filterHandle}
              onChange={(e) =>
                setFilterValues({ ...filterValues, keywords: e.target.value })
              }
            />

            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {sides &&
                    sides.map((side) => (
                      <Menu.Item
                        onClick={() =>
                          setFilterValues({ ...filterValues, side })
                        }
                      >
                        {side.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filterValues.side.title}
              />
            </Dropdown>
            {transactionMarketsType && (
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {transactionMarketsType.length &&
                      transactionMarketsType.map((marketType) => (
                        <Menu.Item
                          onClick={() =>
                            setFilterValues({ ...filterValues, marketType })
                          }
                        >
                          {marketType.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues.marketType?.title}
                />
              </Dropdown>
            )}
            {orderSourceType && (
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {orderSourceType.length &&
                      orderSourceType.map((sourceType) => (
                        <Menu.Item
                          onClick={() =>
                            setFilterValues({
                              ...filterValues,
                              source: sourceType,
                            })
                          }
                        >
                          {sourceType.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues.source?.title}
                />
              </Dropdown>
            )}
            {platforms && (
              <Dropdown
                trigger={["click", "hover"]}
                overlay={
                  <Menu>
                    {platforms.length &&
                      platforms.map((platform, index) => (
                        <Menu.Item
                          key={index}
                          onClick={() =>
                            setFilterValues({ ...filterValues, platform })
                          }
                        >
                          {platform.title}
                        </Menu.Item>
                      ))}
                  </Menu>
                }
                placement="bottom"
              >
                <Button
                  icon={<DownOutlined />}
                  type="outlined"
                  text={filterValues.platform?.title}
                />
              </Dropdown>
            )}
            <Calendar
              value={filterValues.startDate}
              placeholder="از تاریخ"
              name="fromDate"
              onChange={(value) =>
                setFilterValues((prev) => ({
                  ...prev,
                  startDate: value,
                }))
              }
            />
            <Calendar
              value={filterValues.endDate}
              placeholder="تا تاریخ"
              name="toDate"
              onChange={(value) =>
                setFilterValues((prev) => ({
                  ...prev,
                  endDate: value,
                }))
              }
            />
          </div>

          <Divider type="vertical" />
          <div className="buttons">
            <Button
              loading={loading}
              disabled={loading}
              onClick={() => filterHandle()}
              text="اعمال فیلتر"
              type="success"
            />
            <span
              onClick={() => {
                setFilterValues({
                  keywords: "",
                  startDate: "",
                  endDate: "",
                  side: sides[0],
                  source: defaultOrderSourceTypeItems[0],
                  marketType: defaultMarketTypeItems[0],
                });
                getAllOrders(
                  setCardLoading,
                  setOrderList,
                  getPageNum(),
                  pageSize,
                  "",
                  setOrdersCount
                );
                getAllOrdersCount(
                  setLoadingPaging,
                  setOrdersCount,
                  getPageNum(),
                  pageSize,
                  ""
                );
              }}
            >
              حذف فیلتر
            </span>
          </div>
        </div>
        {orderList && (
          <Table
            filters={query}
            setLoader={setCardLoading}
            setData={setOrderList}
            getData={getAllOrders}
            pagination={ordersCount}
            data={orderList.items}
            setCount={setOrdersCount}
            column={columns}
            pageSize={ordersCount?.pageSize ?? 10}
            setPageSize={updatePageSize as Dispatch<SetStateAction<number>>}
          />
        )}
      </Card>
    </div>
  );
};
