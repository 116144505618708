import * as React from "react";
import { Fragment } from "react";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button } from "components/button/button";
import "./pagination.scss";
import { useSearchParams } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { digitSeparator } from "../../utils/scripts/decimal";

const { useState } = React;

const pageSizeNumbers = [
  { id: 10, title: "10" },
  { id: 20, title: "20" },
  { id: 30, title: "30" },
  { id: 40, title: "40" },
  { id: 50, title: "50" },
  { id: 100, title: "100" },
  { id: 100, title: "200" },
  { id: 100, title: "500" },
  { id: 100, title: "1000" },
];

type Props = {
  data: any[];
  setdata: any;
  getdata: any;
  setloader: any;
  page: number;
  totalRows: number;
  size: number;
  query?: string;
  setPageSize?: React.Dispatch<React.SetStateAction<number>>;
  setCount?: any;
};

export const Pagination: React.FC<Props> = ({
  totalRows,
  getdata,
  setloader,
  page,
  data,
  setdata,
  size,
  query,
  setPageSize,
  setCount,
}) => {
  const [totalPage, setTotalPage] = useState<number>(0);
  const [minPageLimit, setMinPageLimit] = useState<number>(0);
  const [maxPageLimit, setMaxPageLimit] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const onPrevPage = () => {
    page !== 1 && getdata(setloader, setdata, page - 1, size, query, setCount);
  };
  const onNextPage = () => {
    page !== totalPage &&
      getdata(setloader, setdata, page + 1, size, query, setCount);
  };

  React.useEffect(() => {
    setTotalPage(Math.ceil(totalRows / size));
  }, []);

  React.useEffect(() => {
    totalPage && page > totalPage - 4
      ? setMinPageLimit(totalPage - 4)
      : page < 5
      ? setMinPageLimit(1)
      : setMinPageLimit(page);

    totalPage && page > totalPage - 4
      ? setMaxPageLimit(totalPage)
      : page < 5
      ? setMaxPageLimit(5)
      : setMaxPageLimit(page + 4);

    setSearchParams({ ["page"]: page.toString() });
  }, [page]);

  const onPageSizeChange = (size: number) => {
    setPageSize && setPageSize(size);
    getdata(setloader, setdata, page, size, query, setCount);
  };

  return (
    <div className="vt-pagination">
      <div className="pagination-row">
        <DoubleRightOutlined
          className={`${page == 1 || totalPage <= 1 ? "disabled" : ""}`}
          onClick={() =>
            page != 1 && getdata(setloader, setdata, 1, size, query, setCount)
          }
        />
        <RightOutlined
          onClick={() => onPrevPage()}
          className={`${page == 1 || totalPage <= 1 ? "disabled" : ""}`}
        />
        <div className="pages">
          {minPageLimit > 1 && <Button type="outlined" text="..." />}
          {Array.from(
            { length: Math.ceil(totalRows / size) },
            (item, index) => index + 1
          ).map((pageItem, index) => (
            <Fragment key={index}>
              {pageItem >= minPageLimit && pageItem <= maxPageLimit && (
                <Button
                  onClick={() =>
                    getdata(setloader, setdata, pageItem, size, query, setCount)
                  }
                  text={pageItem.toString()}
                  type={pageItem === page ? "info" : "outlined"}
                />
              )}
            </Fragment>
          ))}
          {totalPage > maxPageLimit && <Button type="outlined" text="..." />}
        </div>
        <LeftOutlined
          className={`${page == totalPage || totalPage <= 1 ? "disabled" : ""}`}
          onClick={() => onNextPage()}
        />
        <DoubleLeftOutlined
          className={`${page == totalPage || totalPage <= 1 ? "disabled" : ""}`}
          onClick={() =>
            page != totalPage &&
            getdata(setloader, setdata, totalPage, size, query, setCount)
          }
        />
        <Dropdown
          trigger={["click", "hover"]}
          overlay={
            <Menu>
              {pageSizeNumbers &&
                pageSizeNumbers.map((pageSize, index) => (
                  <Menu.Item
                    key={index}
                    onClick={() => onPageSizeChange(pageSize.id)}
                  >
                    {pageSize.title}
                  </Menu.Item>
                ))}
            </Menu>
          }
          placement="bottom"
        >
          <Button
            icon={<DownOutlined />}
            type="outlined"
            text={size.toString()}
          />
        </Dropdown>
      </div>
      {totalPage > 1 && (
        <div className="foot">
          نمایش <b>{(page - 1) * size}</b> تا{" "}
          <b>{page == totalPage ? totalRows : page * size}</b> از{" "}
          <b>{digitSeparator(totalRows)}</b> ردیف
        </div>
      )}
    </div>
  );
};
