import {
  ApartmentOutlined,
  BellOutlined,
  CompressOutlined,
  CreditCardFilled,
  CrownFilled,
  DollarCircleFilled,
  GiftOutlined,
  HistoryOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
  StarFilled,
  StopOutlined,
  TagsOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { ReactNode } from "react";

export type MenuItemChild = {
  id: number;
  title: string;
  link: string;
  itemKey?: string;
};

export type MenuItem = {
  id: number;
  title: string;
  link: string;
  badge?: any;
  icon: ReactNode;
  childrens?: MenuItemChild[];
  filled?: boolean;
  itemKey?: string;
};

export const SidebarItems: MenuItem[] = [
  {
    id: 1,
    title: "داشبورد",
    link: "/",
    icon: (
      <svg
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.16168 22.528V18.8587C8.16168 17.9221 8.92655 17.1628 9.87007 17.1628H13.3191C13.7722 17.1628 14.2067 17.3414 14.5271 17.6595C14.8475 17.9776 15.0275 18.4089 15.0275 18.8587V22.528C15.0246 22.9174 15.1784 23.2918 15.4548 23.5682C15.7312 23.8446 16.1072 24 16.4995 24H18.8525C19.9515 24.0028 21.0064 23.5714 21.7845 22.801C22.5626 22.0305 22.9999 20.9844 22.9999 19.8934V9.44023C22.9999 8.55895 22.6064 7.72301 21.9255 7.1576L13.9208 0.811042C12.5283 -0.301726 10.5333 -0.265797 9.18242 0.896374L1.36037 7.1576C0.64724 7.70634 0.221014 8.54476 0.199951 9.44023V19.8827C0.199951 22.1566 2.05681 24 4.34736 24H6.6467C7.46142 24 8.12354 23.3474 8.12945 22.5387L8.16168 22.528Z"
          fill="#859ABC"
        />
      </svg>
    ),
  },
  {
    id: 2,
    title: "کاربران",
    link: "",
    icon: (
      <svg
        width="20"
        height="24"
        viewBox="0 0 20 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.3998 15.8087C15.6062 15.8087 19.9998 16.6547 19.9998 19.9188C19.9998 23.184 15.5773 24 10.3998 24C5.19465 24 0.799805 23.154 0.799805 19.89C0.799805 16.6247 5.22226 15.8087 10.3998 15.8087ZM10.3998 0C13.9267 0 16.7526 2.82483 16.7526 6.34926C16.7526 9.8737 13.9267 12.6997 10.3998 12.6997C6.87408 12.6997 4.04702 9.8737 4.04702 6.34926C4.04702 2.82483 6.87408 0 10.3998 0Z"
          fill="#859ABC"
        />
      </svg>
    ),
    childrens: [
      {
        id: 21,
        title: "همه کاربران",
        link: "/users",
      },
      {
        id: 51,
        title: "لیست موجودی کاربران",
        link: "/wallets/users-balance-list",
      },

      {
        id: 16,
        title: "حساب های بانکی",
        link: "/bank-accounts",
      },
      {
        id: 127,
        title: "کاربران با برداشت غیر فعال",
        link: "/withdrawal-disabled-users",
      },
      {
        id: 24,
        title: "احراز نشده",
        link: "/users/no-auth",
      },
      {
        id: 25,
        title: "سطوح احراز هویت",
        link: "/users/levels",
      },
      {
        id: 121,
        title: "کیف پول کاربران",
        link: "/users-personal-wallets",
      },
      {
        id: 122,
        title: "شناسه واریز کاربران",
        link: "/cash-in-management",
      },
    ],
  },
  {
    id: 3,
    title: "اقدامات",
    link: "/actions",
    itemKey: "actions",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.1222 14.0232C21.4953 13.5548 21.222 12.7825 21.222 12C21.222 11.2217 21.5081 10.4557 22.1465 10.0105L23.7398 8.89952C23.974 8.72727 24.0911 8.38278 23.9154 8.09569L21.3391 3.84689C21.1635 3.55981 20.8707 3.44498 20.5779 3.61722L18.8042 4.41731C18.0914 4.73886 17.275 4.63738 16.5959 4.2497C15.9015 3.8533 15.3792 3.1774 15.3054 2.38124L15.1326 0.516746C15.074 0.229665 14.8398 0 14.547 0H9.51152C9.21876 0 8.926 0.229665 8.926 0.516746L8.75041 2.41068C8.67828 3.18871 8.15014 3.85531 7.46711 4.23481C6.7712 4.62147 5.95126 4.73167 5.22555 4.40432L3.48062 3.61722C3.18786 3.50239 2.83654 3.61722 2.71944 3.84689L0.201681 8.09569C0.0260233 8.38278 0.143128 8.72727 0.377338 8.89952L1.97061 10.0105C2.60903 10.4557 2.89509 11.2217 2.89509 12C2.89509 12.7757 2.60121 13.5376 1.95612 13.9682L0.260233 15.1005C0.0260233 15.2727 -0.0910816 15.6172 0.0845758 15.9043L2.60233 20.1531C2.77799 20.4402 3.07075 20.555 3.36351 20.3828L5.13726 19.5827C5.85009 19.2611 6.66642 19.3626 7.34555 19.7503C8.03995 20.1467 8.56223 20.8226 8.63604 21.6188L8.80889 23.4833C8.86744 23.7703 9.10165 24 9.39442 24H14.4299C14.7227 24 15.0155 23.7703 15.0155 23.4833L15.191 21.5893C15.2632 20.8113 15.7913 20.1447 16.4743 19.7652C17.1702 19.3785 17.9902 19.2683 18.7159 19.5957L20.4608 20.3828C20.7536 20.4976 21.1049 20.3828 21.222 20.1531L23.7398 15.9043C23.9154 15.6172 23.7983 15.2727 23.5641 15.1005L22.1222 14.0232ZM11.9707 17.7416C8.75034 17.7416 6.11548 15.1579 6.11548 12C6.11548 8.8421 8.75034 6.25837 11.9707 6.25837C15.1911 6.25837 17.826 8.8421 17.826 12C17.826 15.1579 15.1911 17.7416 11.9707 17.7416Z"
          fill="#859ABC"
        />
      </svg>
    ),
    childrens: [
      {
        id: 31,
        title: "برداشت ریالی در انتظار",
        link: "/actions/rial-waiting-withdrawal",
        itemKey: "approveIRTTransaction",
      },
      {
        id: 32,
        title: "واریز و برداشت ارزی در انتظار",
        link: "/actions/crypto-waiting-withdrawal",
        itemKey: "approveCryptoTransaction",
      },
      // {
      //   id: 33,
      //   title: "سفارشات در انتظار",
      //   link: "/actions/waiting-orders",
      // },
      {
        id: 34,
        title: "کارت بانکی در انتظار",
        link: "/actions/waiting-bank-accounts",
        itemKey: "approveToAddBankAccount",
      },
      {
        id: 35,
        title: "در انتظار تایید ارتقاء سطح",
        link: "/actions/waiting-level-apply",
        itemKey: "approveToUpgradeLevel",
      },
      // {
      //   id: 36,
      //   title: "کاربران خرید اول",
      //   link: "/actions/first-purchase-users",
      // },
      // {
      //   id: 37,
      //   title: "حساب های بانکی در انتظار",
      //   link: "/actions/waiting-bank-accounts",
      // },
      {
        id: 38,
        title: "تایید برداشت از رفرال به کیف پول",
        link: "/actions/referral-withdrawal-to-wallet-approval",
        itemKey: "approveReferralWidthrawal",
      },
      {
        id: 39,
        title: "واریز های آفلاین در انتظار",
        link: "/actions/waiting-offline-deposit",
        itemKey: "transactionsPendingAdminApproval",
      },
    ],
  },
  /*  {
    id: 4,
    title: "تیکت ها",
    link: "/tickets",
    icon: <MailFilled />,
    itemKey: "tickets",
  },*/

  /*  {
      id: 7,
      title: "اطلاعیه ها",
      link: "/notifications",
      icon: <BellFilled />,
    },
    {
      id: 8,
      title: "بازاریابی",
      link: "/marketing",
      icon: <TeamOutlined />,
    },*/

  // {
  //   id: 9,
  //   title: "گزارشات",
  //   link: "/reports",
  //   icon: (
  //     <svg
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24ZM12 8.4H7.2V10.8H18L12 4.8V8.4ZM6 13.2L12 19.2V15.6H16.8V13.2H6Z"
  //         fill="#859ABC"
  //       />
  //     </svg>
  //   ),
  //   childrens: [
  //     {
  //       id: 26,
  //       title: "شاخص های مارکتینگ",
  //       link: "/reports/marketing",
  //     },
  //     {
  //       id: 27,
  //       title: "گزارش درگاه",
  //       link: "/reports/gateway",
  //     },
  //   ],
  // },

  {
    id: 10,
    title: "سوابق تراکنش ها",
    link: "/transactions",
    icon: <HistoryOutlined />,
    childrens: [
      {
        id: 61,
        title: "همه سفارشات",
        link: "/orders/all",
      },
      {
        id: 1003,
        title: "همه تراکنش ها",
        link: "/transactions/all",
      },
      /*    {
        id: 101,
        title: "واریز و برداشت پرفکت",
        link: "/transactions/perfect-money",
      },*/
      {
        id: 102,
        title: "واریز و برداشت ریالی",
        link: "/transactions/currency",
      },
    ],
  },
  {
    id: 11,
    title: "کد های ایردراپ",
    icon: <TagsOutlined />,
    link: "/promocode",
  },
  {
    id: 13,
    title: "مدیریت ارز ها",
    link: "/market",
    icon: (
      <svg
        width="14"
        height="11"
        viewBox="0 0 14 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.13398 0.499999C6.51888 -0.166667 7.48113 -0.166667 7.86603 0.5L13.0622 9.5C13.4471 10.1667 12.966 11 12.1962 11H1.80385C1.03405 11 0.552922 10.1667 0.937822 9.5L6.13398 0.499999Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    id: 14,
    title: "تغییر نرخ",
    link: "/fiats",
    icon: <ApartmentOutlined />,
  },
  {
    id: 22,
    title: "اطلاعیه ها",
    link: "/notifications",
    icon: <BellOutlined />,
  },

  {
    id: 12,
    title: "مدیران و اپراتور",
    link: "/staff",
    icon: <CrownFilled />,
    childrens: [
      {
        id: 121,
        title: "لیست",
        link: "/staff/list",
      },
      // {
      //   id: 122,
      //   title: "گروه بندی اپراتور",
      //   link: "/staff/operator-grouping",
      // },
      {
        id: 123,
        title: "لیست تراکنش",
        link: "/staff/transaction-list",
      },
      {
        id: 124,
        title: "مدیریت نقش ها",
        link: "/users/roles",
      },
      // {
      //   id: 125,
      //   title: "مدیریت دسترسی ها",
      //   link: "/users/permissions",
      // },
    ],
  },
  {
    id: 117,
    title: "کارتابل تائید ها",
    link: "/manual-validations",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.1222 14.0232C21.4953 13.5548 21.222 12.7825 21.222 12C21.222 11.2217 21.5081 10.4557 22.1465 10.0105L23.7398 8.89952C23.974 8.72727 24.0911 8.38278 23.9154 8.09569L21.3391 3.84689C21.1635 3.55981 20.8707 3.44498 20.5779 3.61722L18.8042 4.41731C18.0914 4.73886 17.275 4.63738 16.5959 4.2497C15.9015 3.8533 15.3792 3.1774 15.3054 2.38124L15.1326 0.516746C15.074 0.229665 14.8398 0 14.547 0H9.51152C9.21876 0 8.926 0.229665 8.926 0.516746L8.75041 2.41068C8.67828 3.18871 8.15014 3.85531 7.46711 4.23481C6.7712 4.62147 5.95126 4.73167 5.22555 4.40432L3.48062 3.61722C3.18786 3.50239 2.83654 3.61722 2.71944 3.84689L0.201681 8.09569C0.0260233 8.38278 0.143128 8.72727 0.377338 8.89952L1.97061 10.0105C2.60903 10.4557 2.89509 11.2217 2.89509 12C2.89509 12.7757 2.60121 13.5376 1.95612 13.9682L0.260233 15.1005C0.0260233 15.2727 -0.0910816 15.6172 0.0845758 15.9043L2.60233 20.1531C2.77799 20.4402 3.07075 20.555 3.36351 20.3828L5.13726 19.5827C5.85009 19.2611 6.66642 19.3626 7.34555 19.7503C8.03995 20.1467 8.56223 20.8226 8.63604 21.6188L8.80889 23.4833C8.86744 23.7703 9.10165 24 9.39442 24H14.4299C14.7227 24 15.0155 23.7703 15.0155 23.4833L15.191 21.5893C15.2632 20.8113 15.7913 20.1447 16.4743 19.7652C17.1702 19.3785 17.9902 19.2683 18.7159 19.5957L20.4608 20.3828C20.7536 20.4976 21.1049 20.3828 21.222 20.1531L23.7398 15.9043C23.9154 15.6172 23.7983 15.2727 23.5641 15.1005L22.1222 14.0232ZM11.9707 17.7416C8.75034 17.7416 6.11548 15.1579 6.11548 12C6.11548 8.8421 8.75034 6.25837 11.9707 6.25837C15.1911 6.25837 17.826 8.8421 17.826 12C17.826 15.1579 15.1911 17.7416 11.9707 17.7416Z"
          fill="#859ABC"
        />
      </svg>
    ),
  },
  {
    id: 118,
    title: "استعلام کارت",
    link: "/bank-accounts/tracking",
    icon: <CreditCardFilled />,
  },
  {
    id: 120,
    title: "بلک لیست",
    link: "/blacklist",
    icon: <StopOutlined />,
  },
  {
    id: 127,
    title: "امتیازات",
    link: "/authentication-scores",
    icon: <StarFilled />,
    childrens: [
      {
        id: 125,
        title: "مدیریت امتیازات",
        link: "/authentication-scores",
      },
      {
        id: 126,
        title: "تاریخچه ی امتیازات",
        link: "/authentication-scores-history",
      },
    ],
  },
  {
    id: 119,
    title: "استعلام ووچر",
    link: "/pm-tracking",
    icon: <CompressOutlined />,
  },
  {
    id: 122,
    title: "شارژ ووچر",
    link: "/pm-charge",
    icon: <PlusCircleOutlined />,
  },
  {
    id: 123,
    title: "تاریخچه کوکوین",
    link: "/kucoin/history",
    icon: <HistoryOutlined />,
  },
  {
    id: 20,
    title: "اسلایدر",
    link: "/sliders",
    icon: <PlayCircleOutlined />,
    childrens: [
      {
        id: 201,
        title: "اسلایدر ها",
        link: "/sliders",
      },
      {
        id: 202,
        title: "اسلاید ها",
        link: "/slides",
      },
    ],
  },

  {
    id: 19,
    title: "پاپ آپ ها",
    link: "/popups",
    icon: <UpOutlined />,
  },
  {
    id: 23,
    title: "بونوس ها",
    link: "/bonuses",
    icon: <GiftOutlined />,
  },

  {
    id: 18,
    title: "رفرال",
    link: "",
    icon: <DollarCircleFilled />,
    childrens: [
      {
        id: 181,
        title: "برداشت ها",
        link: "/referral/withdrawals",
      },
      {
        id: 182,
        title: "فعالیت ها",
        link: "/referral/actions",
      },
      {
        id: 183,
        title: "همکاران",
        link: "/referral/co-workers",
      },
      {
        id: 184,
        title: "سطوح",
        link: "/referral/levels",
      },
    ],
  },
  {
    id: 17,
    title: "تنظیمات",
    link: "",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.1222 14.0232C21.4953 13.5548 21.222 12.7825 21.222 12C21.222 11.2217 21.5081 10.4557 22.1465 10.0105L23.7398 8.89952C23.974 8.72727 24.0911 8.38278 23.9154 8.09569L21.3391 3.84689C21.1635 3.55981 20.8707 3.44498 20.5779 3.61722L18.8042 4.41731C18.0914 4.73886 17.275 4.63738 16.5959 4.2497C15.9015 3.8533 15.3792 3.1774 15.3054 2.38124L15.1326 0.516746C15.074 0.229665 14.8398 0 14.547 0H9.51152C9.21876 0 8.926 0.229665 8.926 0.516746L8.75041 2.41068C8.67828 3.18871 8.15014 3.85531 7.46711 4.23481C6.7712 4.62147 5.95126 4.73167 5.22555 4.40432L3.48062 3.61722C3.18786 3.50239 2.83654 3.61722 2.71944 3.84689L0.201681 8.09569C0.0260233 8.38278 0.143128 8.72727 0.377338 8.89952L1.97061 10.0105C2.60903 10.4557 2.89509 11.2217 2.89509 12C2.89509 12.7757 2.60121 13.5376 1.95612 13.9682L0.260233 15.1005C0.0260233 15.2727 -0.0910816 15.6172 0.0845758 15.9043L2.60233 20.1531C2.77799 20.4402 3.07075 20.555 3.36351 20.3828L5.13726 19.5827C5.85009 19.2611 6.66642 19.3626 7.34555 19.7503C8.03995 20.1467 8.56223 20.8226 8.63604 21.6188L8.80889 23.4833C8.86744 23.7703 9.10165 24 9.39442 24H14.4299C14.7227 24 15.0155 23.7703 15.0155 23.4833L15.191 21.5893C15.2632 20.8113 15.7913 20.1447 16.4743 19.7652C17.1702 19.3785 17.9902 19.2683 18.7159 19.5957L20.4608 20.3828C20.7536 20.4976 21.1049 20.3828 21.222 20.1531L23.7398 15.9043C23.9154 15.6172 23.7983 15.2727 23.5641 15.1005L22.1222 14.0232ZM11.9707 17.7416C8.75034 17.7416 6.11548 15.1579 6.11548 12C6.11548 8.8421 8.75034 6.25837 11.9707 6.25837C15.1911 6.25837 17.826 8.8421 17.826 12C17.826 15.1579 15.1911 17.7416 11.9707 17.7416Z"
          fill="#859ABC"
        />
      </svg>
    ),
    childrens: [
      {
        id: 171,
        title: "تنظیمات سایت",
        link: "/setting",
      },
      {
        id: 172,
        title: "تنظیمات درگاه‌ها",
        link: "/gateway-setting",
      },
      {
        id: 173,
        title: "تنظیمات سرویسهای ارزی",
        link: "/crypto-setting",
      },
      {
        id: 174,
        title: "تنظیمات امنیتی",
        link: "/security-setting",
      },
      {
        id: 175,
        title: "تنظیمات واریز ریالی",
        link: "/cash-in-setting",
      },
    ],
  },
];

export const MobileMenuItems: MenuItem[] = [];
