import "./index.scss";
import { Button, Card, Input, Modal } from "../../../components";
import * as React from "react";
import { useEffect, useState } from "react";
import { FilterValues, PaginationList } from "../../../core/domains";
import { Promocode } from "../../../core/domains/promocode/promocode";
import { Dropdown, Menu, Tooltip } from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "jalali-moment";
import { digitSeparator } from "../../../utils/scripts/decimal";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "../../../components/table/table";
import {
  getAllPromocodes,
  removePromoCode,
} from "../../../core/repos/promocode";
import { message } from "../../../components/toast/toast";

const activeOptions = [
  { title: "همه", id: -1 },
  { title: "فعال", id: 1 },
  { title: "غیرفعال", id: 0 },
];

export const AllPromocodes = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    keywords: "",
    isActive: -1, //hame
  });
  const [promocodes, setPromocodes] = useState<PaginationList<Promocode>>();
  const [selectedPromocode, setSelectedPromocode] = useState<Promocode>();
  const [query, setQuery] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
  const [deleteModalIsLoading, setDeleteModalIsLoading] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const columns: any[] = [
    {
      name: "کد",
      id: "code",
      selector: (row: Promocode) => row.code,
      width: "20%",
    },
    {
      name: "تاریخ انقضا",
      id: "expiredAt",
      selector: (row: Promocode) => row.expiresAt,
      cell: (row: Promocode) => (
        <span className="day">
          {moment(row.expiresAt).locale("fa").local().format("jYYYY/MM/DD")}
        </span>
      ),
      width: "20%",
    },
    {
      name: "نماد",
      id: "symbol",
      selector: (row: Promocode) => row.symbol.toUpperCase(),
      width: "15%",
    },
    {
      name: "مقدار",
      id: "amount",
      selector: (row: Promocode) => row.amount,
      cell: (row: Promocode) => digitSeparator(row.amount),
      width: "15%",
    },
    {
      name: "وضعیت",
      id: "isActive",
      selector: (row: Promocode) => row.isActive,
      cell: (row: Promocode) => (row.isActive ? "فعال" : "غیرفعال"),
      width: "10%",
    },
    {
      name: "تاریخ ایجاد",
      id: "createdOn",
      selector: (row: Promocode) => row.createdOn,
      cell: (row: Promocode) => (
        <div className="column-date">
          <span className="day">
            {moment(row.createdOn).locale("fa").local().format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment(row.createdOn).local().format("HH:mm")}
          </span>
        </div>
      ),
      width: "10%",
    },
    {
      name: "جزئیات",
      id: "detail",
      cell: (row: Promocode) => (
        <div className="actions">
          <Tooltip placement="top" title={"ویرایش کد جایزه"}>
            <Link to={`/promocode/edit/${row.id}`}>
              <EditOutlined />
            </Link>
          </Tooltip>
          <Tooltip placement="top" title={"حذف کد جایزه"}>
            <DeleteOutlined onClick={() => onDeleteIconClick(row)} />
          </Tooltip>
        </div>
      ),
      width: "10%",
    },
  ];
  const filterHandle = () => {
    let _query = "";

    if (filterValues.keywords) {
      _query += `${_query.includes("?") ? "&" : "?"}keyword=${
        filterValues.keywords
      }`;
    }

    if (filterValues.isActive !== -1) {
      _query += `${_query.includes("?") ? "&" : "?"}isActive=${
        filterValues.isActive === 1 ? "true" : "false"
      }`;
    }

    setQuery(_query);
    getAllPromocodes(setLoading, setPromocodes, 1, pageSize, _query);
  };

  const onDeleteIconClick = (item: Promocode) => {
    setSelectedPromocode(item);
    setDeleteModalIsOpen(true);
  };

  useEffect(() => {
    filterHandle();
  }, []);

  return (
    <Card
      title="کد های ایردراپ"
      className="vt-dashboard-promocode"
      blur={loading}
    >
      {selectedPromocode && (
        <Modal
          title={"آیا از حذف کد جایزه اطمینان دارید؟"}
          isOpen={deleteModalIsOpen}
          setIsOpen={setDeleteModalIsOpen}
          className="delete-promocode-modal"
        >
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              htmlType="button"
              loading={deleteModalIsLoading}
              disabled={deleteModalIsLoading}
              onClick={() =>
                removePromoCode(
                  setDeleteModalIsLoading,
                  selectedPromocode?.id,
                  () => {
                    message.success("کد جایزه با موفقیت حذف شد");
                    setDeleteModalIsOpen(false);
                    getAllPromocodes(
                      setLoading,
                      setPromocodes,
                      1,
                      pageSize,
                      query
                    );
                  }
                )
              }
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => setDeleteModalIsOpen(false)}
            />
          </div>
        </Modal>
      )}
      <div className="filter-row">
        <div className="fields">
          <Input
            type="text"
            value={filterValues?.keywords}
            placeholder="جستجو کنید ..."
            name="search"
            className="search"
            onPressEnter={filterHandle}
            onChange={(e) =>
              setFilterValues({ ...filterValues, keywords: e.target.value })
            }
          />

          <Dropdown
            trigger={["click", "hover"]}
            overlay={
              <Menu>
                {activeOptions?.map((option) => (
                  <Menu.Item
                    key={option.id}
                    onClick={() =>
                      setFilterValues((prev) => ({
                        ...prev,
                        isActive: option.id,
                      }))
                    }
                  >
                    {option.title}
                  </Menu.Item>
                ))}
              </Menu>
            }
            placement="bottom"
          >
            <Button
              icon={<DownOutlined />}
              type="outlined"
              text={
                activeOptions?.find(
                  (option) => option.id === filterValues?.isActive
                )?.title || "همه"
              }
            />
          </Dropdown>
        </div>
        <div className="buttons">
          <Button
            loading={loading}
            disabled={loading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => filterHandle()}
          />
          <span
            onClick={() => {
              setFilterValues({
                keywords: "",
                isActive: undefined,
              });
              getAllPromocodes(setLoading, setPromocodes, 1, pageSize, "");
            }}
          >
            حذف فیلتر
          </span>
        </div>
        &nbsp;&nbsp;&nbsp;
        <Button
          type="info"
          icon={<PlusOutlined />}
          text="افزودن کد جایزه"
          onClick={() => navigate("/promocode/add")}
          htmlType="button"
        />
      </div>
      {promocodes && (
        <Table
          className="promocodes-table"
          data={promocodes.items}
          column={columns}
          setData={setPromocodes}
          getData={getAllPromocodes}
          setLoader={setLoading}
          pagination={promocodes.pagination}
          filters={query}
        />
      )}
    </Card>
  );
};
