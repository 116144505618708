import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import { SettingGoogleAuthType } from "core/domains";
import * as React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import { SettingGoogleAuth } from "../google-auth/google-auth";
import "./index.scss";

interface Bep20Setting {
  secretKey?: string;
  hotWalletAddress?: string;
  apiKey?: string;
  commissionWalletAddress?: string;
}

export const Bep20Settings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [bep20Settings, setBep20Settings] = React.useState<Bep20Setting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();

  const getBep20Settings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.bep20Setting).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setBep20Settings(response.value);
      }
    });
  };

  const onConfirmHandler = (googleAuthCode: string) => {
    setLoading(true);
    put(API.settings.changeBep20Setting, {
      ...bep20Settings,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getBep20Settings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getBep20Settings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card blur={cardLoading} className="vt-dashboard-tron24-settings">
        <Card title={`کانفیگ bep20`} className="gateway-item">
          <Input
            label="کلید وب سرویس"
            type="text"
            name="apiKey"
            value={bep20Settings?.apiKey}
            onChange={(e) =>
              setBep20Settings({ ...bep20Settings, apiKey: e.target.value })
            }
            dir="ltr"
          />
          <Input
            label="سکرت"
            type="text"
            name="secretKey"
            value={bep20Settings?.secretKey}
            onChange={(e) =>
              setBep20Settings({
                ...bep20Settings,
                secretKey: e.target.value,
              })
            }
            dir="ltr"
          />
          <Input
            label="آدرس کیف پول گرم"
            type="text"
            name="hotWalletAddress"
            value={bep20Settings?.hotWalletAddress}
            onChange={(e) =>
              setBep20Settings({
                ...bep20Settings,
                hotWalletAddress: e.target.value,
              })
            }
            dir="ltr"
          />
          <Input
            label="آدرس کیف پول کارمزد"
            type="text"
            name="commissionWalletAddress"
            value={bep20Settings?.commissionWalletAddress}
            onChange={(e) =>
              setBep20Settings({
                ...bep20Settings,
                commissionWalletAddress: e.target.value,
              })
            }
            dir="ltr"
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => setModalIsOpen(true)}
            loading={loading}
            disabled={
              loading ||
              bep20Settings?.apiKey === "" ||
              bep20Settings?.secretKey === ""
            }
          />
        </Card>
      </Card>
    </>
  );
};
