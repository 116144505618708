import "./index.scss";
import React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import { API } from "apis";
import {
  deleteCommaSeparator,
  digitSeparator,
  faNumToEnNum,
} from "utils/scripts/decimal";
import { message, Switch } from "antd";
import { Button, Card, Input } from "components";

interface TransferTronSetting {
  transferTRXFromKucoinToHotState: boolean;
  transferTRXFromHotToKucoinState: boolean;
  graterThanForTransferValueFromKucoinToHot: number;
  minKucoinRequirementValueFromKucoinToHot: number;
  minimumAmountOfTetherKucoin: number;
  amountOfRechargeForKucoin: number;
  minimumAmountOfTetherBingX: number;
  amountOfRechargeForBingX: number;
}

export const TransferTronSettings: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [transferTronSettings, setTransferTronSettings] = React.useState<
    TransferTronSetting | undefined
  >(undefined);

  const getTransferTronSettings = async () => {
    setCardLoading(true);
    const response: any = await getViaAuth(
      API.settings.getTransferTronSettings
    );
    setCardLoading(false);
    if (responseValidator(response.status)) {
      setTransferTronSettings(response.value);
    }
  };

  const onUpdateTransferTronHandler = async () => {
    setLoading(true);
    if (!transferTronSettings) {
      setLoading(false);
      return;
    }

    const payload = {
      transferTRXFromKucoinToHotState:
        transferTronSettings.transferTRXFromKucoinToHotState,
      transferTRXFromHotToKucoinState:
        transferTronSettings.transferTRXFromHotToKucoinState,
      graterThanForTransferValueFromKucoinToHot: parseFloat(
        deleteCommaSeparator(
          transferTronSettings.graterThanForTransferValueFromKucoinToHot.toString()
        ) || "0"
      ),
      minKucoinRequirementValueFromKucoinToHot: parseFloat(
        deleteCommaSeparator(
          transferTronSettings.minKucoinRequirementValueFromKucoinToHot.toString()
        ) || "0"
      ),
      minimumAmountOfTetherKucoin: parseFloat(
        deleteCommaSeparator(
          transferTronSettings.minimumAmountOfTetherKucoin.toString()
        ) || "0"
      ),
      amountOfRechargeForKucoin: parseFloat(
        deleteCommaSeparator(
          transferTronSettings.amountOfRechargeForKucoin.toString()
        ) || "0"
      ),
      minimumAmountOfTetherBingX: parseFloat(
        deleteCommaSeparator(
          transferTronSettings.minimumAmountOfTetherBingX.toString()
        ) || "0"
      ),
      amountOfRechargeForBingX: parseFloat(
        deleteCommaSeparator(
          transferTronSettings.amountOfRechargeForBingX.toString()
        ) || "0"
      ),
    };

    const response: any = await put(
      API.settings.changeTransferTronSetting,
      payload
    );
    setLoading(false);
    if (responseValidator(response.status)) {
      message.success(response.data.message);
      getTransferTronSettings();
    } else {
      message.error(response.data.message);
    }
  };

  React.useEffect(() => {
    getTransferTronSettings();
  }, []);

  const handleInputChange = (
    field: keyof TransferTronSetting,
    value: string
  ) => {
    setTransferTronSettings((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        [field]: parseFloat(deleteCommaSeparator(faNumToEnNum(value))) || 0,
      };
    });
  };

  return (
    <>
      <Card
        title="تنظیمات انتقال"
        blur={cardLoading}
        className="vt-dashboard-transfer-tron-settings"
      >
        <Card className="delay-item">
          <Input
            label="انتقال مقدار بالا"
            type="text"
            name="transfer-tron-greater"
            value={digitSeparator(
              transferTronSettings?.graterThanForTransferValueFromKucoinToHot.toString()
            )}
            onChange={(e) =>
              handleInputChange(
                "graterThanForTransferValueFromKucoinToHot",
                e.target.value
              )
            }
            dir="ltr"
            maxLength={32}
          />
          <Input
            label="میزان مورد نیاز مانده ترون در کوکوین"
            type="text"
            name="transfer-tron-min"
            value={digitSeparator(
              transferTronSettings?.minKucoinRequirementValueFromKucoinToHot.toString()
            )}
            onChange={(e) =>
              handleInputChange(
                "minKucoinRequirementValueFromKucoinToHot",
                e.target.value
              )
            }
            dir="ltr"
            maxLength={32}
          />
          <Input
            label="حداقل مقدار تبادل تتر در کوکوین"
            type="text"
            name="minimum-amount-tether-kucoin"
            value={digitSeparator(
              transferTronSettings?.minimumAmountOfTetherKucoin.toString()
            )}
            onChange={(e) =>
              handleInputChange("minimumAmountOfTetherKucoin", e.target.value)
            }
            dir="ltr"
            maxLength={32}
          />
          <Input
            label="مقدار شارژ برای تبادل تتر در کوکوین"
            type="text"
            name="amount-recharge-kucoin"
            value={digitSeparator(
              transferTronSettings?.amountOfRechargeForKucoin.toString()
            )}
            onChange={(e) =>
              handleInputChange("amountOfRechargeForKucoin", e.target.value)
            }
            dir="ltr"
            maxLength={32}
          />
          <Input
            label="حداقل مقدار تبادل تتر در بینگ ایکس"
            type="text"
            name="minimum-amount-tether-bingx"
            value={digitSeparator(
              transferTronSettings?.minimumAmountOfTetherBingX.toString()
            )}
            onChange={(e) =>
              handleInputChange("minimumAmountOfTetherBingX", e.target.value)
            }
            dir="ltr"
            maxLength={32}
          />
          <Input
            label="مقدار شارژ برای تبادل‌ها در بینگ ایکس"
            type="text"
            name="amount-recharge-bingx"
            value={digitSeparator(
              transferTronSettings?.amountOfRechargeForBingX.toString()
            )}
            onChange={(e) =>
              handleInputChange("amountOfRechargeForBingX", e.target.value)
            }
            dir="ltr"
            maxLength={32}
          />
        </Card>
        <Card className="automation-item">
          <span>وضعیت انتقال ترون از صرافی به هات ولت:</span>
          <Switch
            checkedChildren={"انتقال از کوکوین به هات ولت روشن می باشد"}
            unCheckedChildren={"انتقال از کوکوین به هات ولت خاموش می باشد"}
            checked={transferTronSettings?.transferTRXFromKucoinToHotState}
            onChange={(checked) =>
              setTransferTronSettings((prev) => {
                if (!prev) return prev;
                return {
                  ...prev,
                  transferTRXFromKucoinToHotState: checked,
                };
              })
            }
          />
        </Card>
        <Card className="automation-item">
          <span>وضعیت انتقال ترون از هات ولت به صرافی:</span>
          <Switch
            checkedChildren={"انتقال از هات ولت به کوکوین روشن می باشد"}
            unCheckedChildren={"انتقال از هات ولت به کوکوین خاموش می باشد"}
            checked={transferTronSettings?.transferTRXFromHotToKucoinState}
            onChange={(checked) =>
              setTransferTronSettings((prev) => {
                if (!prev) return prev;
                return {
                  ...prev,
                  transferTRXFromHotToKucoinState: checked,
                };
              })
            }
          />
        </Card>
        <Button
          htmlType="button"
          type="info"
          onClick={onUpdateTransferTronHandler}
          loading={loading}
          disabled={loading}
          text={"ثبت تغییرات"}
        />
      </Card>
    </>
  );
};
