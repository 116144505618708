import * as React from "react";
import { Button, Modal } from "components";
import { Enum } from "core/domains";
import { Progress } from "antd";
import {
  Market,
  MarketAction,
  MarketActionLabel,
} from "core/domains/markets/market";
import { message } from "components/toast/toast";
import { editSingleMarketsState } from "core/repos";
import "./bulk-select-modal.scss";

interface Props {
  selectedRows: Market[];
  selectedActionItem: Enum;
  setButtonLoading: any;
  buttonLoading: boolean;
  setSelectedRows: any;
  isOpen: boolean;
  setIsOpen: any;
  fetchData: () => void;
  actionItem: Enum;
}

export const MarketConfirmBulkSelectModal: React.FC<Props> = ({
  selectedRows,
  selectedActionItem,
  buttonLoading,
  setButtonLoading,
  setSelectedRows,
  setIsOpen,
  isOpen,
  fetchData,
  actionItem,
}) => {
  const [completedMarketsCount, setCompletedMarketsCount] =
    React.useState<number>(0);

  React.useEffect(() => {
    completedMarketsCount === selectedRows?.length && onCompleteMarket();
  }, [completedMarketsCount]);

  async function onConfirmActionHandle() {
    if (selectedRows) {
      for (const e of selectedRows) {
        setButtonLoading(true);
        {
          await editSingleMarketsState(
            setButtonLoading,
            actionItem.id === MarketAction.ActivateDeposit ||
              actionItem.id === MarketAction.DeActivateDeposit
              ? {
                  ...e,
                  depositEnabled:
                    actionItem.id === MarketAction.ActivateDeposit
                      ? true
                      : actionItem.id === MarketAction.DeActivateDeposit
                      ? false
                      : e.depositEnabled,
                }
              : actionItem.id === MarketAction.ActivateWithdrawal ||
                actionItem.id === MarketAction.DeActivateWithdrawal
              ? {
                  ...e,
                  widthrawEnabled:
                    actionItem.id === MarketAction.ActivateWithdrawal
                      ? true
                      : actionItem.id === MarketAction.DeActivateWithdrawal
                      ? false
                      : e.widthrawEnabled,
                }
              : actionItem.id === MarketAction.ActivateBuy ||
                actionItem.id === MarketAction.DeActivateBuy
              ? {
                  ...e,
                  isBuyActive:
                    actionItem.id === MarketAction.ActivateBuy
                      ? true
                      : actionItem.id === MarketAction.DeActivateBuy
                      ? false
                      : e.isBuyActive,
                }
              : actionItem.id === MarketAction.ActivateSell ||
                actionItem.id === MarketAction.DeActivateSell
              ? {
                  ...e,
                  isSellActive:
                    actionItem.id === MarketAction.ActivateSell
                      ? true
                      : actionItem.id === MarketAction.DeActivateSell
                      ? false
                      : e.isSellActive,
                }
              : { ...e },
            () => setCompletedMarketsCount((prev) => prev + 1),
            closeActionModal
          );
        }
      }
    }
  }

  const closeActionModal = () => {
    setSelectedRows(undefined);
    setCompletedMarketsCount(0);
    setButtonLoading(false);
    setIsOpen(false);
  };

  const onCompleteMarket = async () => {
    setButtonLoading(false);
    message.success("همه تراکنش ها با موفقیت به روز شدند");
    fetchData();
    selectedRows && setCompletedMarketsCount(selectedRows?.length);
    setCompletedMarketsCount(0);
    setSelectedRows(undefined);
    setIsOpen(false);
  };

  return (
    <Modal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      title={`از ${MarketActionLabel.get(selectedActionItem.id)} ${
        selectedRows.length
      }ارزهای انتخاب شده مطمئن هستید؟`}
      className="market-action-approve-modal"
      onClose={closeActionModal}
      disabledClose={buttonLoading}
    >
      <div className="modal-content">
        <Progress
          type="line"
          percent={Math.round(
            (completedMarketsCount * 100) / selectedRows.length
          )}
        />
        <span>
          {completedMarketsCount} از {selectedRows.length} ارز انجام شده
        </span>

        <div className="buttons">
          <Button
            type="info"
            text="بله مطمئنم"
            loading={buttonLoading}
            disabled={buttonLoading}
            htmlType="button"
            onClick={onConfirmActionHandle}
          />
          <Button
            type="outlined"
            text="بازگشت"
            onClick={closeActionModal}
            disabled={buttonLoading}
          />
        </div>
      </div>
    </Modal>
  );
};
