import { FC, useEffect, useState } from "react";
import moment from "jalali-moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import { DateValue } from "core/domains/dashboard/dashboard";
import { Card } from "components/card/card";

Exporting(Highcharts);
ExportData(Highcharts);

interface Props {
  allCount?: DateValue[];
}

interface FormattedData {
  dateTime: string;
  all: number;
}

export const AllCount: FC<Props> = ({ allCount }) => {
  const [options, setOptions] = useState<Highcharts.Options>({});

  useEffect(() => {
    const formatData = (all: DateValue[]): FormattedData[] => {
      const formattedData: { [key: string]: FormattedData } = {};

      all?.forEach((item) => {
        const date =
          item?.dateTime === "0001-01-01T00:00:00+00:00"
            ? ""
            : moment(item?.dateTime).locale("fa").local().format("jYYYY/MM/DD");
        if (!formattedData[date]) {
          formattedData[date] = {
            dateTime: date,
            all: 0,
          };
        }
        formattedData[date].all = item.value;
      });

      return Object.values(formattedData);
    };

    if (allCount) {
      const combinedData = formatData(allCount);
      const dates = combinedData.map((item) => item.dateTime);

      const all = combinedData.map((item) => item.all);

      setOptions({
        chart: {
          className: "xpay-chart",
          style: {
            fontFamily: "anjoman",
            fontSize: "16px",
          },
        },

        lang: {
          thousandsSep: ",",
          downloadPNG: "PNG دانلود",
          downloadJPEG: "JPEG دانلود",
          downloadPDF: "PDF دانلود",
          downloadSVG: "SVG دانلود",
          downloadCSV: "CSV دانلود",
          downloadXLS: "XLS دانلود",
        },
        tooltip: {
          style: {
            fontSize: "16px",
          },
          formatter: function () {
            return (
              "<b>" +
              this.x +
              "</b><br>" +
              '<span style="color:' +
              this.series.color +
              '"><span>' +
              this.series.name +
              "</span>: " +
              "<b>" +
              Highcharts.numberFormat(this.y as number, 0, ".", ",") +
              "</b>" +
              "</span>"
            );
          },
        },
        title: {
          text: undefined,
        },
        xAxis: {
          categories: dates,
        },
        yAxis: {
          title: {
            text: "تعداد",
          },
          labels: {
            formatter: function () {
              const value = this.value as number;
              if (value >= 1_000_000) {
                return value / 1_000_000 + " میلیون";
              } else if (value >= 1_000) {
                return value / 1_000 + " هزار";
              } else {
                return value.toString();
              }
            },
            style: {
              fontFamily: "YekanBakh",
              direction: "rtl",
            },
          },
        },
        series: [
          {
            name: "تعداد کل سفارشات و تراکنش ها",
            data: all,
            type: "spline",
            color: "#4CAF50",
          },
        ],
        exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              menuItems: [
                "downloadPNG",
                "downloadJPEG",
                "downloadPDF",
                "downloadSVG",
                "downloadCSV",
                "downloadXLS",
              ],
            },
          },
        },
        credits: {
          enabled: false,
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                chart: {
                  style: {
                    width: 50,
                    height: 50,
                  },
                },
              },
            },
          ],
        },
      });
    }
  }, [allCount]);

  return (
    <Card title="تعداد کل سفارشات و تراکنش ها">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Card>
  );
};
