import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import {
  CheckOutlined,
  EditOutlined,
  InfoCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Divider, Tooltip } from "antd";
import { Button, Calendar, Card, Input, Modal } from "components";
import { Badge } from "components/badge/badge";
import { Table } from "components/table/table";
import moment from "jalali-moment";
import "./index.scss";
import {
  Enum,
  Market,
  PaginationList,
  SortDirection,
  TransactionsCount,
} from "core/domains";
import {
  Transaction,
  TransactionState,
  TransactionStateLabel,
} from "core/domains/transaction/transaction";
import { getAllMarkets } from "core/repos/market";
import {
  editTransactionState,
  getAllTransactions,
  payoutTranscation,
} from "core/repos";
import { CryptoTransactionModal } from "routes/dashboard/transactions/modals/crypto";
import { getIconPath } from "../../../../utils/scripts/getIconPath";
import { getPageNum } from "../../../../utils/scripts/paggingHelper";
import { convertFaToEnCalendar } from "utils/scripts/calendarExtentions";
import TextArea from "antd/lib/input/TextArea";
import { digitSeparator } from "../../../../utils/scripts/decimal";

type Props = {
  markets?: Market[];
};

export interface FilterValues {
  startDate?: string;
  endDate?: string;
  sort?: SortDirection;
  market?: string;
  keywords?: string;
  state: Enum;
}

const defaultStateItems: Enum[] = [
  {
    title: "وضعیت تراکنش",
    id: 0,
  },
];

const { useState } = React;

export const OfflineDepositAction: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [transactions, setTransactions] =
    useState<PaginationList<Transaction>>();
  const [transactionsCount, setTransactionsCount] =
    useState<TransactionsCount>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [cardLoading, setCardLoading] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    state: defaultStateItems[0],
  });
  const [descriptionValue, setDescriptionValue] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<Transaction>();
  const [markets, setMarkets] = useState<Market[]>();
  const [payoutModalIsOpen, setPayoutModalIsOpen] = useState<boolean>(false);
  const [approveModalIsOpen, setApproveModalIsOpen] = useState<boolean>(false);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [payoutLoading, setPayoutLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [modalTargetState, setModalTargetState] = useState<TransactionState>(
    TransactionState.WaitingForAdmin
  );
  const [transactionStates, setTransactionStates] = useState<Enum[]>([]);

  const updatePageSize = (newPageSize: number) => {
    setTransactionsCount((prevState: TransactionsCount | undefined) => ({
      ...prevState!,
      pageSize: newPageSize,
    }));
  };

  const closePayoutModal = () => setPayoutModalIsOpen(false);

  const openPayoutModal = (item: Transaction) => {
    setSelectedItem(item);
    setPayoutModalIsOpen(true);
  };
  const openConfirmModal = (item: Transaction, state: TransactionState) => {
    setSelectedItem(item);
    setModalTargetState(state);
    setApproveModalIsOpen(true);
  };

  const closeAppproveModal = () => {
    setDescriptionValue("");
    setApproveModalIsOpen(false);
  };

  const [transactionTypes, setTransactionTypes] = useState<Enum[]>([
    {
      id: 0,
      title: "همه",
    },
  ]);

  React.useEffect(() => {
    !markets && getAllMarkets(setCardLoading, setMarkets, getPageNum(), 10, "");
    getAllTransactions(
      setCardLoading,
      setTransactions,
      getPageNum(),
      pageSize,
      query,
      setTransactionsCount
    );
  }, []);

  async function onApproveHandler() {
    selectedItem &&
      (await editTransactionState(
        setApproveLoading,
        selectedItem,
        closeAppproveModal,
        modalTargetState,
        (modalTargetState === TransactionState.Succeed ||
          modalTargetState === TransactionState.Cancel) &&
          descriptionValue !== ""
          ? descriptionValue
          : undefined
      ));

    getAllTransactions(
      setCardLoading,
      setTransactions,
      getPageNum(),
      pageSize,
      query,
      setTransactionsCount
    );
  }

  const columns: any[] = [
    {
      name: "#",
      id: "index",
      selector: (row: Transaction, rowIndex: number) => rowIndex + 1,
      width: "50px",
    },
    {
      name: "شناسه",
      id: "trackingCode",
      selector: (row: Transaction) => row.trackingCode,
      cell: (row: Transaction) => (
        <span className="en">{row.trackingCode}</span>
      ),
    },
    {
      name: "نام ارز",
      id: "market",
      selector: (row: Transaction) => row.market.name,
      cell: (row: Transaction) => (
        <>
          <img src={getIconPath(row.market.symbol)} />
          <span className="en">{row.market.symbol}</span>
        </>
      ),
    },
    {
      name: "توسط",
      id: "createdBy",
      selector: (row: Transaction) => row.createdBy,
      cell: (row: Transaction) => (
        <div className="column-createdBy">
          <span className="day">{row.createdBy}</span>
          <p className="">{row.phoneNumber}</p>
        </div>
      ),
    },
    {
      name: "نوع تراکنش",
      id: "type",
      selector: (row: Transaction) => row.type.title,
    },
    {
      name: "مقدار",
      id: "amount",
      selector: (row: Transaction) => row.amount,
      cell: (row: Transaction) => <>{digitSeparator(row.amount)}</>,
    },
    {
      name: "زمان",
      id: "createdAt",
      selector: (row: Transaction) => row.createdAt,

      cell: (row: Transaction) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdAt)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdAt).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "وضعیت",
      id: "state",
      selector: (row: Transaction) => row.state.id,
      cell: (row: Transaction) => (
        <Badge
          text={row.state.title}
          type={
            row.state.id == 2
              ? "success"
              : row.state.id == 6
              ? "waiting"
              : "error"
          }
          icon={`/assets/icons/table/${
            row.state.id == 2
              ? "success"
              : row.state.id == 6
              ? "waiting"
              : "danger"
          }.svg`}
        />
      ),
      width: "200px",
    },
    {
      name: "جزئیات",
      id: "detail",
      cell: (row: Transaction) => (
        <div className="actions">
          {row.state.id !== 2 && (
            <Tooltip placement="top" title={"تایید تراکنش"}>
              <CheckOutlined
                onClick={() => openConfirmModal(row, TransactionState.Succeed)}
              />
            </Tooltip>
          )}

          {row.state.id !== 2 && (
            <Tooltip placement="top" title={"لغو تراکنش"}>
              <StopOutlined
                onClick={() => openConfirmModal(row, TransactionState.Rejected)}
              />
            </Tooltip>
          )}
          <Tooltip placement="top" title={"جزئیات تراکنش"}>
            <InfoCircleOutlined onClick={() => handleModal(row)} />
          </Tooltip>
          <Tooltip placement="top" title={"ویرایش تراکنش"}>
            <EditOutlined
              onClick={() =>
                window.open(`/transactions/edit/${row.id}`, "_self")
              }
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  function handleModal(item: Transaction) {
    setIsOpen(true);
    setSelectedItem(item);
  }

  var query = "?depositCurrencyType=2&state=6";
  const filterHandle = () => {
    setLoading(true);
    if (filterValues?.state?.id !== 0)
      query += `&state=${filterValues?.state?.id}`;
    if (filterValues?.startDate)
      query += `&startDate=${convertFaToEnCalendar(filterValues?.startDate)}`;
    if (filterValues?.endDate)
      query += `&endDate=${convertFaToEnCalendar(filterValues?.endDate)}`;
    if (filterValues?.keywords) query += `&keywords=${filterValues?.keywords}`;
    getAllTransactions(
      setLoading,
      setTransactions,
      1,
      25,
      query,
      setTransactionsCount
    );
  };

  return (
    <div className="waiting-offline-deposit-action">
      {selectedItem && (
        <CryptoTransactionModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          item={selectedItem}
          loading={false}
        />
      )}
      {selectedItem && (
        <Modal
          setIsOpen={setApproveModalIsOpen}
          isOpen={approveModalIsOpen}
          title={`از تغییر وضعیت به  "${TransactionStateLabel.get(
            modalTargetState
          )}" تراکش   ${selectedItem.trackingCode}   مطمئن هستید؟`}
          closeAble
          className="transaction-approve-modal"
          onClose={closeAppproveModal}
        >
          {(modalTargetState === TransactionState.Succeed ||
            modalTargetState === TransactionState.Cancel) && (
            <TextArea
              name="description"
              placeholder="توضیحات را وارد کنید"
              onChange={(e) => setDescriptionValue(e.target.value)}
              value={descriptionValue}
            />
          )}
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              loading={approveLoading}
              disabled={approveLoading}
              htmlType="button"
              onClick={(e) => onApproveHandler()}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => closeAppproveModal()}
            />
          </div>
        </Modal>
      )}
      {selectedItem && (
        <Modal
          setIsOpen={setPayoutModalIsOpen}
          isOpen={payoutModalIsOpen}
          closeAble
          title="آیا از تسویه تراکنش مطمئن هستید؟"
          className="transaction-payout-modal"
        >
          <div className="buttons">
            <Button
              type="info"
              text="بله مطمئنم"
              loading={payoutLoading}
              disabled={payoutLoading}
              htmlType="button"
              onClick={async (e) => {
                await payoutTranscation(
                  setPayoutLoading,
                  selectedItem,
                  closePayoutModal
                );
                getAllTransactions(
                  setLoading,
                  setTransactions,
                  getPageNum(),
                  25,
                  query,
                  setTransactionsCount
                );
              }}
            />
            <Button
              type="outlined"
              text="بازگشت"
              onClick={() => closePayoutModal()}
            />
          </div>
        </Modal>
      )}
      <Card
        title="واریز های آفلاین در انتظار"
        blur={cardLoading}
        className="desktop-show"
      >
        <div className="filter-row">
          <div className="fields">
            <Input
              type="text"
              value={filterValues?.keywords}
              placeholder="جستجو کنید ..."
              className="search"
              name="search"
              onPressEnter={filterHandle}
              onChange={(e) =>
                setFilterValues({ ...filterValues, keywords: e.target.value })
              }
            />

            <Calendar
              placeholder="از تاریخ"
              name="startDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  startDate: value,
                }))
              }
            />
            <Calendar
              value={filterValues?.endDate}
              placeholder="تا تاریخ"
              name="endDate"
              onChange={(value) =>
                setFilterValues((prev: FilterValues) => ({
                  ...prev,
                  endDate: value,
                }))
              }
            />
          </div>

          <Divider type="vertical" />
          <div className="buttons">
            <Button
              loading={loading}
              text="اعمال فیلتر"
              type="success"
              onClick={filterHandle}
            />
            <span
              onClick={() => {
                setCardLoading(true);
                setFilterValues({
                  keywords: "",
                  startDate: "",
                  endDate: "",
                  state: defaultStateItems[0],
                });
                setTransactions(transactions);
                getAllTransactions(
                  setCardLoading,
                  setTransactions,
                  getPageNum(),
                  pageSize,
                  "?depositCurrencyType=2&state=6",
                  setTransactionsCount
                );
              }}
            >
              حذف فیلتر
            </span>
          </div>
        </div>
        {transactions && (
          <Table
            getData={getAllTransactions}
            setData={setTransactions}
            setLoader={setCardLoading}
            blur={cardLoading}
            filters={query}
            pagination={transactionsCount}
            setCount={setTransactionsCount}
            data={transactions.items}
            column={columns}
            pageSize={transactionsCount?.pageSize}
            setPageSize={updatePageSize as Dispatch<SetStateAction<number>>}
          />
        )}
      </Card>
    </div>
  );
};
