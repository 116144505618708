import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import * as React from "react";
import {
  getValidationErrors,
  getViaAuth,
  hasValidationError,
  put,
  responseValidator,
} from "utils/scripts/api";
import "./index.scss";
import {
  deleteCommaSeparator,
  digitSeparator,
  faNumToEnNum,
} from "../../../../utils/scripts/decimal";
import { SettingGoogleAuthType } from "core/domains";
import { SettingGoogleAuth } from "../google-auth/google-auth";
import { useEffect } from "react";

interface CommisionSetting {
  orderSide?: number;
  commission?: string;
}

export const CommisionSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [buySettings, setBuySettings] = React.useState<CommisionSetting>();
  const [sellSettings, setSellSettings] = React.useState<CommisionSetting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();
  const [side, setSide] = React.useState<number>(1);

  const getCommisionSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.commisions).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setBuySettings(
          response.value.items.filter(
            (s: CommisionSetting) => s.orderSide === 1
          )[0]
        );
        setSellSettings(
          response.value.items.filter(
            (s: CommisionSetting) => s.orderSide === 2
          )[0]
        );
      }
    });
  };

  const onConfirmHandler = (googleAuthCode: string) => {
    setLoading(true);
    put(API.settings.changeOrderCommission, {
      orderSide: side,
      commission:
        side === 1
          ? Number(deleteCommaSeparator(faNumToEnNum(buySettings?.commission)))
          : Number(
              deleteCommaSeparator(faNumToEnNum(sellSettings?.commission))
            ),
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getCommisionSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  const onOpenModalHandle = (side: number) => {
    setSide(side);
    setModalIsOpen(true);
  };

  React.useEffect(() => {
    getCommisionSettings();
  }, []);

  useEffect(() => {
    console.log({ buySettings, sellSettings });
  }, [buySettings, sellSettings]);

  return (
    <>
      <SettingGoogleAuth
        submitData={onConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />

      <Card blur={cardLoading} className="vt-dashboard-commision-settings">
        <Card className="commision-item">
          <Input
            label="کارمزد سفارشات خرید"
            type="text"
            name="commision"
            value={digitSeparator(buySettings?.commission)}
            onChange={(e) =>
              setBuySettings({
                ...buySettings,
                commission: faNumToEnNum(e.target.value),
              })
            }
            dir="ltr"
            maxLength={80}
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onOpenModalHandle(1)}
            loading={loading}
            disabled={loading}
          />
        </Card>
        <Card className="commision-item">
          <Input
            label="کارمزد سفارشات فروش"
            type="text"
            name="commission"
            value={digitSeparator(sellSettings?.commission)}
            onChange={(e) =>
              setSellSettings({ ...sellSettings, commission: e.target.value })
            }
            dir="ltr"
            maxLength={80}
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onOpenModalHandle(2)}
            loading={loading}
            disabled={loading}
          />
        </Card>
      </Card>
    </>
  );
};
