import "./index.scss";
import * as React from "react";
import { SettingGoogleAuthType } from "../../../../core/domains";
import {
  getViaAuth,
  put,
  responseValidator,
} from "../../../../utils/scripts/api";
import { API } from "../../../../apis";
import { message } from "antd";
import { SettingGoogleAuth } from "../google-auth/google-auth";
import { Button, Card, Input } from "../../../../components";

interface LBankSetting {
  apiKey?: string;
  apiSecret?: string;
}

export const LBankSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [lbankSettings, setLBankSettings] = React.useState<LBankSetting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();

  const getLBankSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.lbankCredentials).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setLBankSettings(response.value);
      }
    });
  };

  const onConfirmHandler = (googleAuthCode: string) => {
    setLoading(true);
    put(API.settings.changeLBankCredentials, {
      ...lbankSettings,
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getLBankSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getLBankSettings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onConfirmHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card blur={cardLoading} className="vt-dashboard-bingx-credentials">
        <Card title={`کانفیگ ال بانک`} className="gateway-item">
          <Input
            label="کلید دسترسی"
            type="text"
            name="apiKey"
            value={lbankSettings?.apiKey}
            onChange={(e) =>
              setLBankSettings({ ...lbankSettings, apiKey: e.target.value })
            }
            dir="ltr"
          />
          <Input
            label="سکرت"
            type="text"
            name="secretKey"
            value={lbankSettings?.apiSecret}
            onChange={(e) =>
              setLBankSettings({
                ...lbankSettings,
                apiSecret: e.target.value,
              })
            }
            dir="ltr"
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => setModalIsOpen(true)}
            loading={loading}
            disabled={
              loading ||
              lbankSettings?.apiKey === "" ||
              lbankSettings?.apiSecret === ""
            }
          />
        </Card>
      </Card>
    </>
  );
};
