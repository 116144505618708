import * as React from "react";
import { Button, Input } from "components";
import {
  Transaction,
  TransactionType,
} from "core/domains/transaction/transaction";
import "./update.scss";
import { Col, Image, Row, Select, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import {
  deleteCommaSeparator,
  digitSeparator,
  faNumToEnNum,
} from "utils/scripts/decimal";
import {
  getTransactionById,
  getTransactionStates,
  updateTransaction,
} from "core/repos/transaction";
import { Enum, SettingGoogleAuthType } from "core/domains";
import moment from "jalali-moment";
import { SettingGoogleAuth } from "../../cash-in/google-auth/google-auth";

const { useState, useEffect } = React;

export const UpdateTransaction: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<Transaction>();
  const [states, setStates] = useState<Enum[]>([]);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const [isGoogleAuthOpen, setIsGoogleAuthOpen] = useState<boolean>(false);
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();

  const location = useLocation();
  const navigate = useNavigate();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const id = urlSections[urlSections.length - 1];
  function handleFormChange(value: string, field: string) {
    transaction && setTransaction({ ...transaction, [field]: value });
  }

  function handleUpdate() {
    if (transaction?.depositIrtType.id === 2) {
      setIsGoogleAuthOpen(true);
    } else {
      update();
    }
  }

  function submitGoogleAuth(code: string) {
    update();
    setIsGoogleAuthOpen(false);
  }

  function update() {
    updateTransaction(
      setLoading,
      {
        txId: transaction?.txId,
        id: transaction?.id,
        state: transaction?.state.id,
        payoutAmount: transaction?.payoutAmount,
        description: transaction?.description,
      },

      () => {
        navigate(-1);
      }
    );
  }

  useEffect(() => {
    !transaction && getTransactionById(setIsCardLoading, setTransaction, id);
    getTransactionStates(setIsCardLoading, setStates);
  }, []);

  useEffect(() => {
    if (
      transaction &&
      transaction.files &&
      transaction.files.fileByteContent.length > 0
    ) {
      const fileByte = transaction.files.fileByteContent[0];
      const base64String = `data:image/jpeg;base64,${fileByte}`;
      setImageUrl(base64String);
    }
  }, [transaction]);

  const updateOfflineDepositTransaction = async (googleAuthCode: string) => {
    updateTransaction(
      setLoading,
      {
        txId: transaction?.txId,
        id: transaction?.id,
        state: transaction?.state.id,
        amount: Number(deleteCommaSeparator(transaction?.amount)),
        description: transaction?.description,
        googleAuthCode,
        paymentIdentifier: transaction?.paymentIdentifier,
      },
      () => {
        setIsGoogleAuthOpen(false);
        navigate(-1);
      }
    );
  };

  return (
    <>
      <Spin spinning={isCardLoading} tip="درحال بارگذاری">
        <div className="vt-dashboard-transactions-edit">
          <SettingGoogleAuth
            submitData={updateOfflineDepositTransaction}
            data={googleAuth}
            setData={setGoogleAuth}
            isOpen={isGoogleAuthOpen}
            setIsOpen={setIsGoogleAuthOpen}
            loading={loading}
          />
          <h3>
            {`اصلاح تراکنش`}{" "}
            <span className="en">{transaction?.trackingCode}</span>
          </h3>
          {transaction?.state && (
            <form>
              <div className="row">
                {transaction.market.symbol !== "IRT" && (
                  <Input
                    type="text"
                    label="Txid"
                    name="name"
                    value={transaction?.txId}
                    onChange={(e) => handleFormChange(e.target.value, "txId")}
                  />
                )}
                <Select
                  placeholder={"وضعیت"}
                  defaultValue={transaction.state.title}
                  onChange={(value) =>
                    transaction &&
                    setTransaction({
                      ...transaction,
                      state: { id: parseInt(value), title: "انتخاب" },
                    })
                  }
                >
                  <Select.Option key={transaction.state.id}>
                    {transaction?.state.title}
                  </Select.Option>

                  {states.map((state: Enum) => {
                    return (
                      state.id !== transaction?.state.id && (
                        <Select.Option key={state.id}>
                          {state.title}
                        </Select.Option>
                      )
                    );
                  })}
                </Select>
              </div>
              <div className="text-area-field">
                <label htmlFor="description">توضیحات</label>
                <TextArea
                  name="description"
                  value={transaction?.description}
                  onChange={(e) =>
                    handleFormChange(e.target.value, "description")
                  }
                  maxLength={100}
                />
              </div>
              {transaction?.depositIrtType.id === 2 && (
                <div className={"row"}>
                  <Input
                    type="text"
                    label="شماره پیگیری"
                    name="paymentIdentifier"
                    value={transaction?.paymentIdentifier}
                    onChange={(e) =>
                      handleFormChange(e.target.value, "paymentIdentifier")
                    }
                    maxLength={25}
                  />
                </div>
              )}
              <div className="row">
                {transaction.type.id == TransactionType.Withdrawal &&
                  transaction.market.symbol === "IRT" && (
                    <>
                      <Input
                        type="text"
                        label="مقدار جهت تسویه"
                        name="name"
                        value={digitSeparator(
                          transaction?.payoutAmount?.toString()
                        )}
                        onChange={(e) =>
                          handleFormChange(
                            deleteCommaSeparator(e.target.value),
                            "payoutAmount"
                          )
                        }
                        maxLength={25}
                      />
                      {transaction.digitalReceipt ? (
                        <Input
                          type="text"
                          label="رسید دیجیتالی"
                          name="digitalReceipt"
                          value={transaction?.digitalReceipt}
                          onChange={(e) =>
                            handleFormChange(e.target.value, "digitalReceipt")
                          }
                        />
                      ) : null}
                    </>
                  )}
              </div>
              <div className={"row image"}>
                <Input
                  type="text"
                  label="مقدار "
                  name="amount"
                  value={digitSeparator(transaction?.amount)}
                  onChange={(e) =>
                    handleFormChange(faNumToEnNum(e.target.value), "amount")
                  }
                  disabled={transaction.depositIrtType.id !== 2}
                  maxLength={25}
                />
                {imageUrl && (
                  <div
                    className="image-preview"
                    style={{
                      margin: "auto 0",
                    }}
                  >
                    <Image
                      width={128}
                      height={128}
                      src={imageUrl}
                      alt="Transaction File"
                      preview={{}}
                    />
                  </div>
                )}
              </div>
              <Row>
                <Col style={{ display: "flex", flexDirection: "column" }}>
                  <span>
                    ویرایش شده در تاریخ{" "}
                    {transaction.lastModifiedDate &&
                      moment
                        .utc(transaction.lastModifiedDate)
                        .locale("fa")
                        .local()
                        .format("jYYYY/MM/DD")}{" "}
                    |{" "}
                    {transaction.lastModifiedDate &&
                      moment
                        .utc(transaction.lastModifiedDate)
                        .local()
                        .format("HH:mm")}
                  </span>
                  <span>ویرایش شده توسط {transaction.lastModifiedBy}</span>
                </Col>
                <Col>
                  <span>شماره همراه کاربر: </span>
                  <span style={{ color: "black" }}>
                    {transaction.phoneNumber}
                  </span>
                </Col>
              </Row>
              <div className="row">
                <Button
                  htmlType="button"
                  type="info"
                  text="اصلاح"
                  onClick={handleUpdate}
                  loading={loading}
                />

                <Button
                  htmlType="button"
                  type="outlined"
                  text="بازگشت"
                  onClick={() => navigate(-1)}
                />
              </div>
            </form>
          )}
        </div>
      </Spin>
    </>
  );
};
