import "./delete-modal.scss";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { Button, Modal } from "../../../../components";
import { PopUp } from "../../../../core/domains";
import { deletePopup } from "../../../../core/repos/popup";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  popUp: PopUp;
  onComplete: () => void;
}

export const DeletePopupModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  popUp,
  onComplete,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = () => {
    deletePopup(setLoading, popUp.id, onComplete);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="confirm-box">
        <div className="head">
          <h4>آیا از حذف پاپ‌آپ {popUp?.title} مطمئن هستید؟</h4>
        </div>
        <div className="buttons">
          <Button
            type="info"
            text="بله مطمئنم"
            htmlType="button"
            onClick={handleDelete}
            loading={loading}
          />
          <Button
            type="outlined"
            text="بازگشت"
            onClick={() => setIsOpen(false)}
          />
        </div>
      </div>
    </Modal>
  );
};
