import * as React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Modal } from "components";
import "./index.scss";
import moment from "jalali-moment";
import { getIconPath } from "utils/scripts/markets";
import { copyToClipboard } from "utils/scripts/copyToClipboard";
import { Transaction } from "core/domains/transaction/transaction";
import { digitSeparator } from "utils/scripts/decimal";
import { detect_bank } from "../../../../../utils/scripts/bankDetecter";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item: Transaction;
}

export const CurrencyTransactionModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  item,
}) => {
  return (
    <Modal
      className="currency-transactions-detail-modal"
      isOpen={isOpen}
      closeAble
      title="جزئیات تراکنش"
      setIsOpen={setIsOpen}
    >
      <div className="content">
        <div className="row">
          <div className="col">
            <span>نام ارز</span>
            <div className="coin-info">
              <img
                alt={item.market.symbol}
                src={getIconPath(item.market?.symbol)}
              />
              <span>{item.market?.name}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <span>نوع تراکنش</span>
          <div>
            <span>{item.type.title}</span>
          </div>
        </div>
        <div className="row">
          <span>مقدار تراکنش</span>
          <div>
            <span className="en">
              {digitSeparator(
                item.market.symbol.toUpperCase() === "IRT"
                  ? item.amount.toFixed(0)
                  : item.amount
              )}{" "}
              {item.market.symbol}
            </span>
          </div>
        </div>

        {item.onPrice !== 0 && item.market.symbol.toUpperCase() !== "IRT" && (
          <div className="row">
            <span>قیمت لحظه</span>
            <div>
              <span className="en">{item.onPrice}</span>
            </div>
          </div>
        )}
        {item.bankAccount && item.trackingCode.startsWith("D") ? (
          <div className="row">
            <span>شماره کارت پرداختی</span>
            <div>
              <span className="en">
                {item.bankAccount &&
                  item.bankAccount.cardNumber &&
                  detect_bank(item.bankAccount.cardNumber)?.bank_logo && (
                    <img
                      className="bankLogo"
                      src={detect_bank(item.bankAccount?.cardNumber)?.bank_logo}
                    />
                  )}{" "}
                {item.bankAccount?.cardNumber ?? "-"}
              </span>
            </div>
          </div>
        ) : (
          item.bankAccount && (
            <div className="row">
              <span>شماره شبا</span>
              <div>
                <span className="en">
                  {item.bankAccount.iBan.toUpperCase().startsWith("IR")
                    ? item.bankAccount?.iBan
                    : "IR" + item.bankAccount?.iBan}
                </span>
              </div>
            </div>
          )
        )}
        {item.paymentIdentifier && (
          <div className="row">
            <span>شناسه یکتا تراکنش</span>
            <div>
              <span className="en">{item.paymentIdentifier}</span>
            </div>
          </div>
        )}

        {item.ip && (
          <div className="row">
            <span>آیپی کاربر</span>
            <div>
              <span className="en">{item.ip}</span>
            </div>
          </div>
        )}
        <div className="row ">
          <span>شناسه تراکنش</span>
          <div>
            <span className="en">{item.trackingCode}</span>
            <CopyOutlined
              onClick={() => copyToClipboard(item.trackingCode, "شناسه سفارش")}
            />
          </div>
        </div>
        {item.digitalReceipt && (
          <div className="row ">
            <span>رسید دیجیتالی</span>
            <div>
              <span className="en">{item.digitalReceipt}</span>
              <CopyOutlined
                onClick={() =>
                  copyToClipboard(item?.digitalReceipt || "", "رسید دیجیتالی")
                }
              />
            </div>
          </div>
        )}
        {item.gateWayName && (
          <div className="row ">
            <span>درگاه پرداخت</span>
            <div>
              <span className="en">{item.gateWayName}</span>
            </div>
          </div>
        )}
        <div className="row">
          <span>تاریخ و ساعت</span>
          <span>
            {moment.utc(item.createdAt).locale("fa").format("YYYY/MM/DD")} |{" "}
            {moment(item.createdAt).local().format("HH:mm")}
          </span>
        </div>
        {item.description && (
          <div className="row">
            <span>توضیحات</span>
            <span>{item.description}</span>
          </div>
        )}
        <div className="row state">
          <span>وضعیت</span>
          <span
            className={`${
              item.state.id === 2
                ? "success"
                : item.state.id === 3
                ? "error"
                : "waiting"
            }`}
          >
            {item.state.title}
          </span>
        </div>
      </div>
    </Modal>
  );
};
