import * as React from "react";
import "./notification-details.scss";
import { Button, Card, Input, Modal } from "components";
import { Table } from "components/table/table";
import {
  Notification,
  NotificationDetail,
  NotificationFilters,
} from "core/domains/notification/notification";
import { Link, useNavigate } from "react-router-dom";
import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { TableColumn } from "react-data-table-component";
import {
  deleteNotification,
  getAllNotificationDetails,
  getNotificationReasons,
} from "core/repos/notification";
import { Enum, PaginationList } from "core/domains";
import { Dropdown, Menu, Popover, Tooltip } from "antd";
import moment from "jalali-moment";
import { Badge } from "components/badge/badge";

const defaultNotificationReason = {
  id: 0,
  title: "همه",
};

const isReadFilterItems = [
  { id: 0, title: "همه" },
  { id: 1, title: "خوانده شده" },
  { id: 2, title: "خوانده نشده" },
];

const empty_filters = {
  search: "",
  reason: defaultNotificationReason,
  isRead: isReadFilterItems[0],
};

export const NotificationDetails: React.FC = () => {
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [notificationDetails, setNotificationDetails] =
    React.useState<PaginationList<NotificationDetail>>();
  const [selectedItem, setSelectedItem] = React.useState<NotificationDetail>();
  const [deleteModalIsOpen, setDeleteModalIsOpen] =
    React.useState<boolean>(false);
  const [deleteModalIsLoading, setDeleteModalIsLoading] =
    React.useState<boolean>(false);
  const [reasons, setReasons] = React.useState<Enum[]>();
  const [filters, setFilters] =
    React.useState<NotificationFilters>(empty_filters);
  const [query, setQuery] = React.useState<string>("");
  const [pageSize, setPageSize] = React.useState<number>(10);

  function onReasonTagClick(selected: Enum) {
    setFilters({ ...filters, reason: selected });
  }

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  var messageFromUrl = urlSections[urlSections.length - 1];
  const filterHandle = (newFilters: NotificationFilters) => {
    var q = `?message=${messageFromUrl}`;

    if (newFilters) {
      if (newFilters.reason?.id !== 0)
        q += `${q.includes("?") ? "&" : "?"}reason=${newFilters.reason?.id}`;
      if (newFilters.search !== "")
        q += `${q.includes("?") ? "&" : "?"}keywords=${newFilters.search}`;
      if (newFilters.isRead.id !== 0)
        q += `${q.includes("?") ? "&" : "?"}isread=${
          newFilters.isRead.id == 1 ? true : false
        }`;
    }
    setQuery(q);

    getAllNotificationDetails(
      setCardLoading,
      setNotificationDetails,
      1,
      pageSize,
      q
    );
  };

  React.useEffect(() => {
    getNotificationReasons(setCardLoading, setReasons);
  }, []);

  React.useEffect(() => {
    filterHandle(filters);
  }, []);

  const navigate = useNavigate();

  const onDeleteClick = (item?: NotificationDetail) => {
    setSelectedItem(item);
    setDeleteModalIsOpen(true);
  };

  const columns: TableColumn<NotificationDetail>[] = [
    {
      name: "عنوان",
      id: "title",
      selector: (row) => row?.title,
    },
    {
      name: "پیام",
      id: "message",
      selector: (row) => row.message || "",
      cell: (record) => (
        <Popover content={record.message}>
          <span className="message-column">{record.message}</span>
        </Popover>
      ),
    },
    {
      name: "تاریخ ایجاد",
      id: "createdOn",
      selector: (row) => row.createdOn,
      cell: (row) => (
        <div className="column-date">
          <span className="day">
            {moment
              .utc(row.createdOn)
              .locale("fa")
              .local()
              .format("jYYYY/MM/DD")}
          </span>
          <span className="time">
            {moment.utc(row.createdOn).local().format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      name: "کابر",
      id: "fullName",
      selector: (row) => row.fullName,
    },
    {
      name: "وضعیت",
      id: "isRead",
      selector: (row) => row.isRead,
      cell: (row) => (
        <Badge
          type={row.isRead ? "success" : "waiting"}
          text={row.isRead ? "خوانده شده" : "خوانده نشده"}
        />
      ),
    },
    {
      name: "دلیل",
      id: "reason",
      selector: (row) => row.reason.title,
      cell: (record) => (
        <Badge
          type={
            record.reason.id == 1
              ? "info"
              : record.reason.id == 2
              ? "primary"
              : record.reason.id == 3
              ? "waiting"
              : "error"
          }
          text={record.reason.title}
        />
      ),
    },
    {
      name: "عملیات",
      id: "actions",
      cell: (record) => (
        <div className="actions">
          <Link to={`/notifications/edit/${record.id}`}>
            <Tooltip placement="top" title="ویرایش اعلان">
              <EditOutlined />
            </Tooltip>
          </Link>
          <Link to={""} onClick={() => onDeleteClick(record)}>
            <Tooltip placement="top" title="حذف اعلان">
              <DeleteOutlined />
            </Tooltip>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Card
      title={`جزییات اعلان های با متن : `}
      description={decodeURIComponent(messageFromUrl)}
      className="vt-dashboard-notifications"
      blur={cardLoading}
    >
      <Modal
        title={"آیا از حذف اعلان اطمینان دارید؟"}
        isOpen={deleteModalIsOpen}
        setIsOpen={setDeleteModalIsOpen}
        className="delete-notification-modal"
      >
        <div className="buttons">
          <Button
            type="info"
            text="بله مطمئنم"
            htmlType="button"
            loading={deleteModalIsLoading}
            disabled={deleteModalIsLoading}
            onClick={() =>
              deleteNotification(
                setDeleteModalIsLoading,
                selectedItem?.id!,
                () => {
                  setDeleteModalIsOpen(false);
                  getAllNotificationDetails(
                    setCardLoading,
                    setNotificationDetails,
                    1,
                    pageSize,
                    query
                  );
                }
              )
            }
          />
          <Button
            type="outlined"
            text="بازگشت"
            onClick={() => setDeleteModalIsOpen(false)}
          />
        </div>
      </Modal>
      <div className="filter-row">
        <div className="fields">
          <Input
            type="text"
            className="search"
            value={filters.search}
            placeholder="جستجو کنید ..."
            name="search"
            onPressEnter={() => filterHandle(filters)}
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
          />
          {reasons && (
            <Dropdown
              trigger={["click", "hover"]}
              overlay={
                <Menu>
                  {reasons.length &&
                    reasons.map((reason) => (
                      <Menu.Item
                        onClick={() =>
                          setFilters({
                            ...filters,
                            reason: reason,
                          })
                        }
                      >
                        {reason.title}
                      </Menu.Item>
                    ))}
                </Menu>
              }
              placement="bottom"
            >
              <Button
                icon={<DownOutlined />}
                type="outlined"
                text={filters.reason?.title}
              />
            </Dropdown>
          )}

          <Dropdown
            trigger={["click", "hover"]}
            overlay={
              <Menu>
                {isReadFilterItems.length &&
                  isReadFilterItems.map((item) => (
                    <Menu.Item
                      onClick={() =>
                        setFilters({
                          ...filters,
                          isRead: item,
                        })
                      }
                    >
                      {item.title}
                    </Menu.Item>
                  ))}
              </Menu>
            }
            placement="bottom"
          >
            <Button
              icon={<DownOutlined />}
              type="outlined"
              text={filters.isRead?.title}
            />
          </Dropdown>
        </div>
        <div className="buttons">
          <Button
            loading={loading}
            disabled={loading}
            text="اعمال فیلتر"
            type="success"
            onClick={() => filterHandle(filters)}
          />
          <span
            onClick={() => {
              setFilters({
                search: "",
                reason: defaultNotificationReason,
                isRead: isReadFilterItems[0],
              });
            }}
          >
            حذف فیلتر
          </span>
        </div>
      </div>
      {notificationDetails && (
        <Table
          className="notification-notifications-table"
          data={notificationDetails.items}
          column={columns}
          pagination={notificationDetails.pagination}
          getData={getAllNotificationDetails}
          setData={setNotificationDetails}
          setLoader={setCardLoading}
          filters={query}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </Card>
  );
};
