import { AreaChartOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Col, Row, Spin } from "antd";
import { API } from "apis";
import { Card } from "components";
import * as React from "react";
import { getViaAuth, responseValidator } from "utils/scripts/api";
import { digitSeparator } from "utils/scripts/decimal";
import "./order-reports.scss";
import { UserOrderReport } from "core/domains";

export const UserOrderReports: React.FC = () => {
  const [reports, setReports] = React.useState<UserOrderReport>();
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);

  const urlSections = window.location.pathname.split("/");
  const userId = urlSections[urlSections.length - 1];

  const getUserSummary = () => {
    setCardLoading(true);
    getViaAuth(API.users.getSummery + `${userId}`).then((data: any) => {
      setCardLoading(false);
      if (responseValidator(data.status)) {
        setReports(data.value);
      }
    });
  };

  React.useEffect(() => {
    getUserSummary();
  }, []);

  return (
    <div className="vt-user-detail-reports">
      <Spin spinning={cardLoading}>
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" span={12}>
            <Card
              className="active"
              tags={<ShoppingCartOutlined />}
              title="سفارشات"
            >
              <p className="prices">
                <span>تعداد سفارشات خرید : {reports?.countBuyOrders}</span>
                <span className="divider"> </span>
                <span>
                  تعداد سفارشات فروش : {reports?.countSellOrders.toFixed(0)}
                </span>
              </p>
              <p className="prices">
                <span>
                  مجموع سفارشات خرید :{" "}
                  {digitSeparator(reports?.sumBuyOrders.toFixed(0))} تومان
                </span>
                <span className="divider"> </span>
                <span>
                  مجموع سفارشات فروش :
                  {digitSeparator(reports?.sumSellOrders.toFixed(0))} تومان
                </span>
              </p>
            </Card>
          </Col>

          <Col className="gutter-row" span={12}>
            <Card
              className="active"
              tags={<AreaChartOutlined />}
              title="واریز و برداشت ریالی"
            >
              <p className="prices">
                <span>
                  تعداد واریز ریالی : {reports?.transactionCountDepositIrt}{" "}
                </span>
                <span className="divider"> </span>
                <span>
                  تعداد برداشت ریالی : {reports?.transactionCountWithdrawalIrt}{" "}
                </span>
              </p>
              <p className="prices">
                <span>
                  مجموع واریز ریالی:{" "}
                  {digitSeparator(reports?.transactionSumDepositIrt)} تومان
                </span>
                <span className="divider"> </span>
                <span>
                  مجموع برداشت ریالی:{" "}
                  {digitSeparator(reports?.transactionSumWithdrawalIrt)} تومان
                </span>
              </p>
            </Card>
          </Col>
          <Col className="gutter-row" span={12}>
            <Card
              className="active"
              tags={<AreaChartOutlined />}
              title="واریز و برداشت پرفکت مانی"
            >
              <p className="prices">
                <span>
                  تعداد واریز پرفکت مانی : {reports?.transactionCountDepositPm}{" "}
                </span>
                <span className="divider"> </span>
                <span>
                  تعداد برداشت پرفکت مانی :{" "}
                  {reports?.transactionCountWithdrawalPm}{" "}
                </span>
              </p>
              <p className="prices">
                <span>
                  مجموع واریز پرفکت مانی:{" "}
                  {digitSeparator(reports?.transactionSumDepositPm)} تومان{" "}
                </span>
                <span className="divider"> </span>
                <span>
                  مجموع برداشت پرفکت مانی:{" "}
                  {digitSeparator(reports?.transactionSumWithdrawalPm)} تومان
                </span>
              </p>
            </Card>
          </Col>
          <Col className="gutter-row" span={12}>
            <Card
              className="active"
              tags={<AreaChartOutlined />}
              title=" تراکنش ها ارزی"
            >
              <p className="prices">
                <span>
                  تعداد تراکنش های واریز :{" "}
                  {reports?.transactionsCryptoDepositCount}{" "}
                </span>
                <span className="divider"> </span>
                <span>
                  تعداد تراکنش های برداشت :{" "}
                  {reports?.transactionsCryptoWithdrawalCount}{" "}
                </span>
              </p>
              <p className="prices">
                <span>
                  مجموع تراکنش های واریزی:{" "}
                  {reports?.transactionsCryptoDepositSumInDollar} دلار
                </span>
                <span className="divider"> </span>
                <span>
                  مجموع تراکنش های برداشت:{" "}
                  {reports?.transactionsCryptoWithdrawalSumInDollar} دلار
                </span>
              </p>
            </Card>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
