import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import * as React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import "./index.scss";

interface ZibalSetting {
  apiKey?: string;
  walletId?: string;
}

export const ZibalSettings: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [zibalSettings, setZibalSettings] = React.useState<ZibalSetting>();

  const getZibalSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.zibalSettings).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setZibalSettings(response.value);
      }
    });
  };

  const onConfirmHandler = () => {
    setLoading(true);
    put(API.settings.changeZibalSettings, {
      ...zibalSettings,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success(response.data.message);
        getZibalSettings();
      }
    });
  };

  React.useEffect(() => {
    getZibalSettings();
  }, []);

  return (
    <>
      <Card blur={cardLoading} className="vt-dashboard-zibal-settings">
        <Card title={`کانفیگ درگاه زیبال`} className="gateway-item">
          <Input
            label="کلید API"
            type="text"
            name="apiKey"
            value={zibalSettings?.apiKey}
            onChange={(e) =>
              setZibalSettings({ ...zibalSettings, apiKey: e.target.value })
            }
            dir="ltr"
          />
          <Input
            label="آیدی کیف پول"
            type="text"
            name="walletId"
            value={zibalSettings?.walletId}
            onChange={(e) =>
              setZibalSettings({ ...zibalSettings, walletId: e.target.value })
            }
            dir="ltr"
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandler()}
            loading={loading}
            disabled={loading || zibalSettings?.apiKey === ""}
          />
        </Card>
      </Card>
    </>
  );
};
