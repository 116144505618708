import { API } from "apis";
import { getViaAuth, responseValidator } from "utils/scripts/api";

export const getAllOrders = async (
  setloader: any,
  setdata: any,
  page: number,
  pageSize: number,
  query: string,
  setCount: any
) => {
  setloader(true);
  await getAllOrdersCount(() => {}, setCount, page, pageSize, query);
  await getViaAuth(`${API.order.getAll}/${page}/${pageSize}${query}`).then(
    (response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setdata(response.value);
      }
    }
  );
};

export const getAllOrdersCount = async (
  setLoader: any,
  setData: any,
  page: number,
  pageSize: number,
  query: string
) => {
  setLoader(true);
  await getViaAuth(`${API.order.getCount}/${page}/${pageSize}${query}`).then(
    (response: any) => {
      setLoader(false);
      if (responseValidator(response.status)) setData(response.value);
    }
  );
};

export const getCryptoOrders = (
  setloader: any,
  setdata: any,
  page: number,
  size: number,
  query: string
) => {
  setloader(true);
  getViaAuth(`${API.order.getAll}/${page}/10${query}`).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata({
        ...response.value,
        items: response.value.items,
      });
    }
  });
};

export const getOrderStates = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(`${API.order.states}`).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const getOrderTypes = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(`${API.order.types}`).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};
