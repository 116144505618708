import * as React from "react";
import { useRef } from "react";
import { BottomMenu, Navbar, Sidebar } from "components";
import useOnBlur from "utils/scripts/useOnBlur";
import "./dashboard.scss";
import { useLocation } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "core/redux/store";
import { MobileMenuItems, SidebarItems } from "utils/constants/common";
import { useAppDispatch } from "core/redux/hooks";
import { setMenuItem, setSidebar } from "core/redux/actions";
import { getViaAuth, responseValidator } from "utils/scripts/api";
import { API } from "apis";
import { waitingCounts } from "core/domains";
import { Helmet } from "react-helmet";
import { APPNAME, Fav } from "apis/constants";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  component?: React.ReactElement;
}

const Dashboard: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const sidebarRef = useRef<any>();
  const [title, setTitle] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>();
  const [waitingCount, setWaitingCount] = React.useState<
    waitingCounts | undefined
  >(undefined);

  useOnBlur(sidebarRef, () => {
    if (props.state.sidebar === "open") dispatch(setSidebar("close"));
  });

  React.useEffect(() => {
    if (location.pathname.split("/")[0].includes("signin")) setTitle("ورود");
    else
      setTitle(
        SidebarItems?.filter((item) =>
          item.link.startsWith(location.pathname)
        )[0]?.title.toString() ??
          SidebarItems?.filter(
            (item) =>
              item.childrens?.filter((q) =>
                q.link.startsWith(location.pathname)
              )[0]
          )[0]?.title ??
          ""
      );
  }, [location.pathname]);

  const path = location.pathname.trim();
  React.useEffect(() => {
    setLoading(true);
    getViaAuth(API.awaitingconfirmation.getawaitingList).then(
      (response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
          setWaitingCount(response.value);
        } else {
          setWaitingCount({
            approveIRTTransaction: 0,
            approveCryptoTransaction: 0,
            tickets: 0,
            approveToAddBankAccount: 0,
            approveToUpgradeLevel: 0,
            transactionsPendingAdminApproval: 0,
          });
        }
      }
    );

    SidebarItems.map((item) => {
      path.includes(item.link) &&
        path === item.link &&
        dispatch(setMenuItem(item.id));
    });
  }, [location.pathname]);

  return (
    <div ref={sidebarRef} className="vt-container">
      <Helmet>
        <title>
          {title || ""} | ادمین {APPNAME}
        </title>
        <link rel="icon" href={Fav} />

        <meta
          name="description"
          content="خرید و فروش اتوماتیک و آسان پرفکت مانی ، تتر و رمز ارز ها احراز هویت هوشمند و سریع زیر ۱ دقیقه بدون معطلی نرخ های رقابتی و بدون کارمزد"
        />
      </Helmet>
      <Sidebar items={SidebarItems} waitingCounts={waitingCount} />
      <Navbar />
      {waitingCount !== undefined && (
        <div className="vt-dashboard">{props.component}</div>
      )}{" "}
      <BottomMenu items={MobileMenuItems} />
    </div>
  );
};

export default connector(Dashboard);
