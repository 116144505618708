import { message } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import * as React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import "./index.scss";

interface JibitKycSetting {
  secret?: string;
  key?: string;
}

export const JibitKycSettings: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [jibitSettings, setJibitSettings] = React.useState<JibitKycSetting>();

  const getJibitSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.jibitKycCredentials).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setJibitSettings(response.value);
      }
    });
  };

  const onConfirmHandler = () => {
    setLoading(true);
    put(API.settings.changeJibitKycSetting, {
      ...jibitSettings,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success(response.data.message);
        getJibitSettings();
      }
    });
  };

  React.useEffect(() => {
    getJibitSettings();
  }, []);

  return (
    <>
      <Card blur={cardLoading} className="vt-dashboard-jibit-settings">
        <Card title={`تنظیمات سرویس احراز جیبیت`} className="gateway-item">
          <Input
            label="کلید وب سرویس"
            type="text"
            name="key"
            value={jibitSettings?.key}
            onChange={(e) =>
              setJibitSettings({ ...jibitSettings, key: e.target.value })
            }
            dir="ltr"
          />
          <Input
            label="سکرت"
            type="text"
            name="secret"
            value={jibitSettings?.secret}
            onChange={(e) =>
              setJibitSettings({
                ...jibitSettings,
                secret: e.target.value,
              })
            }
            dir="ltr"
          />

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandler()}
            loading={loading}
            disabled={
              loading ||
              jibitSettings?.key === "" ||
              jibitSettings?.secret === ""
            }
          />
        </Card>
      </Card>
    </>
  );
};
