import "./edit.scss";
import { Button, Card, Input } from "../../../../components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  editPromocode,
  getPromocodeById,
} from "../../../../core/repos/promocode";
import {
  EditPromocodeBody,
  Promocode,
} from "../../../../core/domains/promocode/promocode";
import { message, Switch } from "antd";
import { faNumToEnNum } from "../../../../utils/scripts/decimal";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DatePicker from "react-multi-date-picker";
import { deleteCommaSperator } from "../../../../utils/scripts/exchange";
import moment from "jalali-moment";

export const EditPromocode = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<EditPromocodeBody | Promocode>({
    id: "",
    code: "",
    expiresAt: "",
    symbol: "",
    amount: undefined,
    isActive: false,
  });

  useEffect(() => {
    if (id) {
      getPromocodeById(setLoading, id, setFormData);
    }
  }, [id]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: faNumToEnNum(value),
    }));
  };

  const handleDateChange = (date: any) => {
    setFormData((prev) => ({
      ...prev,
      expiresAt: moment(faNumToEnNum(date.format()), "jYYYY/jMM/jDD", "fa")
        .locale("en")
        .format("YYYY-MM-DD"),
    }));
  };

  return (
    <Card
      title="ویرایش کد جایزه"
      className="vt-dashboard-edit-promocode"
      blur={loading}
    >
      <Input
        type="text"
        name="code"
        value={formData.code}
        onChange={handleChange}
        label="کد:"
        placeholder="کد را وارد کنید"
      />
      <DatePicker
        className="date-picker"
        value={formData.expiresAt}
        calendar={persian}
        locale={persian_fa}
        render={
          <div>
            <Input
              onChange={() => {}}
              name={"expiresAt"}
              type={"text"}
              value={
                formData.expiresAt
                  ? new Date(formData.expiresAt).toLocaleDateString("fa")
                  : undefined
              }
              placeholder="تاریخ انقضا"
            />
          </div>
        }
        onChange={handleDateChange}
      />
      <Input
        type="text"
        name="symbol"
        value={formData.symbol}
        onChange={handleChange}
        label="نماد:"
        placeholder="نماد را وارد کنید"
      />
      <Input
        type="text"
        name="amount"
        value={formData.amount?.toString()}
        onChange={handleChange}
        label="مقدار:"
        placeholder="مقدار را وارد کنید"
      />
      <label>فعال</label>
      <Switch
        checked={formData.isActive}
        onChange={(e) => setFormData({ ...formData, isActive: e })}
      />
      <Button
        type="success"
        loading={editLoading || loading}
        disabled={editLoading || loading}
        onClick={() => {
          editPromocode(
            setEditLoading,
            {
              ...formData,
              amount: Number(deleteCommaSperator(formData.amount)),
              expiresAt: formData?.expiresAt,
            },
            () => message.success("کد جایزه با موفقیت ویرایش شد.")
          );
        }}
        text={"ویرایش کد جایزه"}
      />
    </Card>
  );
};
