import "./add.scss";
import { Button, Card, Input } from "../../../../components";
import { useEffect, useState } from "react";
import { AddPromocodeBody } from "../../../../core/domains/promocode/promocode";
import { addPromoCode } from "../../../../core/repos/promocode";
import { message, Select, Switch } from "antd";
import {
  digitSeparator,
  faNumToEnNum,
} from "../../../../utils/scripts/decimal";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import moment from "jalali-moment";
import { deleteCommaSperator } from "../../../../utils/scripts/exchange";
import { useNavigate } from "react-router-dom";
import { Market, PaginationList } from "../../../../core/domains";
import { getAllMarketsUser } from "../../../../core/repos";

export const AddPromocode = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<AddPromocodeBody>({
    code: "",
    expiresAt: "",
    symbol: "",
    amount: undefined,
    isActive: false,
  });
  const [markets, setMarkets] = useState<PaginationList<Market> | null>(null);
  const [marketsLoading, setMarketsLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>(""); // State for search input

  const navigate = useNavigate();

  useEffect(() => {
    getAllMarketsUser(setMarketsLoading, setMarkets, 1, 999, "");
    console.log(markets);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: faNumToEnNum(value),
    }));
  };

  const handleDateChange = (date: any) => {
    setFormData((prev) => ({
      ...prev,
      expiresAt: date.format(),
    }));
  };

  const handleSubmit = async () => {
    await addPromoCode(
      setLoading,
      {
        ...formData,
        amount: Number(deleteCommaSperator(formData.amount)),
        expiresAt: moment(
          faNumToEnNum(formData?.expiresAt),
          "jYYYY/jMM/jDD",
          "fa"
        )
          .locale("en")
          .format("YYYY-MM-DD"),
      },
      () => {
        message.success("کد جایزه با موفقیت افزوده شد.");
        navigate(-1);
      }
    );
  };

  const handleMarketChange = (value: string) => {
    setFormData((prev) => ({
      ...prev,
      symbol: value.toUpperCase(),
    }));
  };

  const filteredMarkets =
    markets &&
    markets.items.filter(
      (market) =>
        market.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        market.symbol.toLowerCase().includes(searchValue.toLowerCase())
    );

  return (
    <Card title="افزودن کد جایزه" className="vt-dashboard-add-promocode">
      <Input
        type="text"
        name="code"
        value={formData.code}
        onChange={handleChange}
        label="کد:"
        placeholder="کد را وارد کنید"
      />
      <DatePicker
        className="date-picker"
        value={formData.expiresAt}
        calendar={persian}
        locale={persian_fa}
        render={
          <div>
            <Input
              onChange={() => {}}
              name={"expiresAt"}
              type={"text"}
              value={formData.expiresAt}
              placeholder="تاریخ انقضا"
              label={"تاریخ"}
            />
          </div>
        }
        onChange={handleDateChange}
      />
      <Select
        showSearch
        placeholder="انتخاب نماد"
        loading={marketsLoading}
        onSearch={setSearchValue}
        onChange={handleMarketChange}
        style={{ width: "100%" }}
        allowClear
      >
        {markets &&
          filteredMarkets &&
          filteredMarkets?.map((market) => (
            <Select.Option key={market.id} value={market.symbol}>
              {market.symbol} ({market.name})
            </Select.Option>
          ))}
      </Select>
      <Input
        type="text"
        name="amount"
        value={digitSeparator(faNumToEnNum(formData?.amount))}
        onChange={handleChange}
        label="مقدار:"
        placeholder="مقدار را وارد کنید"
      />
      <label>فعال</label>
      <Switch
        checked={formData.isActive}
        onChange={(e) => {
          setFormData({ ...formData, isActive: e });
        }}
      />
      <Button
        type="success"
        loading={loading}
        disabled={
          loading ||
          !formData.amount ||
          !formData.code ||
          !formData.expiresAt ||
          !formData.symbol
        }
        onClick={handleSubmit}
        text={"افزودن کد جایزه"}
      />
    </Card>
  );
};
