import { message, Switch } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import * as React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import "./index.scss";
import {
  digitSeparator,
  faNumToEnNum,
} from "../../../../utils/scripts/decimal";
import { SettingGoogleAuthType } from "core/domains";
import { SettingGoogleAuth } from "../google-auth/google-auth";
import { deleteCommaSperator } from "utils/scripts/exchange";

interface DangerousAmountSetting {
  cryptoMaxAmount?: string;
  irtMaxAmount?: string;
  isActive?: boolean;
  count?: number;
}

export const DangerousAmountSettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setPmLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [dangerousAmountSetttings, setDangerousAmountSettings] =
    React.useState<DangerousAmountSetting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();

  const onConfirmHandle = () => {
    setGoogleAuth({
      code: "",
      error: "",
    });
    setModalIsOpen(true);
  };

  const getDangerousAmountSettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.dangerousAmounts).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setDangerousAmountSettings(response.value);
      }
    });
  };

  const onUpdateDangerousAmountsHandler = () => {
    setPmLoading(true);
    put(API.settings.changeDangerousAmounts, {
      cryptoLimit: parseInt(dangerousAmountSetttings?.cryptoMaxAmount || ""),
      irtLimit: parseInt(
        deleteCommaSperator(dangerousAmountSetttings?.irtMaxAmount)
      ),

      isActive: dangerousAmountSetttings?.isActive ?? false,
      googleAuthCode: googleAuth?.code,
      count: dangerousAmountSetttings?.count ?? 10,
    }).then((response: any) => {
      setPmLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getDangerousAmountSettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  React.useEffect(() => {
    getDangerousAmountSettings();
  }, []);

  return (
    <>
      <SettingGoogleAuth
        submitData={onUpdateDangerousAmountsHandler}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <Card
        title="مقادیر حساس"
        blur={cardLoading}
        className="vt-dashboard-dangerous-amount-settings"
      >
        <Card className="delay-item">
          <Input
            label="حداکثر مقدار تومانی"
            type="text"
            name="irt-max-amount"
            value={digitSeparator(dangerousAmountSetttings?.irtMaxAmount || "")}
            onChange={(e) =>
              setDangerousAmountSettings({
                ...dangerousAmountSetttings,
                irtMaxAmount: digitSeparator(faNumToEnNum(e.target.value)),
              })
            }
            dir="ltr"
            maxLength={32}
          />
          <Input
            label="حداکثر تعداد برداشت تومانی"
            type="text"
            name="irt-max-count"
            value={digitSeparator(dangerousAmountSetttings?.count || "")}
            onChange={(e) =>
              setDangerousAmountSettings({
                ...dangerousAmountSetttings,
                count: faNumToEnNum(e.target.value),
              })
            }
            dir="ltr"
            maxLength={32}
          />
          <Input
            label="حداکثر مقدار رمز ارز (به دلار)"
            type="text"
            name="crypto-max-amount"
            value={digitSeparator(
              dangerousAmountSetttings?.cryptoMaxAmount
            )?.toString()}
            onChange={(e) =>
              setDangerousAmountSettings({
                ...dangerousAmountSetttings,
                cryptoMaxAmount: faNumToEnNum(e.target.value),
              })
            }
            dir="ltr"
            maxLength={32}
          />

          <div className="vt-switch-row">
            <label>وضعیت: </label>
            <Switch
              checkedChildren={"فعال"}
              unCheckedChildren={"غیر فعال"}
              checked={dangerousAmountSetttings?.isActive ?? false}
              onChange={(checked) =>
                setDangerousAmountSettings({
                  ...dangerousAmountSetttings,
                  isActive: checked,
                })
              }
            />
          </div>
          <Button
            htmlType="button"
            type="info"
            onClick={() => onConfirmHandle()}
            loading={loading}
            disabled={loading}
            text={"ثبت تغییرات"}
          />
        </Card>
      </Card>
    </>
  );
};
