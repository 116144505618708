export type Enum = {
  id: number;
  title: string;
};

export type OptionalEnum = {
  id?: number;
  title?: string;
};

export interface EnumAsString {
  id: string;
  title: string;
}

export interface PaginationList<T> {
  pagination: Pagination;
  items: T[];
}

export type Pagination = {
  pageSize: number;
  pageIndex: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
};

export enum SortDirection {
  ASC = 0,
  DESC = 1,
}

export interface FilterValues {
  startDate?: string;
  endDate?: string;
  sort?: SortDirection;
  type?: Enum;
  state?: Enum;
  marketType?: Enum;
  market?: string;
  keywords?: string;
  platform?: Enum;
  isCrypto?: boolean;
  depositCurrencyType?: Enum;
  isActive?: number;
}

export interface OrderFilterValues {
  startDate?: string;
  endDate?: string;
  sort?: SortDirection;
  market?: string;
  keywords?: string;
  source?: Enum;
  side: Enum;
  marketType?: Enum;
  platform?: Enum;
}
