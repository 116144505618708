import { message, Switch } from "antd";
import { API } from "apis";
import { Button, Card, Input } from "components";
import { SettingGoogleAuthType } from "core/domains";
import * as React from "react";
import { getViaAuth, put, responseValidator } from "utils/scripts/api";
import "./index.scss";

interface GatewaySetting {
  serviceName?: number;
  apiKey?: string;
  isDepositActive?: boolean;
  isWithdrawActive?: boolean;
  callBackUrl?: string;
}

export const GatewaySettings: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cardLoading, setCardLoading] = React.useState<boolean>(false);
  const [vandarSettings, setVandarSettings] = React.useState<GatewaySetting>();
  const [payStarSettings, setPayStarSettings] =
    React.useState<GatewaySetting>();

  const [zibalSettings, setZibalSettings] = React.useState<GatewaySetting>();
  const [jibimoSettings, setJibimoSettings] = React.useState<GatewaySetting>();
  const [jibitPaymentSettings, setJibitPaymentSettings] =
    React.useState<GatewaySetting>();
  const [nextpayPaymentSettings, setNextpayPaymentSettings] =
    React.useState<GatewaySetting>();
  const [novinpalSettings, setNovinpalSettings] =
    React.useState<GatewaySetting>();
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();
  const [service, setService] = React.useState<number>(1);
  const [gatewayActivation, setGatewayActivation] =
    React.useState<boolean>(false);
  const [gatewayActivationLoading, setGatewayActivationLoading] =
    React.useState<boolean>(false);

  const getGatewaySettings = async () => {
    setCardLoading(true);
    await getViaAuth(API.settings.gateways).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status) && response.value.items) {
        setVandarSettings(
          response.value.items.filter(
            (s: GatewaySetting) => s.serviceName === 2
          )[0]
        );
        setZibalSettings(
          response.value.items.filter(
            (s: GatewaySetting) => s.serviceName === 1
          )[0]
        );
        setJibimoSettings(
          response.value.items.filter(
            (s: GatewaySetting) => s.serviceName === 3
          )[0]
        );
        setJibitPaymentSettings(
          response.value.items.filter(
            (s: GatewaySetting) => s.serviceName === 4
          )[0]
        );
        setNextpayPaymentSettings(
          response.value.items.filter(
            (s: GatewaySetting) => s.serviceName === 5
          )[0]
        );
        setPayStarSettings(
          response.value.items.filter(
            (s: GatewaySetting) => s.serviceName === 6
          )[0]
        );
        setNovinpalSettings(
          response.value.items.filter(
            (s: GatewaySetting) => s.serviceName === 7
          )[0]
        );

        setGatewayActivation(response.value.isActive);
      }
    });
  };

  const onConfirmHandler = (serviceNumber: number) => {
    setService(serviceNumber);
    setLoading(true);

    let apiKey;
    let isDepositActive;
    let isWithdrawActive;
    let callBackUrl;
    switch (serviceNumber) {
      case 1:
        apiKey = zibalSettings?.apiKey;
        isDepositActive = zibalSettings?.isDepositActive;
        isWithdrawActive = zibalSettings?.isWithdrawActive;
        callBackUrl = zibalSettings?.callBackUrl;
        break;
      case 2:
        apiKey = vandarSettings?.apiKey;
        isDepositActive = vandarSettings?.isDepositActive;
        isWithdrawActive = vandarSettings?.isWithdrawActive;
        callBackUrl = vandarSettings?.callBackUrl;
        break;
      case 3:
        apiKey = jibimoSettings?.apiKey;
        isDepositActive = jibimoSettings?.isDepositActive;
        isWithdrawActive = jibimoSettings?.isWithdrawActive;
        callBackUrl = jibimoSettings?.callBackUrl;
        break;
      case 4:
        apiKey = jibitPaymentSettings?.apiKey;
        isDepositActive = jibitPaymentSettings?.isDepositActive;
        isWithdrawActive = jibitPaymentSettings?.isWithdrawActive;
        callBackUrl = jibitPaymentSettings?.callBackUrl;
        break;
      case 5:
        apiKey = nextpayPaymentSettings?.apiKey;
        isDepositActive = nextpayPaymentSettings?.isDepositActive;
        isWithdrawActive = nextpayPaymentSettings?.isWithdrawActive;
        callBackUrl = nextpayPaymentSettings?.callBackUrl;
        break;
      case 6:
        apiKey = payStarSettings?.apiKey;
        isDepositActive = payStarSettings?.isDepositActive;
        isWithdrawActive = payStarSettings?.isWithdrawActive;
        callBackUrl = payStarSettings?.callBackUrl;
        break;
      case 7:
        apiKey = novinpalSettings?.apiKey;
        isDepositActive = novinpalSettings?.isDepositActive;
        isWithdrawActive = novinpalSettings?.isWithdrawActive;
        callBackUrl = novinpalSettings?.callBackUrl;
        break;
      default:
        setLoading(false);
        return;
    }
    put(API.settings.changeGateway, {
      serviceName: serviceNumber,
      apiKey: apiKey,
      callBackUrl: callBackUrl,
      isDepositActive: isDepositActive ? isDepositActive : false,
      isWithdrawActive: isWithdrawActive ? isWithdrawActive : false,
      // googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setModalIsOpen(false);
        message.success(response.data.message);
        getGatewaySettings();
      } else {
        setGoogleAuth({
          ...googleAuth,
          error: response.data.message,
        });
      }
    });
  };

  const onGatewayIsActiveChange = async (value: boolean) => {
    setGatewayActivationLoading(true);
    await put(`${API.settings.updateGatewaySettingsActivate}`, {
      isActive: value,
    }).then((response: any) => {
      setGatewayActivationLoading(false);
      if (responseValidator(response.status)) {
        setGatewayActivation(value);
        message.success("وضعیت واریز درگاه با موفقیت تغییر یافت.");
      }
    });
  };

  React.useEffect(() => {
    getGatewaySettings();
  }, []);

  return (
    <>
      <div className={"gateway-header"}>
        <label>وضعیت واریز از درگاه:</label>
        <Switch
          loading={gatewayActivationLoading}
          checked={gatewayActivation}
          onChange={onGatewayIsActiveChange}
          checkedChildren="فعال"
          unCheckedChildren="غیر فعال"
        />
      </div>
      <Card
        blur={cardLoading || loading}
        className="vt-dashboard-gateway-settings"
      >
        <Card title={`تنظیمات درگاه وندار`} className="gateway-item">
          <Input
            label="کلید وب سرویس"
            type="text"
            name="apiKey"
            value={vandarSettings?.apiKey}
            onChange={(e) =>
              setVandarSettings({ ...vandarSettings, apiKey: e.target.value })
            }
            dir="ltr"
            maxLength={80}
          />
          <Input
            label="آدرس بازگشت وب سرویس"
            type="text"
            name="callBackUrl"
            value={vandarSettings?.callBackUrl}
            onChange={(e) =>
              setVandarSettings({
                ...vandarSettings,
                callBackUrl: e.target.value,
              })
            }
            dir="ltr"
            maxLength={280}
          />
          <div className="switches">
            <Switch
              checkedChildren={"برداشت فعال"}
              unCheckedChildren={"برداشت غیر فعال"}
              checked={vandarSettings?.isWithdrawActive}
              onChange={(checked) =>
                setVandarSettings({
                  ...vandarSettings,
                  isWithdrawActive: checked,
                })
              }
            />
            <Switch
              checkedChildren={"واریز فعال"}
              unCheckedChildren={"واریز غیر فعال"}
              checked={vandarSettings?.isDepositActive}
              onChange={(checked) =>
                setVandarSettings({
                  ...vandarSettings,
                  isDepositActive: checked,
                })
              }
            />
          </div>

          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandler(vandarSettings?.serviceName || 2)}
            disabled={loading}
          />
        </Card>
        <Card title={`تنظیمات درگاه زیبال`} className="gateway-item">
          <Input
            label="کلید وب سرویس"
            type="text"
            name="apiKey"
            value={zibalSettings?.apiKey}
            onChange={(e) =>
              setZibalSettings({ ...zibalSettings, apiKey: e.target.value })
            }
            dir="ltr"
            maxLength={80}
          />
          <Input
            label="آدرس بازگشت وب سرویس"
            type="text"
            name="callBackUrl"
            value={zibalSettings?.callBackUrl}
            onChange={(e) =>
              setZibalSettings({
                ...zibalSettings,
                callBackUrl: e.target.value,
              })
            }
            dir="ltr"
            maxLength={280}
          />
          <div className="switches">
            <Switch
              checkedChildren={"برداشت فعال"}
              unCheckedChildren={"برداشت غیر فعال"}
              checked={zibalSettings?.isWithdrawActive}
              onChange={(checked) =>
                setZibalSettings({
                  ...zibalSettings,
                  isWithdrawActive: checked,
                })
              }
            />

            <Switch
              checkedChildren={"واریز فعال"}
              unCheckedChildren={"واریز غیر فعال"}
              checked={zibalSettings?.isDepositActive}
              onChange={(checked) =>
                setZibalSettings({
                  ...zibalSettings,
                  isDepositActive: checked,
                })
              }
            />
          </div>
          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandler(zibalSettings?.serviceName || 0)}
            disabled={loading}
          />
        </Card>
        <Card title={`تنظیمات درگاه پی استار`} className="gateway-item">
          <Input
            label="کلید وب سرویس"
            type="text"
            name="apiKey"
            value={payStarSettings?.apiKey}
            onChange={(e) =>
              setPayStarSettings({ ...payStarSettings, apiKey: e.target.value })
            }
            dir="ltr"
            maxLength={80}
          />
          <Input
            label="آدرس بازگشت وب سرویس"
            type="text"
            name="callBackUrl"
            value={payStarSettings?.callBackUrl}
            onChange={(e) =>
              setPayStarSettings({
                ...payStarSettings,
                callBackUrl: e.target.value,
              })
            }
            dir="ltr"
            maxLength={280}
          />
          <div className="switches">
            <Switch
              checkedChildren={"برداشت فعال"}
              unCheckedChildren={"برداشت غیر فعال"}
              checked={payStarSettings?.isWithdrawActive}
              onChange={(checked) =>
                setPayStarSettings({
                  ...payStarSettings,
                  isWithdrawActive: checked,
                })
              }
            />

            <Switch
              checkedChildren={"واریز فعال"}
              unCheckedChildren={"واریز غیر فعال"}
              checked={payStarSettings?.isDepositActive}
              onChange={(checked) =>
                setPayStarSettings({
                  ...payStarSettings,
                  isDepositActive: checked,
                })
              }
            />
          </div>
          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandler(payStarSettings?.serviceName || 6)}
            disabled={loading}
          />
        </Card>
        <Card title={`تنظیمات درگاه جیبیمو`} className="gateway-item">
          <Input
            label="کلید وب سرویس"
            type="text"
            name="apiKey"
            value={jibimoSettings?.apiKey}
            onChange={(e) =>
              setJibimoSettings({ ...jibimoSettings, apiKey: e.target.value })
            }
            dir="ltr"
            maxLength={80}
          />
          <Input
            label="آدرس بازگشت وب سرویس"
            type="text"
            name="callBackUrl"
            value={jibimoSettings?.callBackUrl}
            onChange={(e) =>
              setJibimoSettings({
                ...jibimoSettings,
                callBackUrl: e.target.value,
              })
            }
            dir="ltr"
            maxLength={280}
          />
          <div className="switches">
            <Switch
              checkedChildren={"برداشت فعال"}
              unCheckedChildren={"برداشت غیر فعال"}
              checked={jibimoSettings?.isWithdrawActive}
              onChange={(checked) =>
                setJibimoSettings({
                  ...jibimoSettings,
                  isWithdrawActive: checked,
                })
              }
            />

            <Switch
              checkedChildren={"واریز فعال"}
              unCheckedChildren={"واریز غیر فعال"}
              checked={jibimoSettings?.isDepositActive}
              onChange={(checked) =>
                setJibimoSettings({
                  ...jibimoSettings,
                  isDepositActive: checked,
                })
              }
            />
          </div>
          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandler(jibimoSettings?.serviceName || 0)}
            disabled={loading}
          />
        </Card>
        <Card title={`تنظیمات درگاه جیبیت`} className="gateway-item">
          <Input
            label="کلید وب سرویس"
            type="text"
            name="apiKey"
            value={jibitPaymentSettings?.apiKey}
            onChange={(e) =>
              setJibitPaymentSettings({
                ...jibitPaymentSettings,
                apiKey: e.target.value,
              })
            }
            dir="ltr"
            maxLength={80}
          />
          <Input
            label="آدرس بازگشت وب سرویس"
            type="text"
            name="callBackUrl"
            value={jibitPaymentSettings?.callBackUrl}
            onChange={(e) =>
              setJibitPaymentSettings({
                ...jibitPaymentSettings,
                callBackUrl: e.target.value,
              })
            }
            dir="ltr"
            maxLength={280}
          />
          <div className="switches">
            <Switch
              checkedChildren={"برداشت فعال"}
              unCheckedChildren={"برداشت غیر فعال"}
              checked={jibitPaymentSettings?.isWithdrawActive}
              onChange={(checked) =>
                setJibitPaymentSettings({
                  ...jibitPaymentSettings,
                  isWithdrawActive: checked,
                })
              }
            />

            <Switch
              checkedChildren={"واریز فعال"}
              unCheckedChildren={"واریز غیر فعال"}
              checked={jibitPaymentSettings?.isDepositActive}
              onChange={(checked) =>
                setJibitPaymentSettings({
                  ...jibitPaymentSettings,
                  isDepositActive: checked,
                })
              }
            />
          </div>
          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() =>
              onConfirmHandler(jibitPaymentSettings?.serviceName || 4)
            }
            disabled={loading}
          />
        </Card>
        <Card title={`تنظیمات درگاه نکست پی`} className="gateway-item">
          <Input
            label="کلید وب سرویس"
            type="text"
            name="apiKey"
            value={nextpayPaymentSettings?.apiKey}
            onChange={(e) =>
              setNextpayPaymentSettings({
                ...nextpayPaymentSettings,
                apiKey: e.target.value,
              })
            }
            dir="ltr"
            maxLength={80}
          />
          <Input
            label="آدرس بازگشت وب سرویس"
            type="text"
            name="callBackUrl"
            value={nextpayPaymentSettings?.callBackUrl}
            onChange={(e) =>
              setNextpayPaymentSettings({
                ...nextpayPaymentSettings,
                callBackUrl: e.target.value,
              })
            }
            dir="ltr"
            maxLength={280}
          />
          <div className="switches">
            <Switch
              checkedChildren={"برداشت فعال"}
              unCheckedChildren={"برداشت غیر فعال"}
              checked={nextpayPaymentSettings?.isWithdrawActive}
              onChange={(checked) =>
                setNextpayPaymentSettings({
                  ...nextpayPaymentSettings,
                  isWithdrawActive: checked,
                })
              }
            />

            <Switch
              checkedChildren={"واریز فعال"}
              unCheckedChildren={"واریز غیر فعال"}
              checked={nextpayPaymentSettings?.isDepositActive}
              onChange={(checked) =>
                setNextpayPaymentSettings({
                  ...nextpayPaymentSettings,
                  isDepositActive: checked,
                })
              }
            />
          </div>
          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() =>
              onConfirmHandler(nextpayPaymentSettings?.serviceName || 5)
            }
            disabled={loading}
          />
        </Card>
        <Card title={`تنظیمات درگاه نوین پال`} className="gateway-item">
          <Input
            label="کلید وب سرویس"
            type="text"
            name="apiKey"
            value={novinpalSettings?.apiKey}
            onChange={(e) =>
              setNovinpalSettings({
                ...novinpalSettings,
                apiKey: e.target.value,
              })
            }
            dir="ltr"
            maxLength={80}
          />
          <Input
            label="آدرس بازگشت وب سرویس"
            type="text"
            name="callBackUrl"
            value={novinpalSettings?.callBackUrl}
            onChange={(e) =>
              setNovinpalSettings({
                ...novinpalSettings,
                callBackUrl: e.target.value,
              })
            }
            dir="ltr"
            maxLength={280}
          />
          <div className="switches">
            <Switch
              checkedChildren={"برداشت فعال"}
              unCheckedChildren={"برداشت غیر فعال"}
              checked={novinpalSettings?.isWithdrawActive}
              onChange={(checked) =>
                setNovinpalSettings({
                  ...novinpalSettings,
                  isWithdrawActive: checked,
                })
              }
            />

            <Switch
              checkedChildren={"واریز فعال"}
              unCheckedChildren={"واریز غیر فعال"}
              checked={novinpalSettings?.isDepositActive}
              onChange={(checked) =>
                setNovinpalSettings({
                  ...novinpalSettings,
                  isDepositActive: checked,
                })
              }
            />
          </div>
          <Button
            htmlType="button"
            type="info"
            text="ثبت تغییرات"
            onClick={() => onConfirmHandler(novinpalSettings?.serviceName || 7)}
            disabled={loading}
          />
        </Card>
      </Card>
    </>
  );
};
