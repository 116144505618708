import { Dispatch, SetStateAction } from "react";
import {
  AddPromocodeBody,
  EditPromocodeBody,
  Promocode,
} from "../domains/promocode/promocode";
import {
  del,
  getViaAuth,
  postViaAuth,
  put,
  responseValidator,
} from "../../utils/scripts/api";
import { API } from "../../apis";
import { PaginationList } from "../domains";

export const getAllPromocodes = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  setData: Dispatch<SetStateAction<PaginationList<Promocode> | undefined>>,
  page: number,
  pageSize: number,
  query: string
) => {
  setLoading(true);
  getViaAuth(`${API.promocode.getAll}/${page}/${pageSize}${query}`).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setData(response.value);
      }
    }
  );
};

export const getPromocodeById = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  id: string,
  setData: Dispatch<SetStateAction<any>>
) => {
  setLoading(true);
  getViaAuth(`${API.promocode.getById}/${id}`).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      setData(response.value);
    }
  });
};

export const addPromoCode = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  data: AddPromocodeBody,
  onComplete?: () => void
) => {
  setLoading(true);
  await postViaAuth(API.promocode.add, data).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      onComplete && onComplete();
    }
  });
};

export const editPromocode = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  data: EditPromocodeBody,
  onComplete?: () => void
) => {
  setLoading(true);
  put(API.promocode.edit, data).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      onComplete && onComplete();
    }
  });
};

export const removePromoCode = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  id: string,
  onComplete?: () => void
) => {
  setLoading(true);
  await del(API.promocode.remove, { id: id }).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      onComplete && onComplete();
    }
  });
};
