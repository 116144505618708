import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
  SettingTwoTone,
} from "@ant-design/icons";
import { Divider, Dropdown, Menu, message, Row, Select, Switch } from "antd";
import { __API } from "apis/api";
import { Button, Card, Input } from "components";

import * as React from "react";
import { Fragment } from "react";
import { get, getViaAuth, put, responseValidator } from "utils/scripts/api";
import "./index.scss";
import { Enum, EnumAsString, Level, SettingGoogleAuthType } from "core/domains";
import { SettingGoogleAuth } from "./google-auth/google-auth";
import { getAlllLevels } from "../../../core/repos/level";
import { API } from "../../../apis";
import {
  deleteCommaSeparator,
  digitSeparator,
  faNumToEnNum,
} from "../../../utils/scripts/decimal";

const { useState, useRef, useEffect } = React;

interface CardToCardListType {
  service?: Enum;
  cardNumber?: string;
  ownerName?: string;
  bankName?: string;
  isActive?: boolean;
}

interface CardToCardSetting {
  isActive: boolean;
  items: CardToCardListType[];
}

interface CashInSetting {
  isActive?: boolean;
  cashInItemSettings?: CashInItemSettings[];
  minimumLevelForCashIn?: number;
  hour?: number | string;
  amount?: number | string;
}

interface OfflineDepositBankAccount {
  bankName: string;
  iBan: string;
  bankAccountNumber: string;
  cardNumber: string;
  isDepositActive: boolean;
  ownerName: string;
}

interface CashInItemSettings {
  bankAccounts: CashInBankAccount[];
  mode: number;
}

interface CashInBankAccount {
  isDepositActive: boolean;
  iBan: string;
  accountNumber: string;
  bank: EnumAsString;
  bankAccountOwner: string;
  isDefault: boolean;
}

export const CashInSettings: React.FC = () => {
  // States
  const [cardLoading, setCardLoading] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [dropDownLoading, setDropDownLoading] = useState<boolean>(false);
  const [minimumDepositAmountLoading, setMinimumDepositAmountLoading] =
    useState<boolean>(false);

  const [
    offlineDepositLevelDropdownLoading,
    setOfflineDepositLevelDropdownLoading,
  ] = useState<boolean>(false);

  const [loadingCashInActivation, setLoadingCashInActivation] =
    useState<boolean>(false);
  const [banks, setBanks] = useState<any>();

  const [levelList, setLevelList] = React.useState<Level[]>([]);

  const [cashInModes, setCashInModes] = useState<Enum[]>();
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
  const [googleAuth, setGoogleAuth] = React.useState<SettingGoogleAuthType>();
  const [selectedItem, setSelectedItem] = useState<CashInItemSettings>();

  const [cashInSettingsList, setCashInSettingsList] = useState<CashInSetting>({
    cashInItemSettings: [],
    isActive: false,
    minimumLevelForCashIn: 3,
    hour: 0,
    amount: 0,
  });

  const [offlineDepositSettings, setOfflineDepositSettings] = useState<{
    minimumDepositAmount: number;
    minimumLevelForOfflineDeposit: number;
    bankAccount: OfflineDepositBankAccount[];
    isActive: boolean;
  }>({
    minimumDepositAmount: 0,
    minimumLevelForOfflineDeposit: 0,
    bankAccount: [
      {
        bankName: "",
        iBan: "",
        bankAccountNumber: "",
        cardNumber: "",
        isDepositActive: false,
        ownerName: "",
      },
    ],
    isActive: false,
  });

  const [cardToCardList, setCardToCardList] = useState<CardToCardListType[]>(
    []
  );

  const [pendingOfflineDepositChanges, setPendingOfflineDepositChanges] =
    useState<OfflineDepositBankAccount[]>([]);

  useEffect(() => {
    if (offlineDepositSettings?.bankAccount?.length > 0) {
      setPendingOfflineDepositChanges([...offlineDepositSettings.bankAccount]);
    } else {
      setPendingOfflineDepositChanges([]);
    }
  }, [offlineDepositSettings]);

  const [loadingOfflineDeposit, setLoadingOfflineDeposit] =
    useState<boolean>(false);
  const [loadingCardToCardDeposit, setLoadingCardToCardDeposit] =
    useState<boolean>(false);
  const [offlineDepositActivationLoading, setOfflineDepositActivationLoading] =
    useState<boolean>(false);
  const [cardToCardActivationLoading, setCardToCardActivationLoading] =
    useState<boolean>(false);
  const [offlineDepositModalOpen, setOfflineDepositModalOpen] =
    useState<boolean>(false);
  const [cardToCardModalOpen, setCardToCardModalOpen] =
    useState<boolean>(false);

  const [cardToCardSettingIsActive, setCardToCardSettingIsActive] =
    useState<boolean>(false);

  const [serviceSettings, setServiceSettings] = useState<{
    [key: string]: CardToCardListType | undefined;
  }>({
    vandar: undefined,
    paystar: undefined,
    jibit: undefined,
    manualAyande: undefined,
  });

  const onAddBankAccountHandle = (newItemMode: number) => {
    const selectedCashInItem = cashInSettingsList.cashInItemSettings?.find(
      (cashInItem) => cashInItem.mode === newItemMode
    );
    const otherCashInItems = cashInSettingsList.cashInItemSettings?.filter(
      (cashInItem) => cashInItem.mode !== newItemMode
    );

    var newEmptyItem = {
      accountNumber: "",
      bank: {
        id: "choose",
        title: "انتخاب بانک",
      },
      bankAccountOwner: "",
      iBan: "",
      isDepositActive: false,
      isDefault: false,
    };

    selectedCashInItem?.bankAccounts &&
      selectedCashInItem?.bankAccounts.push(newEmptyItem);

    selectedCashInItem?.bankAccounts &&
      otherCashInItems?.push({
        mode: newItemMode,
        bankAccounts: selectedCashInItem?.bankAccounts,
      });

    setCashInSettingsList({
      cashInItemSettings: otherCashInItems?.sort((a, b) => a.mode - b.mode),
      isActive: cashInSettingsList.isActive,
      minimumLevelForCashIn: cashInSettingsList.minimumLevelForCashIn,
    });
  };
  const onChangeMinLevelHandler = (level: number) => {
    setDropDownLoading(true);
    put(API.settings.changeCashInMinLevel, {
      MinimumLevel: level,
    }).then((response: any) => {
      if (responseValidator(response.status)) {
        setDropDownLoading(false);
        message.success("تنظیمات مورد نظر با موفقیت ویرایش شد.");
      }
    });
  };

  const onChangeOfflineDepositMinLevelHandler = (level: number) => {
    setOfflineDepositLevelDropdownLoading(true);
    put(API.settings.changeOfflineDepositMinLevel, {
      minimumLevel: level,
    }).then((response: any) => {
      if (responseValidator(response.status)) {
        setOfflineDepositLevelDropdownLoading(false);
        message.success("تنظیمات مورد نظر با موفقیت ویرایش شد.");
      }
    });
  };

  const onChangeHandle = (
    newItemMode: number,
    id: number,
    field: string,
    value: string | boolean
  ) => {
    var newData: CashInSetting | undefined;

    var copyOfCashinSettings = cashInSettingsList;
    var copyOfCashinItems = cashInSettingsList.cashInItemSettings;

    var selectedCashInItem = copyOfCashinItems?.find(
      (cashInItem) => cashInItem.mode === newItemMode
    );

    var otherCashinItems = copyOfCashinItems?.filter(
      (cashinItem) => cashinItem.mode !== newItemMode
    );

    var otherBankAccounts = selectedCashInItem?.bankAccounts?.filter(
      (_, index) => index !== id
    );

    var selectedBankAccount =
      selectedCashInItem?.bankAccounts && selectedCashInItem?.bankAccounts[id];

    var newInputData: CashInBankAccount | undefined = selectedBankAccount && {
      ...selectedBankAccount,
      [field]: value,
    };

    newInputData && otherBankAccounts?.push(newInputData);

    selectedCashInItem = {
      mode: selectedCashInItem?.mode,
      bankAccounts: otherBankAccounts?.sort((a, b) =>
        a.bankAccountOwner > b.bankAccountOwner ? -1 : 1
      ),
    } as CashInItemSettings;

    selectedCashInItem && otherCashinItems?.push(selectedCashInItem);

    newData = {
      cashInItemSettings: otherCashinItems?.sort((a, b) => a.mode - b.mode),
      isActive: copyOfCashinSettings.isActive,
      minimumLevelForCashIn: copyOfCashinSettings.minimumLevelForCashIn,
    };

    setCashInSettingsList(newData);
  };

  const onRemoveHandle = (newItemMode: number, id: number) => {
    var newData: CashInSetting | undefined;

    var copyOfCashinSettings = cashInSettingsList;
    var copyOfCashinItems = cashInSettingsList.cashInItemSettings;

    var selectedCashInItem = copyOfCashinItems?.find(
      (cashInItem) => cashInItem.mode === newItemMode
    );

    var otherCashinItems = copyOfCashinItems?.filter(
      (cashinItem) => cashinItem.mode !== newItemMode
    );

    var otherBankAccounts = selectedCashInItem?.bankAccounts?.filter(
      (_, index) => index !== id
    );

    selectedCashInItem = {
      mode: selectedCashInItem?.mode,
      bankAccounts: otherBankAccounts,
    } as CashInItemSettings;

    selectedCashInItem && otherCashinItems?.push(selectedCashInItem);

    newData = {
      cashInItemSettings: otherCashinItems?.sort((a, b) => a.mode - b.mode),
      isActive: copyOfCashinSettings.isActive,
      minimumLevelForCashIn: copyOfCashinSettings.minimumLevelForCashIn,
    };

    setCashInSettingsList(newData);
  };

  const onIsActiveChangeHandler = async (value: boolean) => {
    await updateCashInSettingActivation(value);

    setCashInSettingsList({ ...cashInSettingsList, isActive: value });
  };

  const onOfflineDepositChangeHandle = (
    field: string,
    value: string | boolean | { id: string; title: string },
    index: number
  ) => {
    const updatedBankAccounts = [...pendingOfflineDepositChanges];

    if (typeof value === "object" && value !== null && "title" in value) {
      if (field === "bank") {
        updatedBankAccounts[index] = {
          ...updatedBankAccounts[index],
          bankName: value.title,
        };
      }
    } else {
      updatedBankAccounts[index] = {
        ...updatedBankAccounts[index],
        [field]: value,
      };
    }

    setPendingOfflineDepositChanges(updatedBankAccounts);
  };

  const openOfflineDepositGoogleAuthModal = () => {
    setOfflineDepositModalOpen(true);
  };

  const openCardToCardDepositGoogleAuthModal = (
    _selectedService: CardToCardListType | undefined
  ) => {
    setCardToCardModalOpen(true);
  };

  const confirmOfflineDepositChanges = async (googleAuthCode: string) => {
    setLoadingOfflineDeposit(true);
    await put(`${__API.settings.changeOfflineDepositSettings}`, {
      bankAccounts: pendingOfflineDepositChanges,
      googleAuthCode,
    }).then((response: any) => {
      setLoadingOfflineDeposit(false);
      if (responseValidator(response.status)) {
        message.success("تنظیمات مورد نظر با موفقیت ویرایش شد.");
        setOfflineDepositModalOpen(false);
      }
    });
  };

  const confirmCardToCardDepositChanges = async (googleAuthCode: string) => {
    setLoadingOfflineDeposit(true);

    const body = {
      cardToCardItems: Object.entries(serviceSettings)
        .map(([key, value]) => {
          if (value && value.service) {
            return {
              service: value.service.id,
              cardNumber: value.cardNumber || "",
              ownerName: value.ownerName || "",
              bankName: value.bankName || "",
              isActive: value.isActive || false,
            };
          }
          return null; // Return null if the service is not available
        })
        .filter((item) => item !== null), // Filter out any null values
      googleAuthCode,
    };

    await put(`${__API.settings.updateCardToCardSettings}`, body).then(
      (response: any) => {
        setLoadingOfflineDeposit(false);
        if (responseValidator(response.status)) {
          message.success("تنظیمات مورد نظر با موفقیت ویرایش شد.");
        }
        setCardToCardModalOpen(false);
      }
    );
  };

  const getOfflineDepositSettings = async () => {
    setCardLoading(true);
    await getViaAuth<{
      minimumDepositAmount: number;
      minimumLevelForOfflineDeposit: number;
      bankAccounts: OfflineDepositBankAccount[];
      isActive: boolean;
    }>(__API.settings.offlineDepositSettings).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setOfflineDepositSettings(response.value);
      }
    });
  };

  const onOfflineDepositIsActiveChange = async (value: boolean) => {
    setOfflineDepositActivationLoading(true);
    await put(`${__API.settings.changeOfflineDepositSettingsActivation}`, {
      isActive: value,
    }).then((response: any) => {
      setOfflineDepositActivationLoading(false);
      if (responseValidator(response.status)) {
        setOfflineDepositSettings({
          ...offlineDepositSettings,
          isActive: value,
        });
        message.success("وضعیت واریز آفلاین با موفقیت تغییر یافت.");
      }
    });
  };

  const onCardToCardDepositIsActiveChange = async (value: boolean) => {
    setCardToCardActivationLoading(true);
    await put(`${__API.settings.changeCardToCardActivationSettings}`, {
      isActive: value,
    }).then((response: any) => {
      setCardToCardActivationLoading(false);
      if (responseValidator(response.status)) {
        setCardToCardSettingIsActive(value);
        message.success("وضعیت واریز کارت به کارت با موفقیت تغییر یافت.");
      }
    });
  };

  const onAddOfflineBankAccountHandle = () => {
    const newBankAccount: OfflineDepositBankAccount = {
      bankName: "",
      iBan: "",
      bankAccountNumber: "",
      cardNumber: "",
      isDepositActive: false,
      ownerName: "",
    };

    setPendingOfflineDepositChanges([
      ...pendingOfflineDepositChanges,
      newBankAccount,
    ]);
  };

  const onRemoveOfflineBankAccountHandle = (accountIndex: number) => {
    const updatedBankAccounts = pendingOfflineDepositChanges.filter(
      (_, idx) => idx !== accountIndex
    );

    setPendingOfflineDepositChanges(updatedBankAccounts);
  };

  const getAllCashInSettings = async () => {
    var data: CashInSetting = {};
    setCardLoading(true);
    await getViaAuth<CashInSetting>(__API.settings.cashInSettings).then(
      (response: any) => {
        setCardLoading(false);
        if (responseValidator(response.status)) {
          Object.assign(data, {
            ...response.value,

            cashInItemSettings: response.value.cashInItemSettings.map(
              (ci: CashInItemSettings) => ({
                ...ci,
                bankAccounts: ci.bankAccounts.map((account: any) => ({
                  ...account,
                  bank: {
                    id: account.bank,
                    title: banks?.find(
                      (e: any) =>
                        e.id.toLowerCase() == account.bank.toLowerCase()
                    )?.title,
                  },
                })),
              })
            ),
          });

          setCashInSettingsList(data);
        }
      }
    );
  };

  const getAllBanks = async () => {
    setCardLoading(true);
    await get(__API.banks.allBanks + `/${1}/50`).then((response: any) => {
      setCardLoading(false);
      var arr: any = [];
      if (responseValidator(response.status)) {
        response.data.value.items.forEach((e: any) =>
          arr.push({
            id: e.englishName,
            title: e.name,
          })
        );
        setBanks(arr);
      }
    });
  };

  const getAllCashInSettingsModes = async () => {
    setCardLoading(true);
    await get(__API.enums.cashinModes).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setCashInModes(response.data.value);
      }
    });
  };

  const updateCashInSetting = async (googleAuthCode: string) => {
    setLoading(true);
    await put(`${__API.settings.changeCashInSettings}`, {
      hour: Number(cashInSettingsList.hour),
      amount: Number(cashInSettingsList.amount),
      mode: selectedItem?.mode,
      bankAccounts: selectedItem?.bankAccounts.map((e) => ({
        ...e,
        bank: e.bank.id,
      })),
      googleAuthCode: googleAuthCode,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        getAllCashInSettings();
        message.success("تنظیمات مورد نظر با موفقیت ویرایش شد.");
        setModalIsOpen(false);
        setGoogleAuth({
          code: "",
        });
      }
    });
  };

  const updateCashInSettingActivation = async (value: boolean) => {
    setLoadingCashInActivation(true);
    await put(`${__API.settings.changeCashInSettingsActivation}`, {
      isActive: value,
    }).then((response: any) => {
      setLoadingCashInActivation(false);
      if (responseValidator(response.status)) {
        getAllCashInSettings();
        message.success("تنظیمات مورد نظر با موفقیت ویرایش شد.");
      }
    });
  };

  const openGoogleAuthModal = (item: CashInItemSettings) => {
    setModalIsOpen(true);
    setSelectedItem(item);
  };

  const onMinimumDepositAmountSubmit = async () => {
    setMinimumDepositAmountLoading(true);
    await put(API.settings.changeOfflineDepositMinDeposit, {
      minimumDepositAmount: Number(
        deleteCommaSeparator(offlineDepositSettings.minimumDepositAmount)
      ),
    }).then((response: any) => {
      setMinimumDepositAmountLoading(false);
      if (responseValidator(response.status)) {
        message.success("تنظیمات مورد نظر با موفقیت ویرایش شد.");
      }
    });
  };

  useEffect(() => {
    levelList.length === 0 && getAlllLevels(setCardLoading, setLevelList);

    getAllBanks();
  }, []);

  useEffect(() => {
    if (banks && banks.length > 0) {
      getAllCashInSettingsModes();
      getAllCashInSettings();
      getOfflineDepositSettings();
      getCardToCardSettings();
    }
  }, [banks]);

  const getCardToCardSettings = async () => {
    setCardLoading(true);
    await getViaAuth<CardToCardListType[]>(
      API.settings.cardToCardSettings
    ).then((response: any) => {
      setCardLoading(false);
      if (responseValidator(response.status)) {
        setCardToCardList(response.value.items);
        setCardToCardSettingIsActive(response.value.isActive);

        const settings = response.value.items.reduce((acc: any, s: any) => {
          acc[s.service.id] = s;
          return acc;
        }, {});
        console.log(settings);
        setServiceSettings(settings);
      }
    });
  };

  const onFieldChange = (
    serviceId: string,
    fieldName: string,
    value: string
  ) => {
    setServiceSettings((prev) => ({
      ...prev,
      [serviceId]: {
        ...prev[serviceId],
        [fieldName]: value,
      },
    }));
  };
  ``;

  const renderServiceSettings = (service: string, title: string) => {
    const setting = serviceSettings[service];
    return (
      setting && (
        <div className={"cash-in-setting-item-card"} key={service}>
          <Row>
            <div className="cash-in-setting-info">
              <SettingTwoTone />
              <span>{title}</span>
            </div>
            <div className={"bank-accounts"}>
              <div className={"bank-item"}>
                <Input
                  type={"text"}
                  name="cardNumber"
                  label="شماره کارت"
                  maxLength={16}
                  placeholder={"شماره کارت را وارد کنید"}
                  value={setting.cardNumber}
                  onChange={(e) =>
                    onFieldChange(
                      service,
                      "cardNumber",
                      faNumToEnNum(e.target.value)
                    )
                  }
                />
                <Input
                  type={"text"}
                  name="bankAccountOwner"
                  label="نام صاحب حساب"
                  placeholder={"نام صاحب حساب را وارد کنید"}
                  value={setting.ownerName}
                  onChange={(e) =>
                    onFieldChange(service, "ownerName", e.target.value)
                  }
                />
                <Input
                  type={"text"}
                  name="bankName"
                  label="نام بانک"
                  placeholder={"نام بانک وارد کنید"}
                  value={setting.bankName}
                  onChange={(e) =>
                    onFieldChange(service, "bankName", e.target.value)
                  }
                />
                <div className={"vt-switch-row"}>
                  <label>وضعیت :</label>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={setting.isActive}
                    onChange={(checked) =>
                      setServiceSettings((prev) => ({
                        ...prev,
                        [service]: {
                          ...prev[service],
                          isActive: checked,
                        },
                      }))
                    }
                  />
                </div>
                <Button
                  onClick={() =>
                    openCardToCardDepositGoogleAuthModal(
                      serviceSettings[service]
                    )
                  }
                  text="ویرایش تنظیمات"
                  type={"info"}
                  htmlType="button"
                  loading={loadingOfflineDeposit}
                  disabled={
                    loadingCardToCardDeposit ||
                    setting?.cardNumber?.length !== 16 ||
                    setting.ownerName === ""
                  }
                />
              </div>
            </div>
          </Row>
        </div>
      )
    );
  };

  return (
    <>
      <SettingGoogleAuth
        submitData={updateCashInSetting}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        loading={loading}
      />
      <SettingGoogleAuth
        submitData={confirmOfflineDepositChanges}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={offlineDepositModalOpen}
        setIsOpen={setOfflineDepositModalOpen}
        loading={loadingOfflineDeposit}
      />
      <SettingGoogleAuth
        submitData={confirmCardToCardDepositChanges}
        data={googleAuth}
        setData={setGoogleAuth}
        isOpen={cardToCardModalOpen}
        setIsOpen={setCardToCardModalOpen}
        loading={loadingOfflineDeposit}
        additionalData={serviceSettings}
      />
      <Card blur={cardLoading} className="vt-dashboard-cash-in-settings">
        <h3>تنظیمات واریز شناسه دار</h3>
        <Row className="title">
          <div>
            <h4>وضعیت واریز شناسه دار</h4>

            <div className="vt-switch-row">
              <Switch
                title="وضعیت"
                checked={cashInSettingsList.isActive}
                checkedChildren="فعال"
                unCheckedChildren="غیر فعال"
                loading={loadingCashInActivation}
                onChange={(e) => onIsActiveChangeHandler(e)}
              />
            </div>
          </div>
          <div>
            <label>حداقل سطح کاربری : </label>
            {levelList &&
              levelList.length > 0 &&
              cashInSettingsList.minimumLevelForCashIn && (
                <Select
                  loading={dropDownLoading}
                  value={
                    levelList.filter(
                      (level) =>
                        level.uniqueName?.id ==
                        cashInSettingsList.minimumLevelForCashIn
                    )[0].name
                  }
                  onChange={(value) => {
                    onChangeMinLevelHandler(parseInt(value));

                    setCashInSettingsList({
                      ...cashInSettingsList,
                      minimumLevelForCashIn: parseInt(value),
                    });
                  }}
                >
                  {levelList.map((level) => (
                    <Select.Option key={level.uniqueName?.id.toString()}>
                      {level.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
          </div>
        </Row>
        <Row className={"title"} style={{ marginTop: "32px" }}>
          <Input
            label={"مدت زمان قفل شدن کاربر بعد از تایید واریز شناسه دار"}
            name={"hour"}
            type={"text"}
            value={cashInSettingsList.hour?.toString()}
            onChange={(e) =>
              setCashInSettingsList({
                ...cashInSettingsList,
                hour: e.target.value,
              })
            }
          />
          <Input
            label={"حداکثر مقدار واریز شناسه دار بدون اعمال شدن زمان"}
            name={"amount"}
            type={"text"}
            value={digitSeparator(cashInSettingsList.amount)?.toString()}
            onChange={(e) =>
              setCashInSettingsList({
                ...cashInSettingsList,
                amount: deleteCommaSeparator(e.target.value),
              })
            }
          />
        </Row>
        <Divider />
        {(cashInSettingsList.cashInItemSettings?.length ?? 0) > 0 && (
          <div className="cash-in-settings-list">
            {cashInSettingsList.cashInItemSettings?.map(
              (cashInSetting, index) => {
                return (
                  <div className="cash-in-setting-item-card">
                    <Row>
                      <div className="cash-in-setting-info">
                        <SettingTwoTone />
                        <span>
                          {
                            cashInModes?.filter(
                              (e) => e.id === cashInSetting.mode
                            )[0]?.title
                          }
                        </span>
                      </div>
                      <div className="bank-accounts">
                        {cashInSetting.bankAccounts?.map(
                          (bankAccount: CashInBankAccount, idx) => {
                            return (
                              <div className="bank-item">
                                <Button
                                  type="error"
                                  icon={<DeleteOutlined />}
                                  text="حذف حساب بانکی"
                                  onClick={() =>
                                    onRemoveHandle(cashInSetting.mode, idx)
                                  }
                                />
                                <Input
                                  type="text"
                                  name="iBan"
                                  label="شماره شبا"
                                  value={bankAccount.iBan}
                                  onChange={(e) =>
                                    onChangeHandle(
                                      cashInSetting.mode,
                                      idx,
                                      "iBan",
                                      e.target.value
                                    )
                                  }
                                  maxLength={24}
                                />
                                <Input
                                  type="text"
                                  name="accountNumber"
                                  label="شماره حساب"
                                  value={bankAccount.accountNumber}
                                  onChange={(e) =>
                                    onChangeHandle(
                                      cashInSetting.mode,
                                      idx,
                                      "accountNumber",
                                      e.target.value
                                    )
                                  }
                                />
                                <Input
                                  type="text"
                                  name="bankAccountOwner"
                                  label="نام صاحب حساب"
                                  value={bankAccount.bankAccountOwner}
                                  onChange={(e) =>
                                    onChangeHandle(
                                      cashInSetting.mode,
                                      idx,
                                      "bankAccountOwner",
                                      e.target.value
                                    )
                                  }
                                />
                                {banks && (
                                  <Dropdown
                                    trigger={["click", "hover"]}
                                    overlay={
                                      <Menu className="bank-dropdown">
                                        {banks &&
                                          [
                                            ...banks,
                                            { title: "همه", id: 100 },
                                          ].map((bank) => (
                                            <Menu.Item
                                              onClick={() => {
                                                onChangeHandle(
                                                  cashInSetting.mode,
                                                  idx,
                                                  "bank",
                                                  bank
                                                );
                                              }}
                                            >
                                              {bank.title}
                                            </Menu.Item>
                                          ))}
                                      </Menu>
                                    }
                                    placement="bottom"
                                  >
                                    <Button
                                      icon={<DownOutlined />}
                                      type="outlined"
                                      text={bankAccount.bank.title}
                                    />
                                  </Dropdown>
                                )}

                                <div className="vt-switch-row">
                                  <label htmlFor="">وضعیت واریز</label>
                                  <Switch
                                    title="وضعیت واریز"
                                    checked={bankAccount.isDepositActive}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={(e) =>
                                      onChangeHandle(
                                        cashInSetting.mode,
                                        idx,
                                        "isDepositActive",
                                        e
                                      )
                                    }
                                  />
                                </div>
                                <div className="vt-switch-row">
                                  <label htmlFor="">حساب پیشفرض</label>
                                  <Switch
                                    title="حساب پیشفرض"
                                    checked={bankAccount.isDefault}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={(e) =>
                                      onChangeHandle(
                                        cashInSetting.mode,
                                        idx,
                                        "isDefault",
                                        e
                                      )
                                    }
                                  />
                                </div>
                                <Divider type="horizontal" />
                              </div>
                            );
                          }
                        )}
                        <Button
                          type="info"
                          icon={<PlusOutlined />}
                          text="افزودن حساب بانکی"
                          onClick={() =>
                            onAddBankAccountHandle(cashInSetting.mode)
                          }
                          htmlType="button"
                        />
                      </div>
                    </Row>
                    <Button
                      onClick={() => openGoogleAuthModal(cashInSetting)}
                      text="ویرایش تنظیمات"
                      type="primary"
                      htmlType="button"
                    />
                  </div>
                );
              }
            )}
          </div>
        )}

        <Divider />

        <h3>تنظیمات واریز آفلاین</h3>
        <Row className={"title"} style={{ marginTop: "32px" }}>
          <div className={"offline-deposit-header"}>
            <label>حداقل سطح کاربری برای واریز آفلاین : </label>
            {levelList && levelList.length > 0 && (
              <Select
                loading={dropDownLoading || offlineDepositLevelDropdownLoading}
                value={
                  levelList.find(
                    (level) =>
                      level.uniqueName?.id ==
                      offlineDepositSettings.minimumLevelForOfflineDeposit
                  )?.name
                }
                onChange={(value) => {
                  onChangeOfflineDepositMinLevelHandler(parseInt(value));
                  setOfflineDepositSettings({
                    ...offlineDepositSettings,
                    minimumLevelForOfflineDeposit: parseInt(value),
                  });
                }}
              >
                {levelList.map((level) => (
                  <Select.Option
                    key={level.uniqueName?.id.toString()}
                    value={level.uniqueName?.id.toString()}
                  >
                    {level.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
          <div className={"offline-deposit-header"}>
            <Input
              type="text"
              name="minimumDepositAmount"
              label="حداقل مقدار تومانی برای واریز آفلاین"
              value={digitSeparator(
                offlineDepositSettings.minimumDepositAmount
              )}
              onChange={(e) =>
                setOfflineDepositSettings({
                  ...offlineDepositSettings,
                  minimumDepositAmount: Number(
                    deleteCommaSeparator(faNumToEnNum(e.target.value))
                  ),
                })
              }
            />
            <Button
              type={"primary"}
              text={"ثبت"}
              loading={minimumDepositAmountLoading}
              onClick={onMinimumDepositAmountSubmit}
            />
          </div>

          <div className={"offline-deposit-header"}>
            <label>وضعیت واریز آفلاین:</label>
            <Switch
              loading={offlineDepositActivationLoading}
              checked={offlineDepositSettings.isActive}
              onChange={onOfflineDepositIsActiveChange}
              checkedChildren="فعال"
              unCheckedChildren="غیر فعال"
            />
          </div>
        </Row>

        <Row>
          {pendingOfflineDepositChanges?.map((bank, idx) => (
            <div key={bank.iBan} className="cash-in-setting-item-card">
              <Row>
                <div className="cash-in-setting-info">
                  <SettingTwoTone />
                  <span>آفلاین</span>
                </div>
                <div className="bank-accounts">
                  <div key={idx} className="bank-item">
                    <Button
                      type="error"
                      icon={<DeleteOutlined />}
                      text="حذف حساب بانکی"
                      onClick={() => onRemoveOfflineBankAccountHandle(idx)}
                    />
                    <Input
                      label="شماره شبا"
                      name="iBan"
                      type="text"
                      value={bank.iBan}
                      onChange={(e) =>
                        onOfflineDepositChangeHandle(
                          "iBan",
                          e.target.value,
                          idx
                        )
                      }
                    />
                    <Input
                      label="شماره حساب"
                      name="bankAccountNumber"
                      type="text"
                      value={bank.bankAccountNumber}
                      onChange={(e) =>
                        onOfflineDepositChangeHandle(
                          "bankAccountNumber",
                          e.target.value,
                          idx
                        )
                      }
                    />
                    <Input
                      label="شماره کارت"
                      name="cardNumber"
                      type="text"
                      value={bank.cardNumber}
                      onChange={(e) =>
                        onOfflineDepositChangeHandle(
                          "cardNumber",
                          e.target.value,
                          idx
                        )
                      }
                    />
                    <Input
                      label="صاحب حساب"
                      name="OwnerName"
                      type="text"
                      value={bank.ownerName}
                      onChange={(e) =>
                        onOfflineDepositChangeHandle(
                          "OwnerName",
                          e.target.value,
                          idx
                        )
                      }
                    />
                    <Dropdown
                      trigger={["click", "hover"]}
                      overlay={
                        <Menu className="bank-dropdown">
                          {banks &&
                            [...banks, { title: "همه", id: 100 }].map(
                              (bankOption) => (
                                <Menu.Item
                                  key={bankOption.id}
                                  onClick={() =>
                                    onOfflineDepositChangeHandle(
                                      "bank",
                                      bankOption,
                                      idx
                                    )
                                  }
                                >
                                  {bankOption.title}
                                </Menu.Item>
                              )
                            )}
                        </Menu>
                      }
                      placement="bottom"
                    >
                      <Button
                        icon={<DownOutlined />}
                        type="outlined"
                        text={bank.bankName || "انتخاب بانک"}
                      />
                    </Dropdown>

                    <div className="vt-switch-row">
                      <label htmlFor="">وضعیت واریز</label>
                      <Switch
                        title="وضعیت واریز"
                        checked={bank.isDepositActive}
                        onChange={(e) =>
                          onOfflineDepositChangeHandle(
                            "isDepositActive",
                            e,
                            idx
                          )
                        }
                        checkedChildren="فعال"
                        unCheckedChildren="غیر فعال"
                      />
                    </div>

                    <Divider type="horizontal" />
                  </div>
                  <Button
                    type="info"
                    icon={<PlusOutlined />}
                    text="افزودن حساب بانکی"
                    onClick={onAddOfflineBankAccountHandle}
                    htmlType="button"
                  />
                </div>
              </Row>
              <Button
                onClick={openOfflineDepositGoogleAuthModal}
                text="ویرایش اطلاعات"
                type="primary"
                htmlType="button"
              />
            </div>
          ))}
        </Row>

        <h3>واریز کارت به کارت</h3>
        <Row className={"title"}>
          <div className={"offline-deposit-header"}>
            <label>وضعیت واریز کارت به کارت:</label>
            <Switch
              loading={cardToCardActivationLoading}
              checked={cardToCardSettingIsActive}
              onChange={onCardToCardDepositIsActiveChange}
              checkedChildren="فعال"
              unCheckedChildren="غیر فعال"
            />
          </div>
        </Row>
        <Row>
          {Object.entries(serviceSettings).map(([key, value]) => {
            return renderServiceSettings(key, value?.service?.title ?? "");
          })}
        </Row>
      </Card>
    </>
  );
};
