import * as React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Modal } from "components";
import "./index.scss";
import moment from "jalali-moment";
import { getIconPath } from "utils/scripts/markets";
import { copyToClipboard } from "utils/scripts/copyToClipboard";
import { Transaction } from "core/domains/transaction/transaction";
import { digitSeparator } from "utils/scripts/decimal";
import { Spin } from "antd";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item: Transaction;
  loading: boolean;
}

export const CryptoTransactionModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  item,
  loading,
}) => {
  return (
    <Modal
      className="crypto-transactions-detail-modal"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="جزئیات تراکنش"
      closeAble
    >
      <div className="content">
        <div className="row ">
          <span>شناسه تراکنش</span>
          <div>
            <span className="en">{item.trackingCode}</span>
            <CopyOutlined
              onClick={() => copyToClipboard(item.trackingCode, "شناسه سفارش")}
            />
          </div>
        </div>

        <div className="row has-divider">
          <span>نام ارز</span>
          <div className="coin-info">
            <img src={getIconPath(item.market?.symbol)} />
            <span>{item.market?.name}</span>
          </div>
        </div>
        <div className="row has-divider">
          <span>نوع تراکنش</span>
          <div>
            <span>{item.type.title}</span>
          </div>
        </div>
        {item.network && (
          <div className="row has-divider ">
            <span>شبکه تراکنش</span>
            <div>
              <span className="en">
                {item.network?.network} | {item.network?.name}
              </span>
            </div>
          </div>
        )}
        {item.network && (
          <div className="row has-divider ">
            <span>کارمزد شبکه</span>
            <div>
              <span className="en">
                {item.network.fee ?? 0} {item.market.symbol}
              </span>
            </div>
          </div>
        )}
        {item.market.symbol.toUpperCase() !== "IRT" && (
          <div className="row has-divider ">
            <span>مقدار نهایی</span>
            <div>
              <span className="en">
                {item.amount - (item.network?.fee ?? 0)} {item.market.symbol}
              </span>
            </div>
          </div>
        )}
        {item.trackingCode.startsWith("W") && (
          <div className="row has-divider ">
            <span>آدرس کیف پول</span>
            <div>
              <span className="en">{item.wallet?.walletAddress} </span>
            </div>
          </div>
        )}
        {item.trackingCode.startsWith("W") && item.wallet?.memo && (
          <div className="row has-divider ">
            <span>آدرس تگ یا ممو</span>
            <div>
              <span className="en">{item.wallet?.memo} </span>
            </div>
          </div>
        )}
        {(loading || (item.txId !== null && item.txId !== "")) && (
          <div className="row has-divider">
            <span>تی ایکس آیدی</span>

            {loading ? (
              <Spin size={"large"} spinning={loading}></Spin>
            ) : (
              <div>
                <span className="en">{item.txId ?? "-"}</span>
                <CopyOutlined
                  onClick={() => copyToClipboard(item.txId ?? "", "هش")}
                />
              </div>
            )}
          </div>
        )}

        <div className="row has-divider">
          <span>مقدار تراکنش</span>
          <div>
            <span className="en">
              {digitSeparator(
                item.market.symbol.toUpperCase() === "IRT"
                  ? item.amount.toFixed(0)
                  : item.amount
              )}{" "}
              {item.market.symbol}
            </span>
          </div>
        </div>

        {item.onPrice !== 0 && item.market.symbol.toUpperCase() !== "IRT" && (
          <div className="row has-divider">
            <span>قیمت لحظه</span>
            <div>
              <span className="en">{digitSeparator(item.onPrice)}</span>
            </div>
          </div>
        )}
        {item.digitalReceipt && (
          <div className="row ">
            <span>رسید دیجیتالی</span>
            <div>
              <span className="en">{item.digitalReceipt}</span>
              <CopyOutlined
                onClick={() =>
                  copyToClipboard(item?.digitalReceipt || "", "رسید دیجیتالی")
                }
              />
            </div>
          </div>
        )}
        <div className="row has-divider">
          <span>تاریخ و ساعت</span>
          <span>
            {moment
              .utc(item?.createdAt)
              .locale("fa")
              .format("  HH:mm  -  jYYYY/MM/DD  ")}
          </span>
        </div>
        <div className="row has-divider state">
          <span>وضعیت</span>
          <span
            className={`${
              item.state.id === 2
                ? "success"
                : item.state.id === 3
                ? "error"
                : "waiting"
            }`}
          >
            {item.state.title}
          </span>
        </div>
        {item.description && (
          <div className="row has-divider ">
            <span>توضیحات</span>
            <span>{item.description}</span>
          </div>
        )}
      </div>
    </Modal>
  );
};
