import { Col, Divider, Image, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Button, Card } from "components";
import { ManualValidation } from "core/domains";
import {
  approveManualValidation,
  rejectManualValidation,
} from "core/repos/manual-validations";
import * as React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.scss";
import moment from "jalali-moment";

export const AuthMediaConfirmation: React.FC<{ data: any }> = ({ data }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [authInfo, setAuthInfo] = useState<ManualValidation>(data);

  const location = useLocation();
  const navigate = useNavigate();

  const URL = window.location;
  var urlSections = URL.pathname.split("/");
  const id = urlSections[urlSections.length - 1];

  const approveHandle = async () => {
    approveManualValidation(setLoading, authInfo, id, navigate);
  };
  const rejectHandle = async () => {
    rejectManualValidation(setLoading, authInfo, id, navigate);
  };

  return (
    <Card
      title="تایید مدرک هویتی"
      className="vt-dashboard-mannual-carts-detail"
    >
      {authInfo.files &&
        authInfo.files.fileByteContent.length !== 0 &&
        authInfo.files.fileByteContent.map((file) => (
          <Image width={200} src={`data:image/png;base64, ${file}`} />
        ))}
      <Divider />
      {authInfo.user && (
        <>
          <Row>
            <Col>
              <span>نام کامل:</span>
              <span>
                {authInfo.user.fullName.firstName +
                  " " +
                  authInfo.user.fullName.lastName}
              </span>
            </Col>
            <Col>
              <span>کد ملی:</span> <span>{authInfo.user.nationalCode}</span>
            </Col>
            <Col>
              <span>تاریخ عضویت:</span>
              <span>
                {moment
                  .utc(authInfo.userRegisteredOn)
                  .local()
                  .toDate()
                  .toLocaleDateString("fa-IR")}{" "}
                &nbsp;| &nbsp;{" "}
                {moment
                  .utc(authInfo.userRegisteredOn)
                  .local()
                  .format("HH:mm:ss")}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>تاریخ تولد:</span>
              <span>
                {authInfo.user.birthDate &&
                  moment(authInfo.user.birthDate)
                    .locale("fa")
                    .local()
                    .format("jYYYY/MM/DD")}
              </span>
            </Col>
            <Col>
              <span>موبایل:</span> <span>{authInfo.user.phoneNumber}</span>
            </Col>
          </Row>
        </>
      )}
      <Divider />
      {authInfo.user.address && (
        <Row>
          <label htmlFor="address">آدرس</label>
          <TextArea name="address" value={authInfo?.user.address} />
        </Row>
      )}

      <Row>
        <label htmlFor="description">توضیحات</label>
        <TextArea
          name="attachedMessage"
          value={authInfo?.message}
          onChange={(e) =>
            setAuthInfo({
              ...authInfo,
              message: e.target.value,
            })
          }
        />
        <div className="texts">
          <Button
            onClick={(e) =>
              setAuthInfo({
                ...authInfo,
                message: `${
                  authInfo?.message ? authInfo.message + " " : ""
                }تصاویر آپلود شده خوانا نمیباشد.`,
              })
            }
            type={"trade"}
            text={"عدم خوانایی"}
          />
          <Button
            onClick={(e) =>
              setAuthInfo({
                ...authInfo,
                message: `${
                  authInfo?.message ? authInfo.message + " " : ""
                }داشتن پوشش مناسب در تصویر سلفی اجباری است.`,
              })
            }
            type={"trade"}
            text={"پوشش نامناسب"}
          />
          <Button
            onClick={(e) =>
              setAuthInfo({
                ...authInfo,
                message: `${
                  authInfo?.message ? authInfo.message + " " : ""
                }تصویر باید عکس سلفی با مدارک هویتی و کارت بانکی و دست نوشته باشد.`,
              })
            }
            type={"trade"}
            text={"ایراد تصویر"}
          />
          <Button
            onClick={(e) =>
              setAuthInfo({
                ...authInfo,
                message: `${
                  authInfo?.message ? authInfo.message + " " : ""
                }متن دست نوشته صحیح نیست.`,
              })
            }
            type={"trade"}
            text={"دست نوشته غلط"}
          />
          <Button
            onClick={(e) =>
              setAuthInfo({
                ...authInfo,
                message: `${
                  authInfo?.message ? authInfo.message + " " : ""
                }آدرس پلتفرم در دست نوشته اشتباه است.`,
              })
            }
            type={"trade"}
            text={"آدرس پلتفرم غلط"}
          />
        </div>
      </Row>

      {authInfo?.state.id != 0 && (
        <Row>
          <Col>
            <span>تغییر داده شده توسط </span>
            <span>{authInfo?.modifiedBy}</span>
          </Col>
          <Col>
            <span>تغییر داده شده در تاریخ </span>
            <span>
              {moment.utc(authInfo?.modifiedAt).local().format("HH:mm")}
            </span>
          </Col>
        </Row>
      )}

      <Row className="buttons">
        <Button
          htmlType="button"
          type="info"
          text="تایید"
          onClick={() => approveHandle()}
          loading={loading}
          disabled={loading}
        />

        <Button
          htmlType="button"
          type="outlined"
          text="رد"
          onClick={() => rejectHandle()}
          loading={loading}
          disabled={loading}
        />
      </Row>
    </Card>
  );
};
