import { API } from "apis";
import { message } from "antd";
import { Market } from "core/domains";
import {
  get,
  getViaAuth,
  postViaAuth,
  responseValidator,
} from "utils/scripts/api";

export const getAllMarkets = async (
  setloader: any,
  setdata: any,
  page: number,
  pageSize: number,
  query: string
) => {
  setloader(true);
  await getViaAuth(
    `${API.market.getAllMarkets}/${page.toString()}/${pageSize}${query}`
  ).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata(response.value);
    }
  });
};

export const getAllMarketsUser = async (
  setloader: any,
  setdata: any,
  page: number,
  pageSize: number,
  query: string
) => {
  setloader(true);
  await get(`${API.market.getAll}/${page.toString()}/${pageSize}${query}`).then(
    (response: any) => {
      setloader(false);
      if (responseValidator(response.status)) {
        setdata(response.data.value);
      }
    }
  );
};
export const syncMarkets = (setloader: any, loadAgain: any) => {
  setloader(true);
  getViaAuth(API.market.syncMarket, {}).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("ارز های صرافی با موفقیت بروزرسانی شد.");
      loadAgain();
    }
  });
};
export const updateMarket = (
  setloader: any,
  setdata: any,
  market: Market,
  page: number,
  query: string
) => {
  setloader(true);
  postViaAuth(API.market.editMarket, market).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("ارز با موفقیت ویرایش شد");
      getAllMarkets(setloader, setdata, page, 10, query);
    }
  });
};

export const updateMarketPriority = (
  setloader: any,
  market: Market,
  onComplete: () => void
) => {
  setloader(true);
  postViaAuth(API.market.editMarket, market).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      message.success("ارز با موفقیت ویرایش شد");
      onComplete();
    }
  });
};

export const getMarketTypesEnum = (setloader: any, setdata: any) => {
  setloader(true);
  getViaAuth(API.enums.marketTypes).then((response: any) => {
    setloader(false);
    if (responseValidator(response.status)) {
      setdata([{ id: 0, title: "همه" }, ...response.value]);
    }
  });
};

export const editSingleMarketsState = async (
  setloader: any,
  market: any,
  onComplete: () => void,
  onFail: () => void
) => {
  await postViaAuth(API.market.editMarket, {
    ...market,
  }).then((response: any) => {
    if (responseValidator(response.status)) {
      onComplete();
    } else {
      onFail();
    }
  });
};
